import { Button, H5, ToastBanner } from 'common';
import { Row } from 'reactstrap';
import QRCode from 'react-qr-code';
import { LoaderWheel } from 'common/Loader/LoaderWheel';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { useEffect } from 'react';
import { getTOTP } from 'screens/session/sessionService';
import { modalActions } from 'common/Modal/modalSlice';
import { MODAL_OPEN } from 'common/Modal/const';
import { QR_STEPS } from './const';
import { sessionActions } from 'screens/session/sessionSlice';

export const QR_step_2 = ({ goToStep }: any) => {
    const dispatch = useAppDispatch();

    const { totpCode } = useAppSelector((state) => state.session);
    const clearTotpCode = sessionActions.clearTotpCode;

    useEffect(() => {
        dispatch(getTOTP());
    }, [dispatch]);

    return (
        <Row className='justify-content-center'>
            <H5
                style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    padding: '0px 70px',
                    marginBottom: 40,
                }}
            >
                Configurar autentificación de dos factores
            </H5>
            <Row
                className='mb-3'
                style={{
                    width: 400,
                }}
            >
                <ToastBanner
                    show
                    message='Escanea el siguiente código QR usando la aplicación Google Authenticator en tu teléfono móvil.'
                />
            </Row>

            <Row
                className='m-3 justify-content-center'
                style={{
                    background: 'white',
                    borderRadius: 10,
                    padding: '16px',
                }}
            >
                {totpCode ? (
                    <QRCode value={totpCode} size={200} />
                ) : (
                    <LoaderWheel style={{ width: 200, height: 200 }} />
                )}
            </Row>
            <Button
                style={{
                    width: '70%',
                    fontSize: '13px',
                    marginTop: 20,
                }}
                onClick={() => {
                    dispatch(modalActions.setModalOpen(MODAL_OPEN.NONE));
                    goToStep(QR_STEPS.QR_STEP_1);
                    dispatch(clearTotpCode(null));
                }}
            >
                Aceptar
            </Button>
        </Row>
    );
};
