import { AlignCenter } from 'react-feather';
import { useDispatch } from 'react-redux';
import { layoutActions } from '../layoutSlice';
import bisbull_text_logo_green from 'assets/images/bisbull_text_logo_green.png';
import bisbull_text_logo_white from 'assets/images/bisbull_text_logo_white.png';
import bisbull_icon_logo_green from 'assets/images/logo.png';
import bisbull_icon_logo_orange from 'assets/images/logo_orange.png';
import { useAppSelector } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from 'layout/routes/const';

export const LeftBar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { sidebarOpen } = useAppSelector((state) => state.layout);
    const mix_background_layout = localStorage.getItem('mix_background_layout');

    const logo_icon =
        mix_background_layout === 'dark-only'
            ? bisbull_icon_logo_orange
            : bisbull_icon_logo_green;
    const logo_text =
        mix_background_layout === 'dark-only'
            ? bisbull_text_logo_white
            : bisbull_text_logo_green;

    const toggleSidebar = () =>
        dispatch(layoutActions.setSidebarOpen(!sidebarOpen));

    return (
        <div className='main-header-left d-flex align-content-center'>
            <div
                className='logo-wrapper d-flex align-items-center'
                style={{
                    width: '100%',
                    justifyContent: 'space-between',
                }}
            >
                <div
                    className='d-flex align-items-center'
                    onClick={() => navigate(ROUTE_PATH.DASHBOARD)}
                    style={{ cursor: 'pointer' }}
                >
                    <img
                        src={logo_icon}
                        alt='Texto del logo'
                        style={{
                            height: 28,
                            paddingRight: 5,
                        }}
                    />
                    <img
                        src={logo_text}
                        alt='logo'
                        style={{ height: 38, paddingRight: 10 }}
                    />
                </div>
                <div className='toggle-sidebar' onClick={toggleSidebar}>
                    <AlignCenter
                        className='status_toggle middle'
                        id='sidebar-toggle'
                    />
                </div>
            </div>
        </div>
    );
};
