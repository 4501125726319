import { useContext, useEffect, useState } from 'react';
import { Input, Label, Media } from 'reactstrap';
import { P } from '@common';
import { ThemeContext } from '../../../providers/ThemeProvider';
import './OperatorRobotSwitch.scss';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import {
    getDaemon,
    setDaemon,
} from '../../../screens/operator/operatorService';

export const OperatorRobotSwitch = () => {
    const theme = useContext(ThemeContext);
    const getClassname = () => {
        let result = 'switch-state';
        if (theme.mix_background_layout === 'dark-only')
            result += ' dark-switch';
        return result;
    };
    const dispatch = useAppDispatch();
    const switchState = useAppSelector((state) => state.operator.daemonSwitch);

    useEffect(() => {
        dispatch(getDaemon());
    }, []);

    const handleChange = () => {
        switch (switchState) {
            case true:
                dispatch(
                    setDaemon({
                        switch: false,
                    }),
                );
                break;
            case false:
                dispatch(
                    setDaemon({
                        switch: true,
                    }),
                );
                break;
            case null:
            default:
                break;
        }
    };

    const getText = () => {
        switch (switchState) {
            case true:
                return 'Robot activo';
            case false:
                return 'Robot inactivo';
            case null:
            default:
                return '';
        }
    };

    return (
        <Media>
            <Media body className='text-end robot-switch'>
                <Label className='switch'>
                    <Input
                        type='checkbox'
                        onChange={handleChange}
                        checked={!!switchState}
                    />
                    <span className={getClassname()}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '7px',
                            }}
                        >
                            <P>{getText()}</P>
                        </div>
                    </span>
                </Label>
            </Media>
        </Media>
    );
};
