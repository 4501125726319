import { DashboardBanner } from 'common/DashboardBanner/DashboardBanner';
import { Col, Row } from 'reactstrap';
import { WalletCard } from './WalletCard';
import { OrdersCardTables } from './OrdersCardTables';

export const Dashboard = () => {
    return (
        <Col>
            <Row className='justify-content-center mb-4'>
                <DashboardBanner />
            </Row>
            <Row>
                <WalletCard />
            </Row>
            <Row>
                <OrdersCardTables />
            </Row>
        </Col>
    );
};
