import { Card, H1, H5 } from 'common';
import './TotalBalance.scss';
import { useAppSelector } from 'redux/store';
import { getTotalBalanceEquivalent, useLoadedImage } from '@utils';
import imgBalance from 'assets/images/dashboard_balance_background.png';
import { useContext, useEffect, useState } from 'react';
import banner from '../../../../assets/images/dashboard_balance_background.png';
import { formatNumberWithCommas } from '../../../../utils/string';
import { COIN_TYPES } from '../../../../utils/const';
import { ThemeContext } from 'providers/ThemeProvider';

export const TotalBalance = () => {
    const loaded = useLoadedImage(imgBalance);
    const { balances } = useAppSelector((state) => state.common);
    const totalBalanceEquivalent = getTotalBalanceEquivalent(balances);
    const [width, setWidth] = useState(window.innerWidth);
    const handleResize = () => setWidth(window.innerWidth);

    const darkMode =
        useContext(ThemeContext).mix_background_layout === 'dark-only'
            ? 'dark'
            : '';

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });
    const getBalanceFontSize = () => {
        if (width <= 800) return 20;
        if (width > 1700) return 40;
        return width * 0.025;
    };

    return (
        <div className={`total-balance-banner ${darkMode}`}>
            <div className='left-image'>
                <img
                    src={banner}
                    alt=''
                    style={{
                        display: width > 920 && loaded ? 'unset' : 'none',
                    }}
                />
            </div>
            <div
                className='right-content'
                style={{ border: width > 920 ? '' : 18 }}
            >
                <H5 className='title'>Balance total en ARS</H5>
                <H1 className='balance'>
                    {formatNumberWithCommas(
                        totalBalanceEquivalent.amountARS.toString(),
                        COIN_TYPES.ARS,
                        true,
                    )}
                </H1>
            </div>
        </div>
    );
};
