export const paragraphColorClassName = {
    primary: 'txt-primary',
    secondary: 'txt-secondary',
    success: 'txt-success',
    danger: 'txt-danger',
    info: 'txt-info',
    warning: 'txt-warning',
    light: 'txt-light',
    dark: 'txt-dark',
    transparent: '',
};
