import { useContext, useEffect } from 'react';
import './Sidebar.scss';
import { ThemeContext } from '../../providers/ThemeProvider';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { getBalanceExtended, getCoins } from 'common/commonService';
import { CommonSidebar } from './CommonSidebar';
import {
    Activity,
    Clock,
    CreditCard,
    DollarSign,
    Home,
    Shuffle,
    User,
    Users,
} from 'react-feather';
import { ROUTE_PATH } from 'layout/routes/const';
import { Col } from 'reactstrap';
import { OperatorRobotSwitch } from './OperatorRobotSwitch';
import { dotAndComma, getTotalBalanceEquivalent } from 'utils/currency';
import { NavLink, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { MarketSidebar } from './MarketSidebar/MarketSidebar';
import { USER_TYPE } from 'screens/user/profile/types';

export interface Section {
    title?: string;
    href: string; // use as link to another page...
    onClick?: () => void; // ...or simple callback icon?: JSX.Element | string;
    icon?: JSX.Element | string | '';
}

export const Sidebar = () => {
    const dispatch = useAppDispatch();
    const userType = useAppSelector((state) => state.profile.userType);

    useEffect(() => {
        dispatch(getBalanceExtended());
        dispatch(getCoins());
    }, []);

    if (userType === USER_TYPE.OPERATOR) return <OperatorSidebar />;
    if (userType === USER_TYPE.USER) return <UserSidebar />;
    return null;
};

const OperatorSidebar = () => {
    const sections = [
        {
            title: 'Panel Operadorr',
            icon: <Home />,
            href: ROUTE_PATH.DASHBOARD,
        },
        {
            title: 'Perfiles de usuario',
            icon: <Users />,
            href: ROUTE_PATH.USERS,
        },
    ];

    return (
        <CommonSidebar
            sections={sections}
            renderTopComponent={
                <Col
                    className='col-8'
                    style={{
                        paddingTop: 20,
                        paddingRight: 0,
                    }}
                >
                    <OperatorRobotSwitch />
                </Col>
            }
        />
    );
};

const UserSidebar = () => {
    const { balances } = useAppSelector((state) => state.common);
    const theme = useContext(ThemeContext);
    const location = useLocation();

    const color =
        theme.mix_background_layout === 'light-only'
            ? ''
            : 'rgba(255, 255, 255, 0.7)';

    const sections = [
        {
            title: 'Inicio',
            icon: <Home />,
            href: ROUTE_PATH.DASHBOARD,
        },
        {
            title: 'Compra/Venta',
            icon: <Activity />,
            href: ROUTE_PATH.QUOTATION,
        },
        {
            title: 'Trading',
            icon: <DollarSign />,
            href: ROUTE_PATH.MARKET,
        },
        {
            title: 'Conversor',
            icon: <Shuffle />,
            href: ROUTE_PATH.CONVERSOR,
        },
        {
            title: 'Billetera',
            icon: <CreditCard />,
            href: ROUTE_PATH.WALLET,
        },
        {
            title: 'Perfil',
            icon: <User />,
            href: ROUTE_PATH.PROFILE,
        },
        {
            title: 'Historial',
            icon: <Clock />,
            href: ROUTE_PATH.HISTORY,
        },
    ];

    const totalBalanceEquivalent = getTotalBalanceEquivalent(balances);

    if (location.pathname === ROUTE_PATH.MARKET) {
        return <MarketSidebar sections={sections} />;
    }

    return (
        <CommonSidebar
            sections={sections}
            renderTopComponent={
                <>
                    <Col
                        className='col-8'
                        style={{
                            paddingTop: 10,
                            paddingRight: 15,
                        }}
                    >
                        <div>
                            <h5 style={{ color: color }}>
                                {dotAndComma(
                                    totalBalanceEquivalent?.amountUSD.toFixed(
                                        2,
                                    ),
                                )}{' '}
                                USD
                            </h5>
                            <span>
                                ≈&nbsp;{' '}
                                {dotAndComma(
                                    totalBalanceEquivalent?.amountARS.toFixed(
                                        2,
                                    ),
                                )}{' '}
                                ARS
                            </span>
                        </div>
                    </Col>
                    <Col className='col-1'>
                        <NavLink
                            className='nav-link menu-title'
                            to='/billetera'
                        >
                            <FontAwesomeIcon icon={faAngleRight} />
                        </NavLink>
                    </Col>
                </>
            }
        />
    );
};
