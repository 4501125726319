import {
    Login,
    Login2FA,
    RecoverPassword,
    Register,
    RecoverPasswordConfirm,
    RegisterActivate,
} from 'screens/session';
import { ROUTE_PATH } from './const';
import type { RouteItem } from './types';

export const sessionRoutes: RouteItem[] = [
    {
        title: 'Login',
        path: `${ROUTE_PATH.LOGIN}`,
        Component: <Login />,
    },
    {
        title: 'Login 2FA',
        path: `${ROUTE_PATH.LOGIN2FA}`,
        Component: <Login2FA />,
    },
    {
        title: 'Recuperar contraseña',
        path: `${ROUTE_PATH.RECOVER_PASSWORD}`,
        Component: <RecoverPassword />,
    },
    {
        title: 'Registro',
        path: `${ROUTE_PATH.REGISTER}`,
        Component: <Register />,
    },
    {
        title: 'Activación de registro',
        path: `${ROUTE_PATH.REGISTER_ACTIVATION}/*`,
        Component: <RegisterActivate />,
    },
    {
        title: 'Recuperar confirmar contraseña',
        path: `${ROUTE_PATH.RECOVER_CONFIRM_PASSWORD}/*`,
        Component: <RecoverPasswordConfirm />,
    },
];
