import { ArrowLeft } from 'react-feather';
import { Button } from '../Button';

interface BackButtonProps {
    onClick: () => void;
}
export const BackButton = (props: BackButtonProps) => (
    <Button
        onClick={props.onClick}
        color='secondary'
        outline
        style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#ebe7e4',
        }}
    >
        <ArrowLeft size={18} className='mr-1' />
        Volver
    </Button>
);
