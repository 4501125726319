import type { ExchangeOrder } from 'screens/user/quotation/types';
import type {
    ExchangeWithdrawal,
    ExchangeDeposit,
} from '../../../screens/user/wallet/types';

export enum EXCHANGE_TABLE_ITEM_TYPE {
    ORDER,
    WITHDRAWAL,
    DEPOSIT,
}

export interface ExchangeTableItem {
    type: EXCHANGE_TABLE_ITEM_TYPE;
    exchangeOrder: ExchangeOrder | null;
    exchangeWithdrawalsJoined: ExchangeWithdrawal | null;
    exchangeDeposit: ExchangeDeposit | null;
}
