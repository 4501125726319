import { REQUEST_STATE } from '../../redux/types';
import { createSlice } from '@reduxjs/toolkit';
import {
    refreshToken,
    login,
    getTOTP,
    register,
    recoverPassword,
    recoverPasswordConfirm,
    registerActivate,
    loginTOTP,
} from './sessionService';
import { toast } from 'react-toastify';
import { ACCESS_TOKEN, AUTHENTICATED, REFRESH_TOKEN } from 'utils/service';

// export const USER_REMEMBER_PASSWORD = 'userRememberPassword';

interface SessionState {
    requestStates: {
        login: REQUEST_STATE;
        loginGetTOTP: REQUEST_STATE;
        loginTOTP: REQUEST_STATE;
        register: REQUEST_STATE;
        refresh: REQUEST_STATE;
        recoverPassword: REQUEST_STATE;
        recoverPasswordConfirm: REQUEST_STATE;
        registerActivate: REQUEST_STATE;
    };
    totpCode: string | null;
    // rememberPassword: boolean;
}

const initialState: SessionState = {
    requestStates: {
        login: REQUEST_STATE.NONE,
        loginGetTOTP: REQUEST_STATE.NONE,
        loginTOTP: REQUEST_STATE.NONE,
        register: REQUEST_STATE.NONE,
        refresh: REQUEST_STATE.NONE,
        recoverPassword: REQUEST_STATE.NONE,
        recoverPasswordConfirm: REQUEST_STATE.NONE,
        registerActivate: REQUEST_STATE.NONE,
    },
    totpCode: null,
    // rememberPassword: localStorage.getItem(USER_REMEMBER_PASSWORD),
};

const __removeAuthentication = (state: SessionState) => {
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(AUTHENTICATED);
    state.requestStates.login = REQUEST_STATE.NONE;
};

export const sessionSlice = createSlice({
    name: 'sessionSlice',
    initialState,
    reducers: {
        logout: __removeAuthentication,
        clearTotpCode: (state: SessionState, action) => {
            state.totpCode = action.payload;
        },
    },
    extraReducers: (builder) => {
        /* Login */
        builder.addCase(login.pending, (state) => {
            state.requestStates.login = REQUEST_STATE.LOADING;
        });
        builder.addCase(login.fulfilled, (state, { payload }) => {
            state.requestStates.login = REQUEST_STATE.OK;
        });
        builder.addCase(login.rejected, (state) => {
            state.requestStates.login = REQUEST_STATE.ERROR;
        });
        /* Login get TOTP */
        builder.addCase(getTOTP.pending, (state) => {
            state.requestStates.loginGetTOTP = REQUEST_STATE.LOADING;
        });
        builder.addCase(getTOTP.fulfilled, (state, { payload }) => {
            state.requestStates.loginGetTOTP = REQUEST_STATE.OK;
            state.totpCode = payload;
        });
        builder.addCase(getTOTP.rejected, (state) => {
            state.requestStates.loginGetTOTP = REQUEST_STATE.ERROR;
            __removeAuthentication(state);
        });
        /* Login TOTP */
        builder.addCase(loginTOTP.pending, (state) => {
            state.requestStates.loginTOTP = REQUEST_STATE.LOADING;
        });
        builder.addCase(loginTOTP.fulfilled, (state) => {
            state.requestStates.loginTOTP = REQUEST_STATE.OK;
        });
        builder.addCase(loginTOTP.rejected, (state) => {
            state.requestStates.loginTOTP = REQUEST_STATE.ERROR;
        });
        /* Refresh */
        builder.addCase(refreshToken.pending, (state) => {
            state.requestStates.refresh = REQUEST_STATE.LOADING;
        });
        builder.addCase(refreshToken.fulfilled, (state) => {
            state.requestStates.refresh = REQUEST_STATE.OK;
        });
        builder.addCase(refreshToken.rejected, (state) => {
            state.requestStates.refresh = REQUEST_STATE.ERROR;
            __removeAuthentication(state);
        });
        /* Register */
        builder.addCase(register.pending, (state) => {
            state.requestStates.register = REQUEST_STATE.LOADING;
        });
        builder.addCase(register.fulfilled, (state) => {
            // not using payload, user will be redirected to login
            // because it needs to activate its account first
            state.requestStates.register = REQUEST_STATE.OK;
        });
        builder.addCase(register.rejected, (state) => {
            state.requestStates.register = REQUEST_STATE.ERROR;
        });
        /* Register activate */
        builder.addCase(registerActivate.pending, (state) => {
            state.requestStates.registerActivate = REQUEST_STATE.LOADING;
        });
        builder.addCase(registerActivate.fulfilled, (state) => {
            state.requestStates.registerActivate = REQUEST_STATE.OK;
        });
        builder.addCase(registerActivate.rejected, (state) => {
            toast.error('Hubo un error');
            state.requestStates.registerActivate = REQUEST_STATE.ERROR;
        });
        /* Recover password */
        builder.addCase(recoverPassword.pending, (state) => {
            state.requestStates.recoverPassword = REQUEST_STATE.LOADING;
        });
        builder.addCase(recoverPassword.fulfilled, (state) => {
            toast.success('Recibirás un mail para reestablecer la contraseña.');
            state.requestStates.recoverPassword = REQUEST_STATE.OK;
        });
        builder.addCase(recoverPassword.rejected, (state) => {
            toast.error('Hubo un error');
            state.requestStates.recoverPassword = REQUEST_STATE.ERROR;
        });
        /* Recover password confirm */
        builder.addCase(recoverPasswordConfirm.pending, (state) => {
            state.requestStates.recoverPasswordConfirm = REQUEST_STATE.LOADING;
        });
        builder.addCase(recoverPasswordConfirm.fulfilled, (state) => {
            toast.success('Contraseña cambiada correctamente.');
            state.requestStates.recoverPasswordConfirm = REQUEST_STATE.OK;
        });
        builder.addCase(recoverPasswordConfirm.rejected, (state) => {
            toast.error('Hubo un error');
            state.requestStates.recoverPasswordConfirm = REQUEST_STATE.ERROR;
        });
    },
});

export const sessionActions = sessionSlice.actions;

export const sessionReducer = sessionSlice.reducer;
