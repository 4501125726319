import { Button, LI } from 'common';
import { LogOut } from 'react-feather';

export const Logout = ({ onClick }: { onClick: () => void }) => {
    return (
        <LI className='onhover-dropdown p-0' onClick={onClick}>
            <Button className='btn btn-primary-light' color='light'>
                <LogOut />
                Log out
            </Button>
        </LI>
    );
};
