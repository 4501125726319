import { Button, FormInput, P } from '@common';
import { SessionCard } from '../components/SessionCard';
import { Col, NavLink, Row } from 'reactstrap';
import passwordImage from 'assets/images/recover_password_side_image.png';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { recoverPassword } from '../sessionService';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { REQUEST_STATE } from 'redux/types';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from '../../../layout/routes/const';
import { toast } from 'react-toastify';
import { useState } from 'react';

export interface RecoverPasswordValues {
    email: string;
}

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('El email no es válido')
        .required('El email es requerido'),
});

export const RecoverPassword = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const recoverPasswordState = useAppSelector(
        (state) => state.session.requestStates.recoverPassword,
    );
    const [showResendEmail, setShowResendEmail] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        onSubmit: (values) => {
            dispatch(recoverPassword(values))
                .unwrap()
                .then(() => {
                    setTimeout(() => {
                        setShowResendEmail(true);
                    }, 3000);
                });
        },
        validationSchema: validationSchema,
    });

    const resendEmail = () => {
        dispatch(recoverPassword(formik.values))
            .unwrap()
            .then(() => {
                toast.success('Se ha enviado un nuevo email.');
            });
    };

    return (
        <div>
            <div className='login-card'>
                <SessionCard
                    title='Restablecer mi contraseña'
                    subtitle='Escribí tu email y te enviaremos un link para crear una nueva contraseña'
                    src={passwordImage}
                    onSubmit={formik.handleSubmit}
                >
                    <Row className='mb-3'>
                        <Col>
                            <FormInput
                                label='Email'
                                name='email'
                                type='email'
                                onFormikChange={formik.handleChange}
                                onChange={() => null}
                                value={formik.values.email}
                                error={formik.errors.email}
                                placeholder='example@mail.com'
                            />
                        </Col>
                    </Row>

                    <Row className='justify-content-left'>
                        <Button
                            type='submit'
                            loading={
                                recoverPasswordState === REQUEST_STATE.LOADING
                            }
                            style={{
                                marginLeft: '15px',
                                marginTop: '15px',
                            }}
                        >
                            Enviar
                        </Button>
                        {showResendEmail && (
                            <Col className='align-self-end'>
                                <P
                                    color='primary'
                                    style={{ paddingBottom: '5px' }}
                                    onClick={resendEmail}
                                >
                                    No recibí el email
                                </P>
                            </Col>
                        )}
                    </Row>
                </SessionCard>
            </div>
        </div>
    );
};
