import type { ExchangeTableItem } from './types';
import { EXCHANGE_TABLE_ITEM_TYPE } from './types';
import type { ExchangeOrder } from 'screens/user/quotation/types';
import { compareDesc, parseISO } from 'date-fns';
import type { ExchangeTableOptions } from './ExchangeTable';
import type {
    ExchangeWithdrawal,
    ExchangeDeposit,
} from '../../../screens/user/wallet/types';

export const isExchangeOrder = (exchangeTableItem: ExchangeTableItem) =>
    exchangeTableItem.type === EXCHANGE_TABLE_ITEM_TYPE.ORDER;
export const isExchangeWithdrawal = (exchangeTableItem: ExchangeTableItem) =>
    exchangeTableItem.type === EXCHANGE_TABLE_ITEM_TYPE.WITHDRAWAL;
export const isExchangeDeposit = (exchangeTableItem: ExchangeTableItem) =>
    exchangeTableItem.type === EXCHANGE_TABLE_ITEM_TYPE.DEPOSIT;

export const getExchangeTableData = (
    exchangeOrders: ExchangeOrder[] | null,
    exchangeWithdrawalsJoined: ExchangeWithdrawal[] | null,
    exchangeDeposit: ExchangeDeposit[] | null,
    options?: ExchangeTableOptions,
): ExchangeTableItem[] => {
    if (!exchangeOrders || !exchangeWithdrawalsJoined || !exchangeDeposit)
        return [];
    const nullExchangeTableItem = {
        type: EXCHANGE_TABLE_ITEM_TYPE.ORDER,
        exchangeOrder: null,
        exchangeWithdrawalsJoined: null,
        exchangeDeposit: null,
    };
    let result: ExchangeTableItem[] = [
        ...exchangeOrders.map((eo) => ({
            ...nullExchangeTableItem,
            type: EXCHANGE_TABLE_ITEM_TYPE.ORDER,
            exchangeOrder: eo,
        })),
        ...exchangeWithdrawalsJoined.map((ew) => ({
            ...nullExchangeTableItem,
            type: EXCHANGE_TABLE_ITEM_TYPE.WITHDRAWAL,
            exchangeWithdrawalsJoined: ew,
        })),
        ...exchangeDeposit.map((ed) => ({
            ...nullExchangeTableItem,
            type: EXCHANGE_TABLE_ITEM_TYPE.DEPOSIT,
            exchangeDeposit: ed,
        })),
    ].sort((a, b) => {
        // mix exchange orders with exchange withdrawals in order
        // of creation
        let aDate: string | null = null;
        let bDate: string | null = null;
        if (isExchangeOrder(a) && a.exchangeOrder)
            aDate = a.exchangeOrder.createdAt;
        else if (isExchangeWithdrawal(a) && a.exchangeWithdrawalsJoined)
            aDate = a.exchangeWithdrawalsJoined.createdAt;
        else if (isExchangeDeposit(a) && a.exchangeDeposit)
            aDate = a.exchangeDeposit.createdAt;
        if (isExchangeOrder(b) && b.exchangeOrder)
            bDate = b.exchangeOrder.createdAt;
        else if (isExchangeWithdrawal(b) && b.exchangeWithdrawalsJoined)
            bDate = b.exchangeWithdrawalsJoined.createdAt;
        else if (isExchangeDeposit(b) && b.exchangeDeposit)
            bDate = b.exchangeDeposit.createdAt;
        if (!aDate || !bDate) return 0;
        return compareDesc(parseISO(aDate), parseISO(bDate));
    });
    if (options?.limit) result = result.slice(0, options.limit);
    if (options?.showOnlyActive)
        result = result.filter((item) => {
            if (isExchangeOrder(item) && item.exchangeOrder)
                return (
                    item.exchangeOrder.active && !item.exchangeOrder.executed
                );
            if (isExchangeWithdrawal(item) && item.exchangeWithdrawalsJoined)
                return (
                    item.exchangeWithdrawalsJoined.active &&
                    !item.exchangeWithdrawalsJoined.executed
                );
            if (isExchangeDeposit(item) && item.exchangeDeposit)
                return (
                    item.exchangeDeposit.active &&
                    !item.exchangeDeposit.executed
                );
        });
    if (options?.showOnlyNonActive)
        result = result.filter((item) => {
            if (isExchangeOrder(item) && item.exchangeOrder)
                return !(
                    item.exchangeOrder.active && !item.exchangeOrder.executed
                );
            if (isExchangeWithdrawal(item) && item.exchangeWithdrawalsJoined)
                return !(
                    item.exchangeWithdrawalsJoined.active &&
                    !item.exchangeWithdrawalsJoined.executed
                );
            if (isExchangeDeposit(item) && item.exchangeDeposit)
                return !(
                    item.exchangeDeposit.active &&
                    !item.exchangeDeposit.executed
                );
        });
    if (options?.onlyOrders) result = result.filter(isExchangeOrder);
    return result;
};

export const exchangeTableItemTypeData = {
    [EXCHANGE_TABLE_ITEM_TYPE.DEPOSIT]: {
        text: 'Depósito',
        color: '',
    },
    [EXCHANGE_TABLE_ITEM_TYPE.ORDER + '-market']: {
        text: 'Orden de Mercado',
        color: '',
    },
    [EXCHANGE_TABLE_ITEM_TYPE.ORDER + '-limit']: {
        text: 'Orden de Límite',
        color: '',
    },
    [EXCHANGE_TABLE_ITEM_TYPE.WITHDRAWAL]: {
        text: 'Extracción',
        color: '',
    },
};
