import { Button, LI, P, UL } from '@common';
import { NavLink, useNavigate } from 'react-router-dom';
import './LandingBottom.scss';
import logo from 'assets/images/logo.png';

import { Col, Nav, Row } from 'reactstrap';
import { Facebook, Instagram, Linkedin, Twitter, Youtube } from 'react-feather';
import { ROUTE_PATH } from 'layout/routes/const';

export const LandingBottom = () => {
    const navigate = useNavigate();

    return (
        <div className='bisbull-landing-bottom'>
            <Row className='content'>
                <Col className='titles' sm={4}>
                    <P className='title'>Descubrí la nueva economía digital</P>
                    <div className='buttons'>
                        <Button
                            onClick={() => navigate(ROUTE_PATH.REGISTER)}
                            color='light'
                        >
                            Registrate
                        </Button>
                        <Button
                            onClick={() => navigate(ROUTE_PATH.LOGIN)}
                            outline
                            color='light'
                        >
                            Iniciar sesión
                        </Button>
                    </div>
                </Col>
                {/* <Col className='links' sm={2}>
                    <P className='title'>Productos</P>
                    <UL className='items'>
                        <LI>
                            <NavLink to='/'>Criptomonedas</NavLink>
                        </LI>
                        <LI>
                            <NavLink to='/'>Wallet</NavLink>
                        </LI>
                        <LI>
                            <NavLink to='/'>Exchange</NavLink>
                        </LI>
                        <LI>
                            <NavLink to='/'>Ripio Select</NavLink>
                        </LI>
                        <LI>
                            <NavLink to='/'>Swap</NavLink>
                        </LI>
                        <LI>
                            <NavLink to='/'>B2B</NavLink>
                        </LI>
                    </UL>
                </Col>
                <Col className='links' sm={2}>
                    <P className='title'>Útiles</P>
                    <UL className='items'>
                        <LI>
                            <NavLink to='/'>Launchpad</NavLink>
                        </LI>
                        <LI>
                            <NavLink to='/'>Como Comprar</NavLink>
                        </LI>
                        <LI>
                            <NavLink to='/'>Medios de pago</NavLink>
                        </LI>
                        <LI>
                            <NavLink to='/'>Seguridad</NavLink>
                        </LI>
                        <LI>
                            <NavLink to='/'>Ayuda</NavLink>
                        </LI>
                    </UL>
                </Col>
                <Col className='links' sm={2}>
                    <P className='title'>Institucional</P>
                    <UL className='items'>
                        <LI>
                            <NavLink to='/'>Nosotros</NavLink>
                        </LI>
                        <LI>
                            <NavLink to='/'>Trabaja con nosotros</NavLink>
                        </LI>
                        <LI>
                            <NavLink to='/'>Terminos y condiciones</NavLink>
                        </LI>
                        <LI>
                            <NavLink to='/'>Politicas de privacidad</NavLink>
                        </LI>
                    </UL>
                </Col> */}
            </Row>
            {/* <Row className='social'>
                <Facebook size={25} />
                <Instagram size={25} />
                <Twitter size={25} />
                <Linkedin size={25} />
                <Youtube size={25} />
            </Row> */}
            <div
                style={{
                    backgroundColor: 'white',
                    position: 'absolute',
                    right: '20px',
                    borderRadius: '5px',
                    padding: '10px',
                    bottom: '20px',
                }}
            >
                <img
                    src={logo}
                    alt='logo'
                    style={{
                        width: '38px',
                    }}
                />
            </div>
        </div>
    );
};
