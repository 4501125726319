import type { ORDER_SIDE } from 'screens/user/quotation/types';

export interface OperatorExchangeOrder {
    id: number;
    userId: number;
    name: string;
    lastname: string;
    email: string;
    exchange: string;
    balanceARS: string;
    balanceUSDT: string;
    tokenpairId: number;
    orderType: string;
    orderSide: ORDER_SIDE;
    symbol: string;
    amount: string;
    amountIn: string;
    netPrice: string;
    priceTarget: string;
    feePercentage: string;
    fee: string;
    fiatSpreadPercentage: string;
    fiatSpread: string;
    createdAt: string;
    updatedAt: string;
    active: boolean;
    executed: boolean;
    changeStateBy: number;
    changeStateAt: string;
    convertedToQuote: number;
}

export interface OperatorUser {
    id: number;
    name: string;
    lastname: string;
    email: string;
    balanceARS: string;
    balanceUSDT: string;
    balanceBTC: string;
    balanceETH: string;
    cuit: string;
    spread: string;
    phoneCountryCode: number;
    phoneNumber: number;
}

export interface ExchangeAdvice {
    id: number;
    asset: string;
    advice: string;
    min: string;
    max: string;
    active: boolean;
    done: boolean;
    currentBalance: string;
    createdAt: string;
    doneAt: string;
    changeStateBy: number;
    changeStateAt: string;
}

export interface OperatorExchangeWithdrawal {
    id: string;
    userId: number;
    currencyId: string;
    amount: string;
    createdAt: string;
    updatedAt: string;
    changeStateAt: string;
    changeStateBy: number;
    active: boolean;
    executed: boolean;
    balanceARS: string;
    balanceUSDT: string;
    balanceBTC: string;
    balanceETH: string;
    email: string;
    name: string;
    lastname: string;
}

export interface OperatorExchangeCryptoWithdrawal {
    id: string;
    address: string;
    userId: number;
    name: string;
    lastname: string;
    email: string;
    balanceARS: string;
    balanceUSDT: string;
    balanceBTC: string;
    balanceETH: string;
    currencyId: string;
    amount: string;
    createdAt: string;
    updatedAt: string;
    changeStateAt: string;
    changeStateBy: number;
    cryptoNetworkId: string;
    active: boolean;
    executed: boolean;
}

export enum WITHDRAWAL_TABLE_ITEM_TYPE {
    ARS,
    CRYPTO,
}

export interface OperatorWithdrawalTableItem {
    type: WITHDRAWAL_TABLE_ITEM_TYPE;
    getExchangeWithdrawals: OperatorExchangeWithdrawal | null;
    exchangeCryptoWithdrawals: OperatorExchangeCryptoWithdrawal | null;
}

export const isExchangeWithdrawals = (
    withdrawalTableItem: OperatorWithdrawalTableItem,
) => withdrawalTableItem.type === WITHDRAWAL_TABLE_ITEM_TYPE.ARS;
export const isExchangeCryptoWithdrawals = (
    withdrawalTableItem: OperatorWithdrawalTableItem,
) => withdrawalTableItem.type === WITHDRAWAL_TABLE_ITEM_TYPE.CRYPTO;

export interface OperatorExchangeDeposit {
    id: string;
    currencyId: number;
    amount: string;
    referenceId: string;
    createdAt: string;
    updatedAt: string;
    changeStateBy: string;
    changeStateAt: string;
    active: boolean;
    executed: boolean;
    address: string;
    balanceARS: string;
    balanceUSDT: string;
    balanceBTC: string;
    balanceETH: string;
    cryptonetworkId: string;
    cuit: string;
    email: string;
    name: string;
    lastname: string;
    userId: number;
}
