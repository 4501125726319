import { createSlice } from '@reduxjs/toolkit';
import { REQUEST_STATE } from 'redux/types';
import {
    cancelExchangeCryptoWithdrawal,
    doneExchangeCryptoWithdrawal,
    getOperatorExchangeCryptoWithdrawal,
    getOperatorExchangeDeposit,
    reactivateUserExchangeLimit,
    suspendUserExchangeLimit,
    getDaemon,
    getExchangeAdvice,
    setExchangeAdviceDone,
    getExchangeOrder,
    getOperatorUser,
    setDaemon,
    getOperatorExchangeWithdrawal,
    doneExchangeWithdrawal,
    cancelExchangeWithdrawal,
} from './operatorService';
import type {
    ExchangeAdvice,
    OperatorExchangeWithdrawal,
    OperatorExchangeOrder,
    OperatorUser,
    OperatorExchangeCryptoWithdrawal,
    OperatorExchangeDeposit,
} from './types';
import {
    mapExchangeAdvices,
    mapOperatorExchangeDeposit,
    mapOperatorExchangeCryptoWithdrawal,
    mapOperatorExchangeOrder,
    mapOperatorExchangeWithdrawal,
    mapOperatorUser,
} from './utils';

interface OperatorState {
    requestStates: {
        getDaemon: REQUEST_STATE;
        setDaemon: REQUEST_STATE;
        getExchangeOrder: REQUEST_STATE;
        getOperatorUser: REQUEST_STATE;
        getExchangeAdvice: REQUEST_STATE;
        setExchangeAdviceDone: REQUEST_STATE;
        getExchangeWithdrawal: REQUEST_STATE;
        doneExchangeWithdrawal: REQUEST_STATE;
        cancelExchangeWithdrawal: REQUEST_STATE;
        suspendUserExchangeLimit: REQUEST_STATE;
        reactivateUserExchangeLimit: REQUEST_STATE;
        getExchangeCryptoWithdrawal: REQUEST_STATE;
        doneExchangeCryptoWithdrawal: REQUEST_STATE;
        cancelExchangeCryptoWithdrawal: REQUEST_STATE;
        getExchangeDeposit: REQUEST_STATE;
    };
    daemonSwitch: boolean | null;
    exchangeOrders: OperatorExchangeOrder[] | null;
    users: OperatorUser[] | null;
    exchangeAdvices: ExchangeAdvice[] | null;
    exchangeWithdrawals: OperatorExchangeWithdrawal[] | null;
    exchangeDeposit: OperatorExchangeDeposit[] | null;
    exchangeCryptoWithdrawals: OperatorExchangeCryptoWithdrawal[] | null;
}

const initialState: OperatorState = {
    requestStates: {
        getDaemon: REQUEST_STATE.NONE,
        setDaemon: REQUEST_STATE.NONE,
        getExchangeOrder: REQUEST_STATE.NONE,
        getOperatorUser: REQUEST_STATE.NONE,
        getExchangeAdvice: REQUEST_STATE.NONE,
        setExchangeAdviceDone: REQUEST_STATE.NONE,
        getExchangeWithdrawal: REQUEST_STATE.NONE,
        doneExchangeWithdrawal: REQUEST_STATE.NONE,
        cancelExchangeWithdrawal: REQUEST_STATE.NONE,
        suspendUserExchangeLimit: REQUEST_STATE.NONE,
        reactivateUserExchangeLimit: REQUEST_STATE.NONE,
        getExchangeCryptoWithdrawal: REQUEST_STATE.NONE,
        doneExchangeCryptoWithdrawal: REQUEST_STATE.NONE,
        cancelExchangeCryptoWithdrawal: REQUEST_STATE.NONE,
        getExchangeDeposit: REQUEST_STATE.NONE,
    },
    daemonSwitch: null,
    exchangeOrders: null,
    users: null,
    exchangeAdvices: null,
    exchangeWithdrawals: null,
    exchangeDeposit: null,
    exchangeCryptoWithdrawals: null,
};

export const operatorSlice = createSlice({
    name: 'operatorSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        /* getDaemon */
        builder.addCase(getDaemon.pending, (state) => {
            state.requestStates.getDaemon = REQUEST_STATE.LOADING;
        });
        builder.addCase(getDaemon.fulfilled, (state, { payload }) => {
            state.requestStates.getDaemon = REQUEST_STATE.OK;
            state.daemonSwitch = payload.switch;
        });
        builder.addCase(getDaemon.rejected, (state) => {
            state.requestStates.getDaemon = REQUEST_STATE.ERROR;
        });

        /* setDaemon */
        builder.addCase(setDaemon.pending, (state) => {
            state.requestStates.setDaemon = REQUEST_STATE.LOADING;
        });
        builder.addCase(setDaemon.fulfilled, (state) => {
            state.requestStates.setDaemon = REQUEST_STATE.OK;
            state.daemonSwitch = !state.daemonSwitch;
        });
        builder.addCase(setDaemon.rejected, (state) => {
            state.requestStates.setDaemon = REQUEST_STATE.ERROR;
        });

        /* getExchangeOrder */
        builder.addCase(getExchangeOrder.pending, (state) => {
            state.requestStates.getExchangeOrder = REQUEST_STATE.LOADING;
        });
        builder.addCase(getExchangeOrder.fulfilled, (state, { payload }) => {
            state.requestStates.getExchangeOrder = REQUEST_STATE.OK;
            state.exchangeOrders = mapOperatorExchangeOrder(payload);
        });
        builder.addCase(getExchangeOrder.rejected, (state) => {
            state.requestStates.getExchangeOrder = REQUEST_STATE.ERROR;
        });

        /* getOperatorUser */
        builder.addCase(getOperatorUser.pending, (state) => {
            state.requestStates.getOperatorUser = REQUEST_STATE.LOADING;
        });
        builder.addCase(getOperatorUser.fulfilled, (state, { payload }) => {
            state.requestStates.getOperatorUser = REQUEST_STATE.OK;
            state.users = mapOperatorUser(payload);
        });
        builder.addCase(getOperatorUser.rejected, (state) => {
            state.requestStates.getOperatorUser = REQUEST_STATE.ERROR;
        });

        /* getExchangeAdvice */
        builder.addCase(getExchangeAdvice.pending, (state) => {
            state.requestStates.getExchangeAdvice = REQUEST_STATE.LOADING;
        });
        builder.addCase(getExchangeAdvice.fulfilled, (state, { payload }) => {
            state.requestStates.getExchangeAdvice = REQUEST_STATE.OK;
            state.exchangeAdvices = mapExchangeAdvices(payload);
        });
        builder.addCase(getExchangeAdvice.rejected, (state) => {
            state.requestStates.getExchangeAdvice = REQUEST_STATE.ERROR;
        });

        /* setExchangeAdviceDone */
        builder.addCase(setExchangeAdviceDone.pending, (state) => {
            state.requestStates.setExchangeAdviceDone = REQUEST_STATE.LOADING;
        });
        builder.addCase(setExchangeAdviceDone.fulfilled, (state) => {
            state.requestStates.setExchangeAdviceDone = REQUEST_STATE.OK;
        });
        builder.addCase(setExchangeAdviceDone.rejected, (state) => {
            state.requestStates.setExchangeAdviceDone = REQUEST_STATE.ERROR;
        });

        /* getExchangeWithdrawal */
        builder.addCase(getOperatorExchangeWithdrawal.pending, (state) => {
            state.requestStates.setExchangeAdviceDone = REQUEST_STATE.LOADING;
        });
        builder.addCase(
            getOperatorExchangeWithdrawal.fulfilled,
            (state, { payload }) => {
                state.requestStates.getOperatorUser = REQUEST_STATE.OK;
                state.exchangeWithdrawals =
                    mapOperatorExchangeWithdrawal(payload);
            },
        );
        builder.addCase(getOperatorExchangeWithdrawal.rejected, (state) => {
            state.requestStates.setExchangeAdviceDone = REQUEST_STATE.ERROR;
        });

        /* doneExchangeWithdrawal */
        builder.addCase(doneExchangeWithdrawal.pending, (state) => {
            state.requestStates.doneExchangeWithdrawal = REQUEST_STATE.LOADING;
        });
        builder.addCase(doneExchangeWithdrawal.fulfilled, (state) => {
            state.requestStates.doneExchangeWithdrawal = REQUEST_STATE.OK;
        });
        builder.addCase(doneExchangeWithdrawal.rejected, (state) => {
            state.requestStates.doneExchangeWithdrawal = REQUEST_STATE.ERROR;
        });

        /* cancelExchangeWithdrawal */
        builder.addCase(cancelExchangeWithdrawal.pending, (state) => {
            state.requestStates.cancelExchangeWithdrawal =
                REQUEST_STATE.LOADING;
        });
        builder.addCase(cancelExchangeWithdrawal.fulfilled, (state) => {
            state.requestStates.cancelExchangeWithdrawal = REQUEST_STATE.OK;
        });
        builder.addCase(cancelExchangeWithdrawal.rejected, (state) => {
            state.requestStates.cancelExchangeWithdrawal = REQUEST_STATE.ERROR;
        });
        /* suspendUserExchangeLimit */
        builder.addCase(suspendUserExchangeLimit.pending, (state) => {
            state.requestStates.suspendUserExchangeLimit =
                REQUEST_STATE.LOADING;
        });
        builder.addCase(suspendUserExchangeLimit.fulfilled, (state) => {
            state.requestStates.suspendUserExchangeLimit = REQUEST_STATE.OK;
        });
        builder.addCase(suspendUserExchangeLimit.rejected, (state) => {
            state.requestStates.suspendUserExchangeLimit = REQUEST_STATE.ERROR;
        });
        /* reactivateUserExchangeLimit */
        builder.addCase(reactivateUserExchangeLimit.pending, (state) => {
            state.requestStates.reactivateUserExchangeLimit =
                REQUEST_STATE.LOADING;
        });
        builder.addCase(reactivateUserExchangeLimit.fulfilled, (state) => {
            state.requestStates.reactivateUserExchangeLimit = REQUEST_STATE.OK;
        });
        builder.addCase(reactivateUserExchangeLimit.rejected, (state) => {
            state.requestStates.reactivateUserExchangeLimit =
                REQUEST_STATE.ERROR;
        });

        /* getExchangeCryptoWithdrawal */
        builder.addCase(
            getOperatorExchangeCryptoWithdrawal.pending,
            (state) => {
                state.requestStates.getExchangeCryptoWithdrawal =
                    REQUEST_STATE.LOADING;
            },
        );
        builder.addCase(
            getOperatorExchangeCryptoWithdrawal.fulfilled,
            (state, { payload }) => {
                state.requestStates.getExchangeCryptoWithdrawal =
                    REQUEST_STATE.OK;
                state.exchangeCryptoWithdrawals =
                    mapOperatorExchangeCryptoWithdrawal(payload);
            },
        );
        builder.addCase(
            getOperatorExchangeCryptoWithdrawal.rejected,
            (state) => {
                state.requestStates.getExchangeCryptoWithdrawal =
                    REQUEST_STATE.ERROR;
            },
        );
        /* doneExchangeCryptoWithdrawal */
        builder.addCase(doneExchangeCryptoWithdrawal.pending, (state) => {
            state.requestStates.doneExchangeCryptoWithdrawal =
                REQUEST_STATE.LOADING;
        });
        builder.addCase(doneExchangeCryptoWithdrawal.fulfilled, (state) => {
            state.requestStates.doneExchangeCryptoWithdrawal = REQUEST_STATE.OK;
        });
        builder.addCase(doneExchangeCryptoWithdrawal.rejected, (state) => {
            state.requestStates.doneExchangeCryptoWithdrawal =
                REQUEST_STATE.ERROR;
        });

        /* cancelExchangeCryptoWithdrawal */
        builder.addCase(cancelExchangeCryptoWithdrawal.pending, (state) => {
            state.requestStates.cancelExchangeCryptoWithdrawal =
                REQUEST_STATE.LOADING;
        });
        builder.addCase(cancelExchangeCryptoWithdrawal.fulfilled, (state) => {
            state.requestStates.cancelExchangeCryptoWithdrawal =
                REQUEST_STATE.OK;
        });
        builder.addCase(cancelExchangeCryptoWithdrawal.rejected, (state) => {
            state.requestStates.cancelExchangeCryptoWithdrawal =
                REQUEST_STATE.ERROR;
        });
        /* getExchangeDeposit */
        builder.addCase(getOperatorExchangeDeposit.pending, (state) => {
            state.requestStates.getExchangeDeposit = REQUEST_STATE.LOADING;
        });
        builder.addCase(
            getOperatorExchangeDeposit.fulfilled,
            (state, { payload }) => {
                state.requestStates.getExchangeDeposit = REQUEST_STATE.OK;
                state.exchangeDeposit = mapOperatorExchangeDeposit(payload);
            },
        );
        builder.addCase(getOperatorExchangeDeposit.rejected, (state) => {
            state.requestStates.getExchangeDeposit = REQUEST_STATE.ERROR;
        });
    },
});

export const operatorActions = operatorSlice.actions;

export const operatorReducer = operatorSlice.reducer;
