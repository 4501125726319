/* eslint-disable import/no-duplicates */
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { capitalize } from 'utils';

export const formatISODate = (dateISO: string | null): string => {
    if (!dateISO) return '';
    const formatted = format(parseISO(dateISO), 'dd MMM yyyy - HH:mm:ss', {
        locale: es,
    }).split(' ');
    const monthCap = capitalize(formatted[1]);
    return [formatted[0], monthCap, ...formatted.slice(2)].join(' ');
};
