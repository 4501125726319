import type { DetailedHTMLProps, HTMLAttributes } from 'react';

interface H6ElementProps
    extends DetailedHTMLProps<
        HTMLAttributes<HTMLHeadingElement>,
        HTMLHeadingElement
    > {
    fontWeight?:
        | '100'
        | '200'
        | '300'
        | '400'
        | '500'
        | '600'
        | '700'
        | '800'
        | '900';
    display?: '1' | '2' | '3' | '4';
    subtitle?: boolean;
    mb0?: boolean;
}
export const H6 = (props: H6ElementProps) => {
    const getClassName = () => {
        let result = '';

        switch (props.fontWeight) {
            case '100':
                result += 'f-w-100';
                break;
            case '200':
                result += 'f-w-200';
                break;
            case '300':
                result += 'f-w-300';
                break;
            case '400':
                result += 'f-w-400';
                break;
            case '500':
                result += 'f-w-500';
                break;
            case '600':
                result += 'f-w-600';
                break;
            case '700':
                result += 'f-w-700';
                break;
            case '800':
                result += 'f-w-800';
                break;
            case '900':
                result += 'f-w-900';
                break;
            default:
                result += '';
        }

        switch (props.display) {
            case '1':
                result += 'display-1';
                break;
            case '2':
                result += 'display-2';
                break;
            case '3':
                result += 'display-3';
                break;
            case '4':
                result += 'display-4';
                break;
            default:
                result += '';
        }
        if (props.subtitle) {
            result += 'sub-title';
        }

        if (props.mb0) {
            result += 'mb-0';
        }

        if (props.className) {
            result += ' ' + props.className;
        }

        return result;
    };

    return (
        <h6 className={`${getClassName()}`} style={props.style}>
            {props.children}
        </h6>
    );
};
