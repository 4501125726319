import { Checkbox, FormInput, Button } from '@common';
import { SessionCard } from '../components/SessionCard';
import { Row, Col } from 'reactstrap';
import { validateWithPasswordFields } from '@utils';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { REQUEST_STATE } from 'redux/types';
import { register } from '../sessionService';
import { getSelectOptionFromCountries } from 'common/FormInput/utils';
import { getCountries } from 'common/commonService';
import { RegisterThanks } from './RegisterThanks';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import YupPassword from 'yup-password';
import { toast } from 'react-toastify';

YupPassword(Yup);

export interface RegisterValues {
    name: string;
    surname: string;
    cuit: string;
    email: string;
    password: string;
    confirmPassword: string;
    country: string;
    phoneCode: string;
    phone: string;
    checkLegalAge: string;
    checkPersonalData: string;
}

const validationSchema = Yup.object({
    name: Yup.string().required('El nombre es requerido'),
    surname: Yup.string().required('El apellido es requerido'),
    cuit: Yup.string()
        .required('El CUIT es requerido')
        .min(11, 'Deben ser 11 caracteres'),
    email: Yup.string()
        .email('El email no es válido')
        .required('El email es requerido'),
    password: Yup.string()
        .password()
        .min(8, '8 caracteres')
        .minLowercase(0)
        .minUppercase(1, '1 mayúscula')
        .minNumbers(1, '1 número')
        .minSymbols(1, '1 símbolo'),
    confirmPassword: Yup.string()
        .required('La contraseña es requerida')
        .oneOf([Yup.ref('password'), null], 'La contraseña no coincide'),
    country: Yup.string().required('El país es requerido'),
    phone: Yup.string().required('El número es requerido'),
    checkLegalAge: Yup.string().test(
        'checkLegalAge',
        'Debes ser mayor de edad',
        (value) => value === 'true',
    ),
    checkPersonalData: Yup.string().test(
        'checkPersonalData',
        'Debes aceptar los términos y condiciones',
        (value) => value === 'true',
    ),
});

export const Register = () => {
    const dispatch = useAppDispatch();
    const [checked, setChecked] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [phoneData, setPhoneData] = useState({ code: '', phone: '' });
    const registerState = useAppSelector((state) => state.session).requestStates
        .register;
    const { countries } = useAppSelector((state) => state.common);
    const [renderRegisterThanksScreen, setRenderRegisterThanksScreen] =
        useState(false);

    const formik = useFormik({
        initialValues: {
            name: '',
            surname: '',
            cuit: '',
            email: '',
            password: '',
            confirmPassword: '',
            country: '',
            phoneCode: '',
            phone: '',
            checkLegalAge: '',
            checkPersonalData: '',
        },
        validationSchema: validationSchema,
        validate: (values) =>
            validateWithPasswordFields(values, validationSchema, ['password']),
        onSubmit: () => {
            dispatch(
                register({
                    name: formik.values.name.trim(),
                    lastname: formik.values.surname.trim(),
                    email: formik.values.email,
                    phone_country_cod: phoneData.code,
                    phone_number: phoneData.phone,
                    password: formik.values.password,
                    re_password: formik.values.confirmPassword,
                    cuit: formik.values.cuit.replaceAll('-', '').trim(),
                    country: formik.values.country,
                }),
            )
                .unwrap()
                .then(() => {
                    setRenderRegisterThanksScreen(true);
                })
                .catch((error: any) => {
                    toast.error(error.message);
                });
        },
    });

    useEffect(() => {
        dispatch(getCountries());
    }, []);

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === 'checkLegalAge') {
            setChecked(e.target.checked);
            formik.setFieldValue('checkLegalAge', e.target.checked);
        }
        if (e.target.name === 'checkPersonalData') {
            setChecked2(e.target.checked);
            formik.setFieldValue('checkPersonalData', e.target.checked);
        }
    };

    if (renderRegisterThanksScreen)
        return (
            <div className='login-card'>
                <RegisterThanks />
            </div>
        );
    return (
        <div className='login-card'>
            <SessionCard
                title='Crear cuenta en Exchange Bisbull'
                subtitle='Ingresá a la economía cripto. Creá tu cuenta, seguí los pasos de activación y adelante!'
                src=''
                onSubmit={formik.handleSubmit}
                noImage
            >
                <Row>
                    <Col>
                        <FormInput
                            label='Nombre'
                            name='name'
                            value={formik.values.name}
                            onChange={() => null}
                            onFormikChange={formik.handleChange}
                            placeholder='Nombre'
                            error={formik.errors.name}
                        />
                    </Col>
                    <Col>
                        <FormInput
                            label='Apellido'
                            name='surname'
                            value={formik.values.surname}
                            onChange={() => null}
                            onFormikChange={formik.handleChange}
                            placeholder='Apellido'
                            error={formik.errors.surname}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormInput
                            label='Email'
                            name='email'
                            type='email'
                            value={formik.values.email}
                            onChange={() => null}
                            onFormikChange={formik.handleChange}
                            placeholder='example@mail.com'
                            error={formik.errors.email}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormInput
                            label='Contraseña'
                            name='password'
                            type='password'
                            value={formik.values.password}
                            onChange={() => null}
                            onFormikChange={formik.handleChange}
                            passwordError={formik.errors.password}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormInput
                            label='Confirmar contraseña'
                            name='confirmPassword'
                            type='password'
                            value={formik.values.confirmPassword}
                            onChange={() => null}
                            onFormikChange={formik.handleChange}
                            error={formik.errors.confirmPassword}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormInput
                            label='País'
                            name='country'
                            type='select'
                            options={getSelectOptionFromCountries(countries)}
                            // TODO: implement loading behaviour
                            // loading={getCountriesState === REQUEST_STATE.LOADING}
                            onChange={() => null}
                            onFormikChange={formik.handleChange}
                            value={formik.values.country}
                            error={formik.errors.country}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormInput
                            label='CUIT'
                            name='cuit'
                            type='cuit'
                            onChange={() => null}
                            onFormikChange={formik.handleChange}
                            value={formik.values.cuit}
                            error={formik.errors.cuit}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormInput
                            label='Teléfono'
                            name='phone'
                            type='tel'
                            value={formik.values.phone}
                            onChange={() => null}
                            onFormikPhoneChange={(e, data) => {
                                formik.handleChange(e);
                                setPhoneData(data);
                            }}
                            error={formik.errors.phone}
                        />
                    </Col>
                </Row>
                <Row className='mt-3' style={{ alignItems: 'center' }}>
                    <Col>
                        <Checkbox
                            name='checkLegalAge'
                            label='Soy mayor de 18 años y acepto los términos y condiciones'
                            isChecked={checked}
                            onChange={() => null}
                            onFormikChange={handleCheckboxChange}
                            error={formik.errors.checkLegalAge}
                        />
                    </Col>
                </Row>
                <Row className='mb-5' style={{ alignItems: 'center' }}>
                    <Col>
                        <Checkbox
                            name='checkPersonalData'
                            label='Autorizo el tratamiento de mis datos personales, conforme con el Aviso de Privacidad y la Politica de Tratamiento de Datos Personales'
                            isChecked={checked2}
                            onChange={() => null}
                            onFormikChange={handleCheckboxChange}
                            error={formik.errors.checkPersonalData}
                        />
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <Button
                        type='submit'
                        loading={registerState === REQUEST_STATE.LOADING}
                        style={{ marginLeft: '0px' }}
                    >
                        Ingresar
                    </Button>
                </Row>
            </SessionCard>
        </div>
    );
};
