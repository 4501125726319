import { Button, FormInput, P } from '@common';
import { HelpPage } from '../HelpPage';
import { Col, Row } from 'reactstrap';
import { createInitialFormState, isFormValid, validateInput } from '@utils';
import type { FormEvent } from 'react';
import { useEffect, useState } from 'react';
import './SendConsult.scss';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getSelectOptionFromCountries } from 'common/FormInput/utils';
import { getCountries } from 'common/commonService';

interface SendConsultProps {
    subtitle?: string;
    onSubmit: (values: SendConsultFormValues) => void;
}

const SendConsultFormFields = [
    'email',
    'country',
    'subject',
    'files',
    'details',
] as const;
type SendConsultFormField = typeof SendConsultFormFields[number];
type SendConsultFormValues = { [key in SendConsultFormField]: string };

const initialForm = createInitialFormState<SendConsultFormField>([
    'email',
    'country',
    'subject',
    'files',
    'details',
]);

export const SendConsult = (props: SendConsultProps) => {
    const { countries } = useAppSelector((state) => state.common);
    const [form, setForm] = useState(initialForm);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getCountries());
    }, []);

    const handleChange = (
        id: SendConsultFormField,
        type: string,
        value: string,
        errorText: string,
    ) => {
        const isValid = validateInput(type, value);
        setForm({
            values: {
                ...form.values,
                [id]: value,
            },
            errors: {
                ...form.errors,
                [id]: isValid ? null : errorText,
            },
            touched: {
                ...form.touched,
                [id]: true,
            },
        });
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        props.onSubmit(form.values);
    };

    const validForm = isFormValid(form);

    return (
        <HelpPage className='send-consult' title='Enviar una solicitud'>
            <P style={{ marginBottom: '30px' }}>
                {props.subtitle ||
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua'}
            </P>
            <Row>
                <Col>
                    <FormInput
                        label='Correo electrónico'
                        name='email'
                        type='email'
                        value={form.values.email}
                        onChange={(value) =>
                            handleChange(
                                'email',
                                'email',
                                value,
                                'El email no es válido',
                            )
                        }
                        placeholder='example@mail.com'
                        error={form.errors.email}
                    />
                </Col>
                <Col className='country-form'>
                    <FormInput
                        label='País'
                        name='country'
                        type='select'
                        options={getSelectOptionFromCountries(countries)}
                        onChange={(value) =>
                            handleChange(
                                'country',
                                'select',
                                value,
                                'El país no es válido',
                            )
                        }
                        value={form.values.country}
                        error={form.errors.country}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormInput
                        label='Asunto de la consulta'
                        name='subject'
                        type='text'
                        value={form.values.subject}
                        onChange={(value) =>
                            handleChange(
                                'subject',
                                'subject',
                                value,
                                'El asunto no es válido',
                            )
                        }
                        placeholder=''
                        error={form.errors.subject}
                    />
                    <div className='bigger'>
                        <FormInput
                            label='¿Cómo podemos ayudarte?'
                            name='details'
                            type='textarea'
                            value={form.values.details}
                            onChange={(value) =>
                                handleChange(
                                    'details',
                                    'textarea',
                                    value,
                                    'El detalle no es válido',
                                )
                            }
                            placeholder='Ingrese los detalles de esta solicitud. Un integrante de nuestro personal de soporte respondera en breve'
                            error={form.errors.details}
                        />
                    </div>
                </Col>
                <Col>
                    <FormInput
                        type='file'
                        onChange={(value) =>
                            handleChange(
                                'files',
                                'file',
                                value,
                                'El detalle no es válido',
                            )
                        }
                        name=''
                        value={form.values.files}
                        label='Archivos adjuntos'
                    />
                    <Button
                        className='btn-right'
                        type='submit'
                        disabled={!validForm}
                        onClick={handleSubmit}
                    >
                        Enviar
                    </Button>
                </Col>
            </Row>
        </HelpPage>
    );
};
