import type { CSSProperties } from 'react';
import './LoaderWheel.scss';

interface LoaderWheelProps {
    white?: boolean;
    style?: CSSProperties;
    className?: string;
}

export const LoaderWheel = (props: LoaderWheelProps) => {
    const getClassName = () => {
        let result = 'loader-box';
        if (props.white) result += ' white';
        if (props.className) result += ' ' + props.className;
        return result;
    };

    return (
        <div
            className={getClassName()}
            style={{ height: '100%', ...props.style }}
        >
            <div className='loader-7' />
        </div>
    );
};
