export enum COLOR {
    primary = 'primary',
    secondary = 'secondary',
    success = 'success',
    danger = 'danger',
    warning = 'warning',
    info = 'info',
    light = 'light',
    dark = 'dark',
    transparent = 'transparent',
}

export type Color = `${COLOR}`;

export interface Country {
    id: number;
    name: string;
    code: string;
    active: boolean;
}

export interface Coin {
    id: string;
    name: string;
    tokenCode: string;
    isCrypto: boolean;
    isActive: boolean;
}

export interface TokenPair {
    id: number;
    baseCode: string;
    quoteCode: string;
    isCrypto: boolean;
    symbol: string;
    symbolPretty: string;
}

export interface TokenPairPrice {
    tokenpairId: number;
    baseCode: string;
    baseBalance: string;
    quoteCode: string;
    quoteBalance: string;
    symbol: string;
    symbolPretty: string;
    isCrypto: boolean;
    netPrice: string;
    totalPrice: string;
    fiatSpreadPercentage: string;
    fiatSpreadAmount: string;
    feePercentage: string;
    feeAmount: string;
}

export interface Balance {
    currencyId: number;
    tokenCode: string;
    amount: string;
}

export interface TokenPairPrice24hr {
    tokenpairId: number;
    baseId: number;
    quoteId: number;
    symbol: string;
    priceChange: string;
    priceChangePercent: string;
    weightedAvgPrice: string;
    prevClosePrice: string;
    lastPrice: string;
}

export interface BalanceExtended {
    currencyId: number;
    tokenCode: string;
    amount: string;
    amountARS: string;
    amountUSD: string;
    balanceBTC: string;
    balanceETH: string;
}
