import { Row } from 'reactstrap';
import { TotalBalance } from './TotalBalance';
import { WalletCard } from './WalletCard';

export const Wallet = () => {
    return (
        <div className='m-3'>
            <Row>
                <TotalBalance />
            </Row>
            <Row>
                <WalletCard />
            </Row>
        </div>
    );
};
