import type { DetailedHTMLProps, HTMLAttributes } from 'react';

interface H1ElementProps
    extends DetailedHTMLProps<
        HTMLAttributes<HTMLHeadingElement>,
        HTMLHeadingElement
    > {
    fontWeight?:
        | '100'
        | '200'
        | '300'
        | '400'
        | '500'
        | '600'
        | '700'
        | '800'
        | '900';
    display?: '1' | '2' | '3' | '4';
    className?: string;
    style?: React.CSSProperties;
}
export const H1 = (props: H1ElementProps) => {
    const getClassName = () => {
        let result = '';

        if (props.fontWeight) result += 'f-w-' + props.fontWeight;

        if (props.display) result += ' display-' + props.display;

        if (props.className) result += props.className;

        return result;
    };

    return (
        <h1 className={`${getClassName()}`} style={props.style}>
            {props.children}
        </h1>
    );
};
