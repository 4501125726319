import { Row } from 'reactstrap';
import { TotalBalance } from '../wallet/TotalBalance';
import { ConvertCryptos } from './ConvertCryptos';

export const Conversor = () => {
    return (
        <div className='m-3'>
            <Row>
                <TotalBalance />
            </Row>
            <Row>
                <ConvertCryptos />
            </Row>
        </div>
    );
};
