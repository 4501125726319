export class ConfigDB {
    static data = {
        // settings: {
        //     layout_type: 'ltr',
        //     sidebar: {
        //         type: 'compact-wrapper',
        //     },
        //     sidebar_setting: 'default-sidebar',
        // },
        color: {
            // primary_color: '#26695c',
            // secondary_color: '#c29873',
            mix_background_layout: 'light-only',
        },
        // router_animation: 'fadeIn',
    };
}
