import { Button } from 'common';
import { ArrowLeft } from 'react-feather';
import type { NavigateFunction } from 'react-router-dom';

export const BackButton = ({ navigate }: { navigate: NavigateFunction }) => (
    <Button
        className='btn btn-primary-light d-flex align-items-center justify-content-center'
        color='light'
        onClick={() => navigate(-1)}
        style={{
            marginLeft: '0px',
            position: 'absolute',
            bottom: '20px',
            height: 35,
            width: 120,
        }}
    >
        <ArrowLeft size={18} className='mr-1' />
        Volver
    </Button>
);
