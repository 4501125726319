import { useState } from 'react';
import type { ReactNode } from 'react';
import { Button, P } from '@common';
import { ArrowLeft } from 'react-feather';
import { Col, Row } from 'reactstrap';
import { BuyCard } from '../../quotation/BuyCard/BuyCard';
import { NavLink, useNavigate } from 'react-router-dom';
import './Deposit.scss';
import { InputAmount } from 'screens/user/quotation/InputAmount';

interface DepositProps {
    topComponentTitle?: ReactNode;
    topComponentSubTitle?: ReactNode;
}

export const Deposit = (props: DepositProps) => {
    const navigate = useNavigate();

    const [value, setValue] = useState('');

    return (
        <div className='deposit-card'>
            <div className='btn-container'>
                <Button
                    className='d-flex align-items-center'
                    color='transparent'
                    onClick={() => navigate(-1)}
                >
                    <ArrowLeft size={18} className='mr-1' />
                    Volver
                </Button>
            </div>
            <P
                className='title'
                style={{ justifyContent: 'center', display: 'flex' }}
            >
                {props.topComponentTitle || (
                    <>
                        <strong>Ingresá el monto</strong> &nbsp; que quieras
                        &nbsp;
                        <strong>depositar</strong>
                    </>
                )}
            </P>
            <P className='subtitle'>
                {props.topComponentTitle ||
                    'El monto mínimo para carga de saldo es de $10.000 con cualquier medio de pago '}
            </P>
            {/* Center content row  */}
            <Row style={{ justifyContent: 'center', display: 'flex' }}>
                <BuyCard noBack noPaddingTop>
                    <div style={{ paddingTop: '10px !important' }}>
                        <div>
                            <InputAmount
                                value={value}
                                text='Límite de compra $700000000 ARS'
                                placeholder='$10.0000 ARS'
                                onChange={(value) => {
                                    setValue(value);
                                }}
                            />
                        </div>
                    </div>

                    <div className='results'>
                        <Row style={{ marginTop: '30px' }}>
                            <Col>
                                <P>Monto</P>
                            </Col>
                            <Col>
                                <P>$0.00 ARS</P>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col>
                                <P>Comisión</P>
                            </Col>
                            <Col>
                                <P>$0.00 ARS</P>
                            </Col>
                        </Row>
                        <hr
                            style={{
                                //marginRight: '160px',
                                backgroundColor: '#000',
                            }}
                        />
                        <Row style={{ marginTop: '15px' }}>
                            <Col>
                                <P style={{ fontWeight: '600' }}>Total</P>
                            </Col>
                            <Col>
                                <P style={{ fontWeight: '600' }}>$0.00 ARS</P>
                            </Col>
                        </Row>
                    </div>
                    <Button
                        style={{ marginTop: '40px' }}
                        className='m-3'
                        onClick={() => navigate('/')}
                    >
                        <P>Ir a pagar</P>
                    </Button>
                    <P
                        color='danger'
                        style={{
                            textDecoration: 'underline',
                            marginBottom: '10px',
                        }}
                    >
                        <NavLink
                            onClick={() => navigate(-1)}
                            to=''
                            style={{ color: '#d43554' }}
                        >
                            Volver
                        </NavLink>
                    </P>
                </BuyCard>
            </Row>
        </div>
    );
};
