import { Card, P } from '@common';
import './CryptoCard.scss';
import { useLoadedImage } from 'utils';
import imgCard from 'assets/images/crypto_card_background.png';

export const CryptoCard = () => {
    const loaded = useLoadedImage(imgCard);

    return (
        <div className={`crypto-card ${!!loaded ? 'loaded' : ''}`}>
            <Card>
                <div>
                    <P style={{ width: '130px', color: 'white' }}>
                        ¿Te imaginás poder <strong>Pagar</strong> Todas Tus{' '}
                        <strong>Compras Con Cryptos?</strong>{' '}
                    </P>
                </div>
                {/* <Button
                    style={{
                        width: '47.8px',
                        height: '47.8px',
                        flexGrow: 0,
                        margin: '20px 72.8px 0 0px',
                        padding: '22.9px 12px 22.9px',
                        borderRadius: '50%',
                    }}
                >
                    <i
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '24px',
                            height: '0px',
                        }}
                    >
                        <ArrowRight />
                    </i>
                </Button> */}
            </Card>
        </div>
    );
};
