import axios from 'axios';
import { ALL_URL } from 'redux/url';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const AUTHENTICATED = 'AUTHENTICATED';
export const FIRST_TIME_2FA = 'FIRST_TIME_2FA';
export const RETRIED_REFRESH = 'RETRIED_REFRESH';

export const refreshTokenRequest = async (redirect: boolean) => {
    try {
        const authenticated = localStorage.getItem(AUTHENTICATED);
        const refresh = localStorage.getItem(REFRESH_TOKEN);
        if (authenticated !== 'true') throw new Error('');
        const { data } = await axios.post(
            process.env.REACT_APP_PUBLIC_URL + '/' + ALL_URL.REFRESH,
            {
                refresh,
            },
        );
        localStorage.setItem(ACCESS_TOKEN, data.access);
        localStorage.setItem(REFRESH_TOKEN, data.refresh);
        localStorage.setItem(AUTHENTICATED, 'true');
        localStorage.removeItem(RETRIED_REFRESH);
        return data;
    } catch (error: any) {
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);
        localStorage.removeItem(AUTHENTICATED);
    }
};

const TEN_MINUTES = 600000;
const getRefreshInterval = () => {
    return setInterval(async () => {
        await refreshTokenRequest(true);
    }, TEN_MINUTES);
};
let interval = getRefreshInterval();

export const testInterval = () => {
    clearInterval(interval);
    interval = getRefreshInterval();
};

/*
    Axios instance initialization and config
*/

export const axiosInstance = axios.create();

axiosInstance.defaults.baseURL = process.env.REACT_APP_PUBLIC_URL;
axiosInstance.defaults.headers.common = {};

// add authorization if has it, request that do not need it wont matter.
axiosInstance.interceptors.request.use(
    async (config) => {
        const access = localStorage.getItem(ACCESS_TOKEN);
        if (access) {
            config.headers = {
                ...config.headers,
                'Content-Type': 'application/json',
                accept: 'application/json',
                authorization: `JWT ${access}`,
            };
        }

        return config;
    },
    (error) => Promise.reject(error),
);

// on unauthorized token refresh logic
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const config = error?.config;
        if (error?.response?.status === 401) {
            const retried = config?._retry;
            if (!retried) {
                config._retry = true;
                await refreshTokenRequest(false);
                const access = localStorage.getItem(ACCESS_TOKEN);
                if (access) {
                    config.headers = {
                        ...config.headers,
                        authorization: `JWT ${access}`,
                    };
                }
                return axiosInstance(config);
            } else {
                localStorage.removeItem(ACCESS_TOKEN);
                localStorage.removeItem(REFRESH_TOKEN);
                localStorage.removeItem(AUTHENTICATED);
            }
        }
        return Promise.reject(error);
    },
);
