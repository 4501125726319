import { toast } from 'react-toastify';
import { formatNumber } from 'utils/string';
import { modalActions } from 'common/Modal/modalSlice';
import {
    addExchangeQuote,
    cancelExchangeLimit,
    getExchangeOrders,
} from '../../quotation/quotationService';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { Button, P, Table } from '@common';
import type { ExchangeOrder } from '../../quotation/types';
import { REQUEST_STATE } from 'redux/types';
import { dotAndComma } from 'utils/currency';
import { MODAL_OPEN } from 'common/Modal/const';
import { formatISODate } from 'common/Table/utils';

export const getOrderTypeData = (
    key: string,
): { color?: 'primary' | 'secondary'; text: string } => {
    if (key === 'EXCHANGE LIMIT') return { color: 'secondary', text: 'Límite' };
    if (key === 'EXCHANGE MARKET') return { color: 'primary', text: 'Mercado' };
    return { color: undefined, text: '' };
};
export const getOrderSideText = (key: string) => {
    if (key === 'BUY') return 'Compra';
    if (key === 'SELL') return 'Venta';
    return '';
};

interface OpenOrdersTableTabProps {
    data: ExchangeOrder[];
}

export const OpenOrdersTableTab = (props: OpenOrdersTableTabProps) => {
    const dispatch = useAppDispatch();
    const { tokenPairPrices } = useAppSelector((state) => state.common);
    const { cancelExchangeLimit: cancelExchangeLimitReq } = useAppSelector(
        (state) => state.quotation.requestStates,
    );
    const { getExchangeOrder: getExchangeOrderReq } = useAppSelector(
        (state) => state.quotation.requestStates,
    );

    return (
        <div style={{ overflowX: 'auto' }}>
            <Table
                loading={getExchangeOrderReq === REQUEST_STATE.LOADING}
                noDataText='No hay operaciones'
                data={props.data || []}
                columns={[
                    {
                        id: 'id',
                        header: 'Número de operación',
                    },
                    {
                        id: 'createdAt',
                        header: 'Fecha de creación',
                        cell: (cell, row) => formatISODate(row.createdAt),
                    },
                    {
                        id: 'tokenpairId',
                        header: 'Par',
                        cell: (cell, row) => {
                            const foundTPP = tokenPairPrices?.find(
                                (tpp) => tpp.tokenpairId === cell,
                            );
                            if (!tokenPairPrices || !foundTPP) return '';
                            return (
                                <P style={{ fontWeight: 'bold' }}>
                                    {foundTPP.symbolPretty}
                                </P>
                            );
                        },
                    },
                    {
                        id: 'orderType',
                        header: 'Tipo',
                        cell: (cell) => {
                            const orderTypeData = getOrderTypeData(cell);
                            return (
                                <P color={orderTypeData.color}>
                                    {orderTypeData.text}
                                </P>
                            );
                        },
                    },
                    {
                        id: 'orderSide',
                        header: 'Compra/Venta',
                        cell: getOrderSideText,
                    },
                    {
                        id: 'amount',
                        header: 'Cantidad',
                        cell: (value, row) =>
                            `${dotAndComma(formatNumber(value))} ${
                                row.amountIn
                            }`,
                    },
                    {
                        id: 'priceTarget',
                        header: 'Precio Objetivo',
                        cell: (value, row) => {
                            const foundTPP = tokenPairPrices?.find(
                                (tpp) => tpp.tokenpairId === row.tokenpairId,
                            );
                            if (!tokenPairPrices || !foundTPP) return ' ';
                            return `${dotAndComma(formatNumber(value))} ${
                                foundTPP.quoteCode
                            }`;
                        },
                    },
                    {
                        id: '__actions',
                        header: 'Acciones',
                        cell: (cell, row) => {
                            if (row.orderType === 'EXCHANGE MARKET')
                                return null;
                            return (
                                <div className='d-flex flex-row flex-nowrap'>
                                    <Button
                                        color='danger'
                                        disabled={
                                            cancelExchangeLimitReq ===
                                            REQUEST_STATE.LOADING
                                        }
                                        onClick={() => {
                                            dispatch(
                                                cancelExchangeLimit({
                                                    exchange: row.exchange,
                                                }),
                                            )
                                                .unwrap()
                                                .then(() => {
                                                    dispatch(
                                                        getExchangeOrders(),
                                                    );
                                                })
                                                .catch(() => {
                                                    toast.error(
                                                        'Hubo un error al cancelar la orden de límite, intente lo más tarde.',
                                                    );
                                                });
                                        }}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        style={{ marginLeft: 10 }}
                                        color='info'
                                        onClick={() =>
                                            dispatch(
                                                addExchangeQuote({
                                                    amount: row.amount,
                                                    amount_in: row.amountIn,
                                                    order_side: row.orderSide,
                                                    symbol: row.symbol,
                                                    tokenpair_id:
                                                        row.tokenpairId,
                                                    executeAfterConfirm:
                                                        row.exchange,
                                                }),
                                            )
                                                .unwrap()
                                                .then(() => {
                                                    dispatch(
                                                        modalActions.setModalOpen(
                                                            MODAL_OPEN.BUY_CONFIRM,
                                                        ),
                                                    );
                                                })
                                                .catch(() => {
                                                    toast.error(
                                                        'Hubo un error al ejecutar su orden (paso 1 de 2, crear orden de mercado), vuelve a intentarlo',
                                                    );
                                                })
                                        }
                                    >
                                        Ejecutar
                                    </Button>
                                </div>
                            );
                        },
                    },
                ]}
            />
        </div>
    );
};
