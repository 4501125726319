import type { ORDER_SIDE } from 'screens/user/quotation/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ALL_URL } from 'redux/url';
import { axiosInstance } from 'utils/service';

enum OPERATOR_REQUEST {
    GET_SWITCH = 'GET_SWITCH',
    SET_SWITCH = 'SET_SWITCH',
    GET_EXCHANGE_ORDER = 'GET_EXCHANGE_ORDER',
    GET_OPERATOR_USER = 'GET_OPERATOR_USER',
    GET_EXCHANGE_ADVICE = 'GET_EXCHANGE_ADVICE',
    SET_EXCHANGE_ADVICE_DONE = 'SET_EXCHANGE_ADVICE_DONE',
    SET_EXCHANGE_ADVICE_ACTIVATE = 'SET_EXCHANGE_ADVICE_ACTIVATE',
    GET_EXCHANGE_WITHDRAWAL = 'GET_EXCHANGE_WITHDRAWAL',
    GET_EXCHANGE_DEPOSIT = 'GET_EXCHANGE_DEPOSIT',
    REACTIVATE_USER_EXCHANGE_LIMIT = 'REACTIVATE_USER_EXCHANGE_LIMIT',
    SUSPEND_USER_EXCHANGE_LIMIT = 'SUSPEND_USER_EXCHANGE_LIMIT',
    EXCHANGE_WITHDRAWAL_DONE = 'EXCHANGE_WITHDRAWAL_DONE',
    EXCHANGE_WITHDRAWAL_CANCEL = 'EXCHANGE_WITHDRAWAL_CANCEL',
    GET_EXCHANGE_WITHDRAWAL_CRYPTO = ' GET_EXCHANGE_WITHDRAWAL_CRYPTO',
    EXCHANGE_WITHDRAWAL_CRYPTO_DONE = 'EXCHANGE_WITHDRAWAL_CRYPTO_DONE',
    EXCHANGE_WITHDRAWAL_CRYPTO_CANCEL = 'EXCHANGE_WITHDRAWAL_CRYPTO_CANCEL',
}

type NoneObject = Record<string, never>;

interface DaemonSwitchResponse {
    switch: boolean;
}

type GetDaemonResponse = DaemonSwitchResponse;

export const getDaemon = createAsyncThunk<GetDaemonResponse>(
    OPERATOR_REQUEST.GET_SWITCH,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(
                ALL_URL.OPERATOR_DAEMON_SWITCH,
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

interface SetDaemonParams {
    switch: boolean;
}
type SetDaemonResponse = DaemonSwitchResponse;

export const setDaemon = createAsyncThunk<SetDaemonResponse, SetDaemonParams>(
    OPERATOR_REQUEST.SET_SWITCH,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.post(
                ALL_URL.OPERATOR_DAEMON_SWITCH,
                params,
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

export interface OperatorExchangeOrderItem {
    id: number;
    user_id: number;
    name: string;
    lastname: string;
    email: string;
    exchange: string;
    balance_ARS: string;
    balance_USDT: string;
    tokenpair_id: number;
    order_type: string;
    order_side: ORDER_SIDE;
    symbol: string;
    amount: string;
    amount_in: string;
    net_price: string;
    price_target: string;
    fee_perc: string;
    fee: string;
    fiat_spread_perc: string;
    fiat_spread: string;
    created_at: string;
    updated_at: string;
    active: boolean;
    executed: boolean;
    change_state_by: number;
    change_state_at: string;
    converted_to_quote: number;
}
type GetExchangeOrderResponse = OperatorExchangeOrderItem[];

export const getExchangeOrder = createAsyncThunk<GetExchangeOrderResponse>(
    OPERATOR_REQUEST.GET_EXCHANGE_ORDER,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(
                ALL_URL.OPERATOR_EXCHANGE_ORDER,
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

export interface UserResponse {
    id: number;
    name: string;
    lastname: string;
    email: string;
    balance_ARS: string;
    balance_USDT: string;
    balance_BTC: string;
    balance_ETH: string;
    cuit: string;
    spread: string;
    phone_country_cod: number;
    phone_number: number;
}
type GetOperatorUserResponse = UserResponse[];

export const getOperatorUser = createAsyncThunk<GetOperatorUserResponse>(
    OPERATOR_REQUEST.GET_OPERATOR_USER,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(ALL_URL.OPERATOR_USERS);
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

export interface ExchangeAdviceItem {
    id: number;
    asset: string;
    advice: string;
    min: string;
    max: string;
    active: boolean;
    done: boolean;
    current_balance: string;
    created_at: string;
    done_at: string;
    change_state_by: number;
    change_state_at: string;
}
type GetEchangeAdviceResponse = ExchangeAdviceItem[];

export const getExchangeAdvice = createAsyncThunk<GetEchangeAdviceResponse>(
    OPERATOR_REQUEST.GET_EXCHANGE_ADVICE,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(
                ALL_URL.OPERATOR_EXCHANGE_ADVICE,
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

interface SetExchangeAdviceDoneParams {
    id: string;
    done: boolean;
}
type SetEchangeAdviceDoneResponse = NoneObject;

export const setExchangeAdviceDone = createAsyncThunk<
    SetEchangeAdviceDoneResponse,
    SetExchangeAdviceDoneParams
>(
    OPERATOR_REQUEST.SET_EXCHANGE_ADVICE_DONE,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.post(
                ALL_URL.OPERATOR_EXCHANGE_ADVICE_DONE + params.id + '/',
                params,
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

export interface OperatorExchangeWithdrawalItem {
    id: string;
    user_id: number;
    currency_id: string;
    amount: string;
    created_at: string;
    updated_at: string;
    change_state_at: string;
    change_state_by: number;
    active: boolean;
    executed: boolean;
    balance_ARS: string;
    balance_USDT: string;
    balance_BTC: string;
    balance_ETH: string;
    email: string;
    name: string;
    lastname: string;
}

type GetExchangeWithdrawalResponse = OperatorExchangeWithdrawalItem[];

export const getOperatorExchangeWithdrawal =
    createAsyncThunk<GetExchangeWithdrawalResponse>(
        OPERATOR_REQUEST.GET_EXCHANGE_WITHDRAWAL,
        async (params, { rejectWithValue }) => {
            try {
                const { data } = await axiosInstance.get(
                    ALL_URL.OPERATOR_EXCHANGE_WITHDRAWAL,
                );
                return data;
            } catch (error: any) {
                const status: number = error.response.status;
                const message: string = error.message;
                return rejectWithValue({ status, message });
            }
        },
    );

interface SuspendUserExchangeLimitParams {
    id: string;
}
type SuspendUserExchangeLimitResponse = Record<string, never>;

export const suspendUserExchangeLimit = createAsyncThunk<
    SuspendUserExchangeLimitResponse,
    SuspendUserExchangeLimitParams
>(
    OPERATOR_REQUEST.SUSPEND_USER_EXCHANGE_LIMIT,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.post(
                ALL_URL.OPERATOR_EXCHANGE_LIMIT_SUSPEND + params.id + '/',
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

interface ReactivateUserExchangeLimitParams {
    id: string;
}
type ReactivateUserExchangeLimitResponse = Record<string, never>;

export const reactivateUserExchangeLimit = createAsyncThunk<
    ReactivateUserExchangeLimitResponse,
    ReactivateUserExchangeLimitParams
>(
    OPERATOR_REQUEST.REACTIVATE_USER_EXCHANGE_LIMIT,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.post(
                ALL_URL.OPERATOR_EXCHANGE_LIMIT_REACTIVATE + params.id + '/',
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

interface ExchangeWithdrawalDoneParams {
    id: string;
}
type ExchangeWithdrawalDoneResponse = Record<string, never>;

export const doneExchangeWithdrawal = createAsyncThunk<
    ExchangeWithdrawalDoneResponse,
    ExchangeWithdrawalDoneParams
>(
    OPERATOR_REQUEST.EXCHANGE_WITHDRAWAL_DONE,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.post(
                ALL_URL.OPERATOR_EXCHANGE_WITHDRAWAL_DONE + params.id + '/',
                params,
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

interface ExchangeWithdrawalCancelParams {
    id: string;
}
type ExchangeWithdrawalCancelResponse = Record<string, never>;

export const cancelExchangeWithdrawal = createAsyncThunk<
    ExchangeWithdrawalCancelResponse,
    ExchangeWithdrawalCancelParams
>(
    OPERATOR_REQUEST.EXCHANGE_WITHDRAWAL_CANCEL,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.post(
                ALL_URL.OPERATOR_EXCHANGE_WITHDRAWAL_CANCEL + params.id + '/',
                params,
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

export interface OperatorExchangeCryptoWithdrawalItem {
    id: string;
    address: string;
    user_id: number;
    name: string;
    lastname: string;
    email: string;
    balance_ARS: string;
    balance_USDT: string;
    balance_BTC: string;
    balance_ETH: string;
    currency_id: string;
    amount: string;
    created_at: string;
    updated_at: string;
    change_state_at: string;
    change_state_by: number;
    cryptonetwork_id: string;
    active: boolean;
    executed: boolean;
}

type GetExchangeWithdrawalCryptoResponse =
    OperatorExchangeCryptoWithdrawalItem[];

export const getOperatorExchangeCryptoWithdrawal =
    createAsyncThunk<GetExchangeWithdrawalCryptoResponse>(
        OPERATOR_REQUEST.GET_EXCHANGE_WITHDRAWAL_CRYPTO,
        async (params, { rejectWithValue }) => {
            try {
                const { data } = await axiosInstance.get(
                    ALL_URL.OPERATOR_EXCHANGE_WITHDRAWAL_CRYPTO,
                );
                return data;
            } catch (error: any) {
                const status: number = error.response.status;
                const message: string = error.message;
                return rejectWithValue({ status, message });
            }
        },
    );

interface ExchangeWithdrawalCryptoDoneParams {
    id: string;
}
type ExchangeWithdrawalCryptoDoneResponse = Record<string, never>;

export const doneExchangeCryptoWithdrawal = createAsyncThunk<
    ExchangeWithdrawalCryptoDoneResponse,
    ExchangeWithdrawalCryptoDoneParams
>(
    OPERATOR_REQUEST.EXCHANGE_WITHDRAWAL_CRYPTO_DONE,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.post(
                ALL_URL.OPERATOR_EXCHANGE_WITHDRAWAL_CRYPTO_DONE +
                    params.id +
                    '/',
                params,
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

interface ExchangeWithdrawalCryptoCancelParams {
    id: string;
}
type ExchangeWithdrawalCryptoCancelResponse = Record<string, never>;

export const cancelExchangeCryptoWithdrawal = createAsyncThunk<
    ExchangeWithdrawalCryptoCancelResponse,
    ExchangeWithdrawalCryptoCancelParams
>(
    OPERATOR_REQUEST.EXCHANGE_WITHDRAWAL_CRYPTO_CANCEL,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.post(
                ALL_URL.OPERATOR_EXCHANGE_WITHDRAWAL_CRYPTO_CANCEL +
                    params.id +
                    '/',
                params,
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

export interface OperatorExchangeDepositItem {
    id: string;
    currency_id: number;
    amount: string;
    reference_id: string;
    created_at: string;
    updated_at: string;
    change_state_by: string;
    change_state_at: string;
    active: boolean;
    executed: boolean;
    address: string;
    balance_ARS: string;
    balance_USDT: string;
    balance_BTC: string;
    balance_ETH: string;
    cryptonetwork_id: string;
    cuit: string;
    email: string;
    name: string;
    lastname: string;
    user_id: number;
}
type GetExchangeDepositResponse = OperatorExchangeDepositItem[];

export const getOperatorExchangeDeposit =
    createAsyncThunk<GetExchangeDepositResponse>(
        OPERATOR_REQUEST.GET_EXCHANGE_DEPOSIT,
        async (params, { rejectWithValue }) => {
            try {
                const { data } = await axiosInstance.get(
                    ALL_URL.OPERATOR_EXCHANGE_DEPOSIT,
                );
                return data;
            } catch (error: any) {
                const status: number = error.response.status;
                const message: string = error.message;
                return rejectWithValue({ status, message });
            }
        },
    );
