import { COLOR } from 'common/types';

export const cardColors = {
    [COLOR.primary]: 'bg-primary',
    [COLOR.secondary]: 'bg-secondary',
    [COLOR.success]: 'bg-success',
    [COLOR.danger]: 'bg-danger',
    [COLOR.warning]: 'bg-info',
    [COLOR.info]: 'bg-warning',
    [COLOR.light]: 'bg-light',
    [COLOR.dark]: '',
    [COLOR.transparent]: 'bg-transparent',
};
