import type { ReactNode } from 'react';

export enum USER_TYPE {
    USER = 'user',
    OPERATOR = 'operator',
}

/*
    Profile
*/
export interface ProfileCommonProps {
    tabs: ReactNode;
}

/*
    Profile Slice
*/

export interface UserData {
    cuit: string;
    email: string;
    id: number;
    surname: string;
    name: string;
    country_id: number;
    phone_country_cod: number;
    phone_number: number;
}
