import type {
    ExchangeOrderResponseItem,
    ExchangeQuoteResponseItem,
} from './quotationService';
import type { ExchangeOrder, ExchangeQuote, ORDER_SIDE } from './types';
import type { ExchangeWithdrawalResponseItem } from '../wallet/walletService';
import type { ExchangeWithdrawal } from '../wallet/types';

export const mapExchangeQuote = (
    exchangeQuote: ExchangeQuoteResponseItem,
): ExchangeQuote => ({
    id: exchangeQuote.id,
    tokenpairId: exchangeQuote.tokenpair_id,
    orderSide: exchangeQuote.order_side as ORDER_SIDE,
    symbol: exchangeQuote.symbol,
    amount: exchangeQuote.amount,
    amount_in: exchangeQuote.amount_in,
    priceQuote: exchangeQuote.price_quote,
    fee_perc: exchangeQuote.fee_perc,
    fee: exchangeQuote.fee,
    fiat_spread_perc: exchangeQuote.fiat_spread_perc,
    fiat_spread: exchangeQuote.fiat_spread,
    createdAt: exchangeQuote.created_at,
    expirationAt: exchangeQuote.expiration_at,
    confirmed: exchangeQuote.confirmed,
});

export const mapExchangeOrder = (
    exchangeOrder: ExchangeOrderResponseItem,
): ExchangeOrder => ({
    id: exchangeOrder.id,
    tokenpairId: exchangeOrder.tokenpair_id,
    orderType: exchangeOrder.order_type,
    orderSide: exchangeOrder.order_side,
    symbol: exchangeOrder.symbol,
    amount: exchangeOrder.amount,
    amountIn: exchangeOrder.amount_in,
    netPrice: exchangeOrder.net_price,
    priceTarget: exchangeOrder.price_target,
    feePerc: exchangeOrder.fee_perc,
    fee: exchangeOrder.fee,
    fiatSpreadPerc: exchangeOrder.fiat_spread_perc,
    fiatSpread: exchangeOrder.fiat_spread,
    exchange: exchangeOrder.exchange,
    createdAt: exchangeOrder.created_at,
    updatedAt: exchangeOrder.updated_at,
    active: exchangeOrder.active,
    executed: exchangeOrder.executed,
    changeStateAt: exchangeOrder.change_state_at,
    changeStateBy: exchangeOrder.change_state_by,
    convertedToQuote: exchangeOrder.converted_to_quote,
});

export const mapExchangeWithDrawals = (
    exchangeWithdrawals: ExchangeWithdrawalResponseItem[],
): ExchangeWithdrawal[] => {
    if (!exchangeWithdrawals) return [];
    return exchangeWithdrawals.map((ew) => ({
        id: ew.id,
        currencyId: ew.currency_id,
        amount: ew.amount,
        createdAt: ew.created_at,
        updatedAt: ew.updated_at,
        changeStateBy: ew.change_state_by,
        changeStateAt: ew.change_state_at,
        active: ew.active,
        executed: ew.executed,
    }));
};
