import { ListGroup } from 'reactstrap';
import { LI } from './ListItem';

interface ULProps extends React.HTMLAttributes<HTMLUListElement> {
    listItem?: string[];
    simple?: boolean;
    as?: string;
    className?: string;
}

export const UL = (props: ULProps) => {
    const getClassName = () => {
        if (props.simple) return 'simple-list';
        return '';
    };

    return (
        <ListGroup className={`${getClassName()} ${props.className} `}>
            {props.listItem
                ? props.listItem.map((item, i) => <LI val={item} key={i} />)
                : props.children}
        </ListGroup>
    );
};
