import { Outlet, useLocation } from 'react-router-dom';
import './layout.scss';
import { Header } from './Header/Header';
import { Sidebar } from './Sidebar';
import { ROUTE_PATH } from './routes/const';
import { ThemeContext } from '../providers/ThemeProvider';
import type { CSSProperties } from 'react';
import { useContext } from 'react';
import { HelpModal } from './HelpModal';
import { Receipt } from '../screens/user/quotation/buy-flow/Receipt';
import { Details } from '../screens/user/quotation/buy-flow/Details';
import { Confirm } from '../screens/user/quotation/buy-flow/Confirm';
import { useAppSelector } from 'redux/store';

interface AppLayoutProps {
    children?: JSX.Element;
    classNames?: string[];
    showHelpModal?: boolean;
}

export const AppLayout = (props: AppLayoutProps) => {
    const theme = useContext(ThemeContext);
    const location = useLocation();
    const sidebarOpen = useAppSelector((state) => state.layout.sidebarOpen);

    const getClassName = () => {
        let result = 'page-wrapper compact-wrapper';
        if (
            theme.mix_background_layout === 'dark-only' ||
            useLocation().pathname === ROUTE_PATH.MARKET
        )
            result += ' dark-scroll';
        return result;
    };

    const getStyles = (): CSSProperties => {
        if (location.pathname === ROUTE_PATH.MARKET) {
            return {
                overflowY: 'auto',
                maxHeight: 'calc(100vh - 130px)',
                marginLeft: sidebarOpen ? 130 : 0,
            };
        } else {
            return {
                overflowY: 'auto',
                maxHeight: 'calc(100vh - 220px)',
                marginLeft: sidebarOpen ? 290 : 0,
            };
        }
    };

    return (
        <>
            <div className={getClassName()} id='pageWrapper'>
                <Header />
                <div className='page-body-wrapper horizontal-menu'>
                    <Sidebar />
                    <div className='page-body' style={getStyles()}>
                        <Confirm />
                        <Receipt />
                        <Details />
                        <Outlet />
                        <HelpModal show={!!props.showHelpModal} />
                    </div>
                </div>
            </div>
        </>
    );
};
