import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { MODAL_OPEN } from './const';

interface ModalState {
    modalOpen: MODAL_OPEN;
}

const initialState: ModalState = {
    modalOpen: MODAL_OPEN.NONE,
};

export const modalSlice = createSlice({
    name: 'modalSlice',
    initialState,
    reducers: {
        setModalOpen: (
            state: ModalState,
            action: PayloadAction<MODAL_OPEN>,
        ) => {
            state.modalOpen = action.payload;
        },
    },
});

export const modalActions = modalSlice.actions;

export const modalReducer = modalSlice.reducer;
