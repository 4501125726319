import { Dashboard } from 'screens/user/dashboard';
import { History } from 'screens/user/history';
import { Profile } from 'screens/user/profile';
import { Quotation } from 'screens/user/quotation';
import { Wallet } from 'screens/user/wallet';
import { ROUTE_PATH } from './const';
import { Deposit } from '../../screens/user/dashboard/Deposit';
import { Help } from 'screens/user/help/Help';
import { Market } from 'screens/user/market';
import type { RouteItem } from './types';
import { Conversor } from 'screens/user/conversor';

export const userRoutes: RouteItem[] = [
    {
        title: 'Inicio',
        path: `${ROUTE_PATH.DASHBOARD}`,
        Component: <Dashboard />,
    },
    {
        title: 'Compra/Venta',
        path: `${ROUTE_PATH.QUOTATION}/*`,
        Component: <Quotation />,
    },
    {
        title: 'Trading',
        path: `${ROUTE_PATH.MARKET}/*`,
        Component: <Market />,
    },
    /*  {
        title: 'P2P',
        path: `${ROUTE_PATH.P2P}`,
        Component: <P2P />,
    }, */
    {
        title: 'Conversor',
        path: `${ROUTE_PATH.CONVERSOR}`,
        Component: <Conversor />,
    },
    {
        title: 'Billetera',
        path: `${ROUTE_PATH.WALLET}`,
        Component: <Wallet />,
    },
    {
        title: 'Perfil',
        path: `${ROUTE_PATH.PROFILE}/*`,
        Component: <Profile />,
    },
    {
        title: 'Historial',
        path: `${ROUTE_PATH.HISTORY}`,
        Component: <History />,
    },
    {
        title: 'Depositar',
        path: `${ROUTE_PATH.DEPOSIT}`,
        Component: <Deposit />,
    },
    {
        title: 'Help',
        path: `${ROUTE_PATH.HELP}/*`,
        Component: <Help />,
    },
];
