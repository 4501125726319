import { Button, H5, Card } from 'common';
import type { Coin, TokenPairPrice, TokenPairPrice24hr } from 'common/types';
import { COLOR } from 'common/types';
import { Row, Col } from 'reactstrap';
import { DashboardBanner } from 'common/DashboardBanner/DashboardBanner';
import { useNavigate } from 'react-router-dom';
import { CoinComponentObject } from 'common/helper';
import { ROUTE_PATH } from 'layout/routes/const';
import type { CardCoin } from 'screens/user/dashboard/CoinCarousel';
import { useAppDispatch, useAppSelector } from 'redux/store';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import Big from 'big.js';
import { compareAsc, parseISO, subMonths } from 'date-fns';
import { REQUEST_STATE } from 'redux/types';
import {
    getCoins,
    getTokenPairPrices,
    getTokenPairPrices24hr,
    getTokenPairs,
} from 'common/commonService';
import { getExchangeOrders } from '../quotation/quotationService';
import { SlickCarousel } from 'screens/user/dashboard/CoinCarousel';
import { dotAndComma } from 'utils/currency';
import { ExchangeTable } from 'common/Table/ExchangeTable';
import './Dashboard.scss';
import { COIN_TYPES } from 'utils/const';

const getCardsData = (
    coins: Coin[] | null,
    tokenPairPrices: TokenPairPrice[] | null,
    tokenPairPrices24hs: TokenPairPrice24hr[] | null,
): CardCoin[] => {
    if (!coins || !tokenPairPrices) return [];
    return _.compact(
        coins.map((coin2) => {
            const object = CoinComponentObject[coin2.tokenCode];
            const foundTPP = tokenPairPrices.find((tpp) => {
                return (
                    tpp.baseCode === coin2.tokenCode &&
                    tpp.quoteCode === COIN_TYPES.ARS
                );
            });
            const foundVariation = tokenPairPrices24hs?.find((tpp) => {
                return tpp.baseId === parseInt(coin2.id);
            });
            if (!object || !foundTPP) return null;

            return {
                component: object.component,
                title: object.text,
                value: dotAndComma(Big(foundTPP.netPrice).toFixed(2)),
                variation: foundVariation
                    ? foundVariation.priceChangePercent
                    : undefined,
            };
        }),
    );
};

export const Dashboard = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const tokenPairPrices = useAppSelector(
        (state) => state.common.tokenPairPrices,
    );
    const coins = useAppSelector((state) => state.common.coins);
    const [isLoading, setIsLoading] = useState(true);
    const tokenPairPricesState = useAppSelector(
        (state) => state.common.requestStates.tokenPairPrices,
    );
    const exchangeOrders = useAppSelector(
        (state) => state.quotation.exchangeOrders,
    );
    const tokenPairPrice24hs = useAppSelector(
        (state) => state.common.tokenPairPrices24hr,
    );

    useEffect(() => {
        dispatch(getCoins());
        dispatch(getTokenPairs());
        dispatch(getTokenPairPrices());
        dispatch(getExchangeOrders());
        dispatch(getTokenPairPrices24hr());
    }, []);

    useEffect(() => {
        if (tokenPairPricesState === REQUEST_STATE.LOADING) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [tokenPairPricesState]);

    const historyOrders = exchangeOrders
        ? _.take(
              exchangeOrders.filter((eo) => {
                  const oneMonthOldDate = subMonths(new Date(), 1);
                  const parsedDate = parseISO(eo.createdAt);
                  return compareAsc(parsedDate, oneMonthOldDate);
              }),
              5,
          )
        : [];

    return (
        <div className='bisbull-dashboard-container'>
            <Row className='pb-3 ml-3 mr-5 d-flex flex-row align-items-center justify-content-center'>
                <DashboardBanner />
                <Button
                    className='buy-btn'
                    size='lg'
                    color={COLOR.primary}
                    style={{
                        width: 180,
                        marginLeft: 30,
                    }}
                    onClick={() => navigate(ROUTE_PATH.QUOTATION)}
                >
                    Ir a comprar
                </Button>
            </Row>

            <Row className='mt-3'>
                <Col style={{ height: '255px' }}>
                    <SlickCarousel
                        loading={isLoading}
                        data={getCardsData(
                            coins,
                            tokenPairPrices,
                            tokenPairPrice24hs,
                        )}
                    />
                </Col>
            </Row>
            <Row style={{ marginRight: '0', marginLeft: '0' }}>
                <Col>
                    <Card>
                        <Row>
                            <Col>
                                <H5 style={{ fontWeight: 'bold' }}>
                                    Registro de Operaciones
                                </H5>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                width: '100%',
                                overflowX: 'auto',
                                marginTop: '30px',
                                maxHeight: 1000,
                                marginLeft: 0,
                            }}
                        >
                            <ExchangeTable options={{ limit: 5 }} />
                            <Button
                                size='lg'
                                color={COLOR.primary}
                                style={{
                                    width: 180,
                                    marginRight: 10,
                                    marginBottom: 10,
                                }}
                                onClick={() => navigate(ROUTE_PATH.HISTORY)}
                            >
                                Ver más
                            </Button>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};
