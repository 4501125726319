import { createSlice } from '@reduxjs/toolkit';
import dummyProfile from 'assets/images/avatar.png';
import type { UserData, USER_TYPE } from './types';
import type { PaymentMethod } from './profileService';
import {
    setPaymentMethod,
    getPaymentMethod,
    getAvatar,
    getUserType,
    personalData,
    setAvatar,
    setPassword,
    setPersonalData,
    deleteAvatar,
} from './profileService';
import { toast } from 'react-toastify';
import { REQUEST_STATE } from 'redux/types';

export interface ProfileState {
    requestStates: {
        personalData: REQUEST_STATE;
        setPassword: REQUEST_STATE;
        setPersonalData: REQUEST_STATE;
        getUserType: REQUEST_STATE;
        getPaymentMethod: REQUEST_STATE;
        setPaymentMethod: REQUEST_STATE;
        getAvatar: REQUEST_STATE;
        setAvatar: REQUEST_STATE;
        deleteAvatar: REQUEST_STATE;
    };
    userData: UserData | null;
    avatar: any;
    userType: USER_TYPE | null;
    paymentMethod: PaymentMethod | null;
}

const initialState: ProfileState = {
    requestStates: {
        personalData: REQUEST_STATE.NONE,
        setPassword: REQUEST_STATE.NONE,
        setPersonalData: REQUEST_STATE.NONE,
        getUserType: REQUEST_STATE.NONE,
        getPaymentMethod: REQUEST_STATE.NONE,
        setPaymentMethod: REQUEST_STATE.NONE,
        getAvatar: REQUEST_STATE.NONE,
        setAvatar: REQUEST_STATE.NONE,
        deleteAvatar: REQUEST_STATE.NONE,
    },
    userData: null,
    avatar: `${dummyProfile}`,
    userType: null,
    paymentMethod: null,
};

export const profileSlice = createSlice({
    name: 'profileSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(personalData.pending, (state) => {
            state.requestStates.personalData = REQUEST_STATE.LOADING;
        });
        builder.addCase(personalData.fulfilled, (state, { payload }) => {
            state.userData = {
                email: payload.email,
                id: payload.id,
                name: payload.name,
                surname: payload.lastname,
                cuit: payload.cuit,
                country_id: payload.country_id,
                phone_number: payload.phone_number,
                phone_country_cod: payload.phone_country_cod,
            };
            state.requestStates.personalData = REQUEST_STATE.OK;
        });
        builder.addCase(personalData.rejected, (state) => {
            toast.error(
                'No se pudieron obtener los datos del usuario, contacte el administrador.',
            );
            state.requestStates.personalData = REQUEST_STATE.ERROR;
        });

        /* Set password */
        builder.addCase(setPassword.pending, (state) => {
            state.requestStates.setPassword = REQUEST_STATE.LOADING;
        });
        builder.addCase(setPassword.fulfilled, (state) => {
            state.requestStates.setPassword = REQUEST_STATE.OK;
        });
        builder.addCase(setPassword.rejected, (state) => {
            state.requestStates.setPassword = REQUEST_STATE.ERROR;
        });

        /* Set personal data */
        builder.addCase(setPersonalData.pending, (state) => {
            state.requestStates.setPersonalData = REQUEST_STATE.LOADING;
        });
        builder.addCase(setPersonalData.fulfilled, (state, { payload }) => {
            state.userData = {
                email: payload.email,
                id: payload.id,
                name: payload.name,
                surname: payload.lastname,
                cuit: payload.cuit,
                country_id: payload.country_id,
                phone_number: payload.phone_number,
                phone_country_cod: payload.phone_country_cod,
            };
            state.requestStates.setPersonalData = REQUEST_STATE.OK;
        });
        builder.addCase(setPersonalData.rejected, (state) => {
            state.requestStates.setPersonalData = REQUEST_STATE.ERROR;
        });

        /* Get Avatar  */
        builder.addCase(getAvatar.pending, (state) => {
            state.requestStates.getAvatar = REQUEST_STATE.LOADING;
        });
        builder.addCase(getAvatar.fulfilled, (state, { payload }) => {
            state.avatar = payload.url;
            state.requestStates.getAvatar = REQUEST_STATE.OK;
        });
        builder.addCase(getAvatar.rejected, (state) => {
            state.requestStates.getAvatar = REQUEST_STATE.ERROR;
        });

        /* Set Avatar  */
        builder.addCase(setAvatar.pending, (state) => {
            state.requestStates.setAvatar = REQUEST_STATE.LOADING;
        });
        builder.addCase(setAvatar.fulfilled, (state, { payload }) => {
            state.requestStates.setAvatar = REQUEST_STATE.OK;
            state.avatar = payload;
        });
        builder.addCase(setAvatar.rejected, (state) => {
            state.requestStates.setAvatar = REQUEST_STATE.ERROR;
        });

        /* Delete Avatar  */
        builder.addCase(deleteAvatar.pending, (state) => {
            state.requestStates.deleteAvatar = REQUEST_STATE.LOADING;
        });
        builder.addCase(deleteAvatar.fulfilled, (state) => {
            state.requestStates.deleteAvatar = REQUEST_STATE.OK;
        });
        builder.addCase(deleteAvatar.rejected, (state) => {
            state.requestStates.deleteAvatar = REQUEST_STATE.ERROR;
        });

        /* userType */
        builder.addCase(getUserType.pending, (state) => {
            state.requestStates.getUserType = REQUEST_STATE.LOADING;
        });
        builder.addCase(getUserType.fulfilled, (state, { payload }) => {
            state.requestStates.getUserType = REQUEST_STATE.OK;
            state.userType = payload.usertype;
        });
        builder.addCase(getUserType.rejected, (state) => {
            state.requestStates.getUserType = REQUEST_STATE.ERROR;
        });

        /* get payment method */
        builder.addCase(getPaymentMethod.pending, (state) => {
            state.requestStates.getPaymentMethod = REQUEST_STATE.LOADING;
        });
        builder.addCase(getPaymentMethod.fulfilled, (state, { payload }) => {
            state.requestStates.getPaymentMethod = REQUEST_STATE.OK;
            state.paymentMethod = payload;
        });
        builder.addCase(getPaymentMethod.rejected, (state) => {
            state.requestStates.getPaymentMethod = REQUEST_STATE.ERROR;
        });

        /* set payment method */
        builder.addCase(setPaymentMethod.pending, (state) => {
            state.requestStates.setPaymentMethod = REQUEST_STATE.LOADING;
        });
        builder.addCase(setPaymentMethod.fulfilled, (state, { payload }) => {
            state.requestStates.setPaymentMethod = REQUEST_STATE.OK;
            state.paymentMethod = payload;
        });
        builder.addCase(setPaymentMethod.rejected, (state) => {
            state.requestStates.setPaymentMethod = REQUEST_STATE.ERROR;
        });
    },
});

export const profileActions = profileSlice.actions;

export const profileReducer = profileSlice.reducer;
