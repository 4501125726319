import type { Coin, Country, TokenPairPrice } from 'common/types';
import type { SelectOption, TextDropdownOption } from '@common';
import { CoinComponentObject } from '../helper';
import _ from 'lodash';

export const getSelectOptionFromCountries = (
    countries: Country[] | null,
): SelectOption[] => {
    if (!countries) return [];
    return countries
        .filter((c1) => c1.active)
        .map((c2) => ({
            id: c2.id.toString(),
            value: c2.name,
            flag: c2.code,
        }));
};

export const getSelectOptionFromCoins = (
    coins: Coin[] | null,
): TextDropdownOption[] => {
    if (!coins) return [];
    return coins
        .filter((c1) => c1.isActive && c1.isCrypto)
        .map((c2) => ({
            text: c2.name === 'PESOS ARS' ? 'ARS' : c2.name,
            id: c2.id.toString(),
            component: CoinComponentObject[c2.tokenCode].component,
        }));
};

export const getSelectFilteredOptionFromCoins = (
    coins: Coin[] | null,
    tokenPairPrice: TokenPairPrice[] | null,
): TextDropdownOption[] => {
    if (!coins) return [];
    return _.compact(
        coins
            .filter((c1) => c1.isActive && c1.isCrypto)
            .map((c2) => {
                const foundTPP = tokenPairPrice?.find(
                    (coin) => coin.quoteCode === c2.tokenCode,
                );
                if (foundTPP === undefined) return null;
                return {
                    text: c2.name,
                    id: c2.id.toString(),
                    component: CoinComponentObject[c2.tokenCode].component,
                };
            }),
    );
};
