import { Button, Modal, P } from '@common';
import { MODAL_OPEN } from '../../common/Modal/const';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '../../redux/store';
import { modalActions } from '../../common/Modal/modalSlice';
import './HelpModal.scss';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from 'layout/routes/const';
import {
    helpSubRouteTitles,
    HELP_SUB_ROUTES,
    HELP_SUB_ROUTES_KEY,
} from 'screens/user/help/const';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { useContext } from 'react';
import { ThemeContext } from '../../providers/ThemeProvider';

export const HelpModal = ({ show }: { show: boolean }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { modalOpen } = useSelector((state: RootState) => state.modal);
    const theme = useContext(ThemeContext);

    const closeModal = () => {
        dispatch(modalActions.setModalOpen(MODAL_OPEN.NONE));
    };

    const handleClick = (path: string) => {
        navigate(path);
        closeModal();
    };

    const getClassName = () => {
        let result = 'help-modal';
        if (theme.mix_background_layout === 'dark-only') result += ' dark';
        return result;
    };

    return (
        <div
            className='help-modal'
            style={{ display: show ? 'unset' : 'none' }}
        >
            <Button
                className={getClassName()}
                color='secondary'
                outline
                onClick={() =>
                    dispatch(modalActions.setModalOpen(MODAL_OPEN.HELP_MODAL))
                }
            >
                <FontAwesomeIcon
                    icon={faQuestionCircle}
                    size='2x'
                    style={{ margin: '0px 0px 0px -5px' }}
                />
            </Button>
            <Modal
                className='help-modal-open'
                title='Ayuda'
                open={modalOpen === MODAL_OPEN.HELP_MODAL}
                onClose={closeModal}
                noPadding
            >
                {Object.keys(HELP_SUB_ROUTES_KEY).map((key) => {
                    const helpKey = key as HELP_SUB_ROUTES_KEY;
                    return (
                        <Button
                            key={`help-button-item-${key}`}
                            color='transparent'
                            onClick={() =>
                                handleClick(
                                    `${ROUTE_PATH.HELP}/${HELP_SUB_ROUTES[helpKey]}`,
                                )
                            }
                        >
                            <P>{helpSubRouteTitles[helpKey]}</P>
                        </Button>
                    );
                })}
            </Modal>
        </div>
    );
};
