export enum MODAL_OPEN {
    NONE = 'NONE',
    DEMO = 'DEMO',
    PROFILE_PREFERENCES__SECOND_FACTOR_AUTHENTICATION = 'PROFILE_PREFERENCES__SECOND_FACTOR_AUTHENTICATION',
    PROFILE_PREFERENCES__SECOND_FACTOR_AUTHENTICATION_CONFIGURE = 'PROFILE_PREFERENCES__SECOND_FACTOR_AUTHENTICATION_CONFIGURE',
    PROFILE_PREFERENCES__SECURITY_MAILS = 'PROFILE_PREFERENCES__SECURITY_MAILS',
    PROFILE_PAYMENT_METHODS__CONFIRM_DELETE = 'PROFILE_PAYMENT_METHODS__CONFIRM_DELETE',
    HELP_MODAL = 'HELP_MODAL',
    DEPOSIT_MODAL = 'DEPOSIT_MODAL',
    WITHDRAWAL_MODAL = 'WITHDRAWAL_MODAL',
    // buy flow
    BUY_DETAIL = 'BUY_DETAIL',
    BUY_CONFIRM = 'BUY_CONFIRM',
    BUY_RECEIPT = 'BUY_RECEIPT',
    OPERATOR_UPDATE_BALANCE = 'OPERATOR_UPDATE_BALANCE',
}
