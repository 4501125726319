import { P } from '@common';
import './HelpCenter.scss';
import { useState } from 'react';
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Col,
    Row,
} from 'reactstrap';
import { HelpPage } from '../HelpPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

interface HelpCenterProps {
    questions?: {
        title: string;
        body: string;
    }[];
}

export const HelpCenter = (props: HelpCenterProps) => {
    const [open, setOpen] = useState('');
    const toggle = (id: string) => {
        if (open === id) {
            setOpen('');
        } else {
            setOpen(id);
        }
    };
    return (
        <div className='help-center'>
            <HelpPage title='Preguntas frecuentes'>
                {props.questions &&
                    props.questions.map((question, index) => (
                        <Accordion open={open}>
                            <AccordionItem
                                onClick={() => toggle(index.toString())}
                            >
                                <AccordionHeader targetId={index.toString()}>
                                    <Row>
                                        <Col className='col-10'>
                                            <P>{question.title}</P>
                                        </Col>
                                        <Col className='col icon'>
                                            {open === index.toString() ? (
                                                <FontAwesomeIcon
                                                    icon={faMinusCircle}
                                                    color='#3f6f64'
                                                    size='xs'
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={faPlusCircle}
                                                    color='#3f6f64'
                                                    size='xs'
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                </AccordionHeader>
                                <hr />
                                <AccordionBody accordionId={index.toString()}>
                                    <P>{question.body}</P>
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                    ))}
            </HelpPage>
        </div>
    );
};
