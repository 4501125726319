import { LI } from '@common';
import { ThemeContext } from 'providers/ThemeProvider';
import { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faLightbulb } from '@fortawesome/free-regular-svg-icons';

export const MoonLight = () => {
    const { addMixBackgroundLayout } = useContext(ThemeContext);
    const mix_background_layout = localStorage.getItem('mix_background_layout');
    const [dark, setDark] = useState(mix_background_layout === 'dark-only');

    useEffect(() => {
        if (dark) {
            document.body.className = 'dark-only';
            addMixBackgroundLayout('dark-only');
        } else {
            document.body.className = 'light-only';
            addMixBackgroundLayout('light-only');
        }
    }),
        [dark];

    const handleDarkModeChange = () => {
        const newValue = !dark;
        setDark(newValue);
        if (dark) {
            // dark
            localStorage.setItem('theme_preference', 'light-only');
            document.body.className = 'dark-only';
            addMixBackgroundLayout('dark-only');
        } else {
            // light
            localStorage.setItem('theme_preference', 'dark-only');
            document.body.className = 'light-only';
            addMixBackgroundLayout('light-only');
        }
    };

    return (
        <LI onClick={handleDarkModeChange} style={{ cursor: 'pointer' }}>
            <div className='mode'>
                {dark ? (
                    <FontAwesomeIcon icon={faLightbulb} color='white' />
                ) : (
                    <FontAwesomeIcon icon={faMoon} />
                )}
            </div>
        </LI>
    );
};
