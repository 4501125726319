import { H1 } from 'common';
import { Row } from 'reactstrap';
import { AdvancedRealTimeChart } from 'react-ts-tradingview-widgets';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { LoaderWheel } from 'common/Loader/LoaderWheel';
import Big from 'big.js';
import { useEffect, useMemo } from 'react';
import { getTokenPairPrice } from 'common/commonService';
import { dotAndComma } from 'utils/currency';

interface GraphicCardProps {
    currentCoinId: string | null;
}

export const GraphicCard = (props: GraphicCardProps) => {
    const dispatch = useAppDispatch();
    const tokenPairPrices = useAppSelector(
        (state) => state.common.tokenPairPrices,
    );

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!props.currentCoinId) return;
            dispatch(getTokenPairPrice({ id: props.currentCoinId }));
        }, 5000);
        return () => clearInterval(intervalId);
    }, []);

    const foundTPP = tokenPairPrices?.find(
        (tpp) => tpp.tokenpairId.toString() === props.currentCoinId,
    );

    const TradingViewComponent = useMemo(() => {
        return (
            <AdvancedRealTimeChart
                symbol={foundTPP ? foundTPP.symbol : undefined}
                theme='dark'
                autosize
            />
        );
    }, [props.currentCoinId]);

    return (
        <>
            <Row className='ml-1'>
                <H1 fontWeight='700' style={{ color: '#3f6f64', height: 50 }}>
                    {foundTPP
                        ? dotAndComma(Big(foundTPP.netPrice).toFixed(2))
                        : ''}
                </H1>
            </Row>
            <Row>
                {foundTPP ? (
                    <div
                        style={{
                            width: '100%',
                            height: '350px',
                            margin: '15px',
                            borderRadius: '10px',
                        }}
                    >
                        {TradingViewComponent}
                    </div>
                ) : (
                    <div
                        style={{
                            border: '1px solid #f2f2f2',
                            width: '100%',
                            height: 600,
                        }}
                    >
                        <LoaderWheel white />
                    </div>
                )}
            </Row>
        </>
    );
};
