import { Button, Card, H4, H6 } from '@common';
import { Col, Row } from 'reactstrap';
import type { ReactNode } from 'react';
import { ArrowLeft } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from 'layout/routes/const';
import './BuyCard.scss';

interface BuyCardProps {
    title?: string;
    subtitle?: string | ReactNode;
    children?: ReactNode;
    onClose?: boolean;
    noBack?: boolean;
    className?: string;
    noPaddingTop?: boolean;
}

export const BuyCard = (props: BuyCardProps) => {
    const navigate = useNavigate();

    return (
        <div
            className='buy-card'
            style={{
                display: 'flex',
                flexFlow: 'column nowrap',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                paddingTop: props.noPaddingTop ? 0 : 100,
                paddingBottom: 100,
            }}
        >
            {!props.noBack && (
                <Button
                    className='d-flex align-items-center'
                    color='secondary'
                    outline
                    onClick={() => navigate(-1)}
                    style={{
                        position: 'absolute',
                        top: 20,
                        right: 20,
                        backgroundColor: '#ebe7e4',
                        width: 135,
                        margin: '10px 30px 0 0',
                        height: 35,
                    }}
                >
                    <ArrowLeft size={18} className='mr-1' />
                    Volver
                </Button>
            )}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Card className='card-buy'>
                    <Row className='m-3'>
                        <Col>
                            {props.onClose && (
                                <div
                                    className='d-flex'
                                    style={{
                                        justifyContent: 'right',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                        navigate(ROUTE_PATH.QUOTATION)
                                    }
                                >
                                    <i
                                        className='icofont icofont-close-circled'
                                        style={{
                                            fontWeight: '500',
                                            fontSize: '20px',
                                        }}
                                    />
                                </div>
                            )}
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    height: '100%',
                                }}
                            >
                                <H4
                                    style={{
                                        marginBottom: 5,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {props.title}
                                </H4>
                                <H6 fontWeight='400'>{props.subtitle}</H6>
                                {props.children}
                            </div>
                        </Col>
                    </Row>
                </Card>
            </div>
        </div>
    );
};
