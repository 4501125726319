import { Card, Tabs } from 'common';
import { useState } from 'react';
import { LimitTab } from './LimitTab/LimitTab';
import { MarketTab } from './MarketTab/MarketTab';
import './InputCard.scss';
import { LoaderWheel } from 'common/Loader/LoaderWheel';

interface InputCardProps {
    currentCoinId: string | null;
}

export const InputCard = (props: InputCardProps) => {
    const [renderMarket, setRenderMarket] = useState(false);

    const tabs = [
        {
            title: 'Límite',
            active: !renderMarket,
            id: 'limit',
        },
        {
            title: 'Mercado',
            active: renderMarket,
            id: 'market',
        },
    ];

    return (
        <Card className='market-input-card'>
            <Tabs
                style={{ marginBottom: '0px' }}
                items={tabs}
                onClick={(id) => {
                    setRenderMarket(id === 'market');
                }}
            />
            {!props.currentCoinId ? (
                <div className='load-wheel-box'>
                    <LoaderWheel />
                </div>
            ) : renderMarket ? (
                <MarketTab currentCoinId={props.currentCoinId} />
            ) : (
                <LimitTab currentCoinId={props.currentCoinId} />
            )}
        </Card>
    );
};
