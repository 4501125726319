import type { Color } from 'common/types';
import { paragraphColorClassName } from './const';
import './Paragraph.scss';

interface ParagraphProps extends React.HTMLAttributes<HTMLParagraphElement> {
    size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    color?: Color;
    lead?: boolean;
    mb0?: boolean;
    style?: React.CSSProperties;
}

export const P = (props: ParagraphProps) => {
    const { children, color, size, ...rest } = props;

    const getClassName = () => {
        let result = '';
        result += color ? paragraphColorClassName[color] : '';
        if (props.lead) result += ' lead';
        if (props.mb0) result += ' mb-0';
        if (size) result += size;
        if (props.onClick) result += ' clickable';
        return result;
    };

    return (
        <p className={getClassName()} style={props.style} {...rest}>
            {children}
        </p>
    );
};
