import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ALL_URL } from 'redux/url';
import { ACCESS_TOKEN, axiosInstance } from 'utils/service';
import type { USER_TYPE } from './types';

enum USER_PROFILE_REQUEST {
    PERSONAL_DATA = 'PERSONAL_DATA',
    SET_PASSWORD = 'SET_PASSWORD',
    SET_PERSONAL_DATA = 'SET_PERSONAL_DATA',
    GET_AVATAR = 'GET_AVATAR',
    SET_AVATAR = 'SET_AVATAR',
    DELETE_AVATAR = 'DELETE_AVATAR',
    USER_TYPE = 'USER_TYPE',
    GET_PAYMENT_METHOD = 'GET_PAYMENT_METHOD',
    SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD',
}

export interface PersonalDataResponse {
    email: string;
    id: number;
    name: string;
    lastname: string;
    cuit: string;
    country_id: number;
    phone_country_cod: number;
    phone_number: number;
}

export const personalData = createAsyncThunk<PersonalDataResponse>(
    USER_PROFILE_REQUEST.PERSONAL_DATA,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(ALL_URL.PERSONAL_DATA);
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

interface SetPasswordParams {
    new_password: string;
    re_new_password: string;
    current_password: string;
}

export const setPassword = createAsyncThunk<
    Record<string, never>,
    SetPasswordParams
>(USER_PROFILE_REQUEST.SET_PASSWORD, async (params, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.post(ALL_URL.SET_PASSWORD, params);
        return data;
    } catch (error: any) {
        const status: number = error.response.status;
        const message: string = error.message;
        return rejectWithValue({ status, message });
    }
});

interface SetPersonalDataResponse {
    email: string;
    id: number;
    name: string;
    lastname: string;
    cuit: string;
    country_id: number;
    phone_country_cod: number;
    phone_number: number;
}

interface SetPersonalDataParams {
    /*  email: string;
    id: number;
    name: string;
    lastname: string;
    cuit: string;
    country_id: number; */
    phone_country_cod: number;
    phone_number: number;
    password: string;
}

export const setPersonalData = createAsyncThunk<
    SetPersonalDataResponse,
    SetPersonalDataParams
>(
    USER_PROFILE_REQUEST.SET_PERSONAL_DATA,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.patch(
                ALL_URL.SET_PERSONAL_DATA,
                params,
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

interface AvatarResponseItem {
    url: string;
}

export const getAvatar = createAsyncThunk<AvatarResponseItem>(
    USER_PROFILE_REQUEST.GET_AVATAR,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(ALL_URL.GET_AVATAR);
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

interface SetAvatarParams {
    image: any;
}

export const setAvatar = createAsyncThunk<
    Record<string, never>,
    SetAvatarParams
>(USER_PROFILE_REQUEST.SET_AVATAR, async (params, { rejectWithValue }) => {
    try {
        const formdata = new FormData();
        formdata.append('image', params.image);
        const { data } = await axios.post(
            process.env.REACT_APP_PUBLIC_URL + ALL_URL.SET_AVATAR,
            // ALL_URL.SET_AVATAR,
            formdata,
            {
                headers: {
                    Authorization: `JWT ${localStorage.getItem(ACCESS_TOKEN)}`,
                    'Content-Type': 'multipart/form-data;',
                    // Accept: 'application/json',
                },
            },
        );
        return data;
    } catch (error: any) {
        const status: number = error.response.status;
        const message: string = error.message;
        return rejectWithValue({ status, message });
    }
});

export const deleteAvatar = createAsyncThunk(
    USER_PROFILE_REQUEST.DELETE_AVATAR,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axios.delete(
                process.env.REACT_APP_PUBLIC_URL + ALL_URL.SET_AVATAR,
                {
                    headers: {
                        Authorization: `JWT ${localStorage.getItem(
                            ACCESS_TOKEN,
                        )}`,
                    },
                },
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

interface GetUserTypeResponse {
    usertype: USER_TYPE;
}

export const getUserType = createAsyncThunk<GetUserTypeResponse>(
    USER_PROFILE_REQUEST.USER_TYPE,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(ALL_URL.USER_TYPE);
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

export interface PaymentMethod {
    cbu: string;
    alias: string;
}
type GetPaymentMethodResponse = PaymentMethod;

export const getPaymentMethod = createAsyncThunk<GetPaymentMethodResponse>(
    USER_PROFILE_REQUEST.GET_PAYMENT_METHOD,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(ALL_URL.PAYMENT_METHOD);
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

type SetPaymentMethodParams = PaymentMethod;
type SetPaymentMethodResponse = PaymentMethod;

export const setPaymentMethod = createAsyncThunk<
    SetPaymentMethodResponse,
    SetPaymentMethodParams
>(
    USER_PROFILE_REQUEST.SET_PAYMENT_METHOD,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.post(
                ALL_URL.PAYMENT_METHOD,
                params,
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);
