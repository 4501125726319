import { LeftBar } from './LeftBar';
import { RightBar } from './RightBar/RightBar';
import { Row } from 'reactstrap';
import { MoreHorizontal } from 'react-feather';
import { useEffect, useState } from 'react';
import { ROUTE_PATH } from 'layout/routes/const';
import { MarketHeader } from './MarketHeader';
import { useLocation } from 'react-router-dom';

export const Header = () => {
    const location = useLocation();
    const [toggle, setToggle] = useState(false);

    const toggleResp = (value: boolean) => {
        setToggle(value);
    };

    if (location.pathname === ROUTE_PATH.MARKET) {
        document.body.className = 'dark-only';
        localStorage.setItem('mix_background_layout', 'dark-only');
        return <MarketHeader />;
    } else {
        const pref = localStorage.getItem('theme_preference');
        if (pref === 'light-only') {
            document.body.className = 'light-only';
            localStorage.setItem('mix_background_layout', 'light-only');
        }
    }

    return (
        <div className='page-main-header'>
            <Row className='main-header-right m-0'>
                <LeftBar />
                <RightBar toggle={toggle} />
                <div
                    style={{ cursor: 'pointer' }}
                    className='d-lg-none mobile-toggle pull-right w-auto'
                    onClick={() => toggleResp(!toggle)}
                >
                    <MoreHorizontal />
                </div>
            </Row>
        </div>
    );
};
