import type { FormState } from 'common/FormInput/types';
import type { FormikErrors } from 'formik';
import type { AnySchema, ObjectSchema, ValidationError } from 'yup';

const VALIDATION_REGEX = {
    /* 
        - only letters or numbers
        - min length of 3
    */
    TEXT: /^[a-zA-Z1-9]{3,}\s*$/,
    /*
        - any + @ + any + . + any
    */
    EMAIL: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
    /*
        XXXX-XX-XX
    */
    DATE: /^\d{4}-\d{2}-\d{2}$/,
};

export const validateInput = (type: string, value: string) => {
    switch (type) {
        case 'text':
            return VALIDATION_REGEX.TEXT.test(value);
        case 'tel':
            return value.length > 8;
        case 'password':
            return value.length > 3;
        case 'email':
            return VALIDATION_REGEX.EMAIL.test(value);
        case 'select':
            return value !== '';
        case 'date':
            return VALIDATION_REGEX.DATE.test(value);
        case 'number':
            return value.length > 0;
        default:
            return true;
    }
};

// validate if confirmPassword match with password
export const validateConfirmPassword = (
    password: string,
    confirmPassword: string,
) => {
    if (password === confirmPassword) {
        return true;
    }
};

// export const validatePasswordInput return errors array
export const validatePasswordInput = (password: string): string | null => {
    const errors: string[] = [];
    const errorType = {
        valid: 'válida',
        length: '8 caracteres',
        uppercase: '1 mayúscula',
        number: '1 número',
    };
    if (password.length < 8) errors.push(errorType.length);
    if (!password.match(/[A-Z]/)) errors.push(errorType.uppercase);
    if (!password.match(/[0-9]/)) errors.push(errorType.number);
    if (errors.length === 0) return null;
    return errors.toString();
};

export const createInitialFormState = <T extends string>(
    fields: T[],
): FormState<T> => {
    const result: any = {
        values: {},
        errors: {},
        touched: {},
    };
    fields.forEach((field) => {
        result.values[field] = '';
        result.errors[field] = null;
        result.touched[field] = false;
    });
    return result;
};

// if any was not touched or has any error -> form is invalid
export const isFormValid = (form: FormState<string>): boolean => {
    // false (not valid) if any not touched
    if (Object.values(form.touched).some((touched) => !touched)) return false;
    // false (not valid) if any error
    if (Object.values(form.errors).some((error) => !!error)) return false;

    return true;
};

export const validateWithPasswordFields = <T extends string>(
    values: { [key in T]: string },
    validationSchema: ObjectSchema<{ [key in T]: AnySchema }>,
    fields: string[],
): Promise<FormikErrors<{ [key in T]: string }>> => {
    const result = validationSchema
        .validate(values, {
            abortEarly: false,
        })
        .then(() => null)
        .catch((validationError) => {
            const newErrors: any = {};
            Object.keys(values).forEach((key) => (newErrors[key] = ''));
            validationError.inner.forEach(
                (validationErrorItem: ValidationError) => {
                    const { path, message } = validationErrorItem;
                    if (!path || !message) return;
                    if (!fields.includes(path)) newErrors[path] = message;
                    else if (newErrors[path].length === 0)
                        newErrors[path] = message;
                    else newErrors[path] = newErrors[path] + ',' + message;
                },
            );
            return newErrors;
        });
    return result;
};
