import bisbull_text_logo_green from 'assets/images/bisbull_text_logo_green.png';
import bisbull_icon_logo_green from 'assets/images/logo.png';
import { Button, P } from 'common';
import { Col, Row } from 'reactstrap';
import image from 'assets/images/landing_main_side_image.png';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from 'layout/routes/const';
import './LandingTop.scss';
import { useLoadedImage } from 'utils';

export const LandingTop = () => {
    const navigate = useNavigate();
    const imageLoaded = useLoadedImage(image);
    const text_greenLoaded = useLoadedImage(bisbull_text_logo_green);
    const icon_greenLoaded = useLoadedImage(bisbull_icon_logo_green);

    return (
        <Row className='bisbull-landing-top'>
            <Col className='texts-container'>
                <div className='brand-container'>
                    <div className='icon-box'>
                        <img
                            className='icon'
                            src={bisbull_icon_logo_green}
                            alt='Icono del logo'
                            style={{
                                display: !!icon_greenLoaded ? 'block' : 'none',
                            }}
                        />
                    </div>
                    <div className='text-box'>
                        <img
                            className='text'
                            src={bisbull_text_logo_green}
                            alt='Texto del logo'
                            style={{
                                display: !!text_greenLoaded ? 'block' : 'none',
                            }}
                        />
                    </div>
                </div>
                <P className='title'>
                    <strong>Descubrí</strong> el futuro de la{' '}
                    <strong>economía</strong> <strong>digital</strong>
                </P>
                <Button onClick={() => navigate(ROUTE_PATH.REGISTER)}>
                    <P>Empezar</P>
                </Button>
            </Col>
            <Col className='image-container pt-5 pb-5'>
                <img
                    src={image}
                    alt=''
                    style={{
                        display: !!imageLoaded ? 'block' : 'none',
                    }}
                />
            </Col>
        </Row>
    );
};
