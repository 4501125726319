export enum PROFILE_SUB_ROUTES_KEY {
    SETTINGS = 'SETTINGS',
    PREFERENCES = 'PREFERENCES',
    PAYMENT_METHOD = 'PAYMENT_METHOD',
}

export const PROFILE_SUB_ROUTES = {
    [PROFILE_SUB_ROUTES_KEY.SETTINGS]: 'ajustes',
    [PROFILE_SUB_ROUTES_KEY.PREFERENCES]: 'preferencias',
    [PROFILE_SUB_ROUTES_KEY.PAYMENT_METHOD]: 'metodos-de-pago',
};

export const profileSubRouteTitles: {
    [key in PROFILE_SUB_ROUTES_KEY]: string;
} = {
    [PROFILE_SUB_ROUTES_KEY.SETTINGS]: 'Ajustes de Perfil',
    [PROFILE_SUB_ROUTES_KEY.PREFERENCES]: 'Preferencias',
    [PROFILE_SUB_ROUTES_KEY.PAYMENT_METHOD]: 'Métodos de Pago',
};
