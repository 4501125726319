import { Input } from 'common/Input';
import type { ChangeEvent } from 'react';
import { useContext, useEffect, useState } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import ReactCodeInput from 'react-code-input';
import { P } from '../Paragraph';
import { useDropzone } from 'react-dropzone';
import { ThemeContext } from '../../providers/ThemeProvider';
import { ErrorTag } from 'screens/session/components/ErrorTag';
import './FormInput.scss';
import { SelectInput } from './SelectInput/SelectInput';
import type { CountryData } from 'react-phone-input-2';
import PhoneInput from 'react-phone-input-2';
import startsWith from 'lodash.startswith';
import { ROUTE_PATH } from 'layout/routes/const';

type FormInputType =
    | 'text'
    | 'password'
    | 'email'
    | 'number'
    | 'select'
    | 'textarea'
    | 'tel'
    | 'pin-password'
    | 'date'
    | 'file'
    | 'cuit';

interface FormInputProps extends SelectInputProps {
    label?: string;
    name: string;
    value: string;
    onChange: (value: string) => void;
    onFormikChange?: (e: ChangeEvent) => void;
    onFormikPhoneChange?: (
        e: ChangeEvent,
        { code, phone }: { code: string; phone: string },
    ) => void;
    error?: string | null;
    passwordError?: string | null;
    type?: FormInputType;
    placeholder?: string;
    leftIcon?: JSX.Element | string;
    disabled?: boolean;
}

export type SelectOption = {
    id: string;
    value: string;
    flag?: string | undefined;
};

interface SelectInputProps {
    options?: SelectOption[];
}

const formatCuitChange = (value: string | undefined) => {
    if (!value) return undefined;
    if (value.length < 14) {
        const cleaned = ('' + value).replace(/\D/g, '');
        const normValue = `${cleaned.substring(0, 2)}${
            cleaned.length > 2 ? '-' : ''
        }${cleaned.substring(2, 10)}${
            cleaned.length > 10 ? '-' : ''
        }${cleaned.substring(10, 11)}`;
        return normValue;
    }
};

export const FormInput = (props: FormInputProps) => {
    const theme = useContext(ThemeContext);
    const background =
        theme.mix_background_layout === 'light-only' ? '#ebf0ee' : '#161f2c';

    const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
    });

    const files = acceptedFiles.map((file) => (
        <li key={file.name}>{file.name}</li>
    ));

    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => setPasswordShown(!passwordShown);

    const renderLeftIcon = () => {
        if (!props.leftIcon) return null;
        if (typeof props.leftIcon === 'string') {
            return (
                <span className='input-group-text'>
                    <i className={props.leftIcon}></i>
                </span>
            );
        } else return props.leftIcon;
    };

    const getClassNameRegister = () => {
        if (window.location.pathname === ROUTE_PATH.REGISTER) {
            return 'light';
        } else {
            background !== '#ebf0ee' ? 'dark' : 'light';
        }
    };

    const renderInput = () => {
        const inputProps = {
            name: props.name,
            value: props.value,
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
                props.onChange(e.target.value);
                props.onFormikChange && props.onFormikChange(e);
            },
            disabled: props.disabled,
        };
        switch (props.type) {
            case 'password':
                return (
                    <Input
                        type={passwordShown ? 'text' : 'password'}
                        placeholder={props.placeholder || '**********'}
                        {...inputProps}
                    />
                );
            case 'select':
                return (
                    <SelectInput
                        options={props.options || []}
                        value={props.value}
                        onChange={(option) => {
                            props.onChange(option.id);
                            props.onFormikChange &&
                                props.onFormikChange({
                                    target: {
                                        name: props.name,
                                        value: option.id,
                                    },
                                } as ChangeEvent<HTMLInputElement>);
                        }}
                        disabled={props.disabled}
                    />
                );
            case 'tel':
                return (
                    <div
                        className={`phone-input-${getClassNameRegister()} ${
                            props.disabled ? 'disabled' : ''
                        }`}
                    >
                        <PhoneInput
                            placeholder='+54 (11) 1234 5678'
                            value={props.value}
                            onChange={(phone, data) => {
                                const code = (data as CountryData).dialCode;
                                const phoneNoCode = phone.replace(code, '');
                                props.onFormikPhoneChange &&
                                    props.onFormikPhoneChange(
                                        {
                                            target: {
                                                name: 'phone',
                                                value: phone,
                                            },
                                        } as ChangeEvent<HTMLInputElement>,
                                        { code, phone: phoneNoCode },
                                    );
                            }}
                            isValid={(inputNumber, country, countries) => {
                                return countries.some((country: any) => {
                                    return (
                                        startsWith(
                                            inputNumber,
                                            country.dialCode,
                                        ) ||
                                        startsWith(
                                            country.dialCode,
                                            inputNumber,
                                        )
                                    );
                                });
                            }}
                            disabled={props.disabled}
                        />
                    </div>
                );
            case 'pin-password':
                return (
                    <ReactCodeInput
                        name={props.name}
                        inputMode='numeric'
                        fields={6}
                        type='password'
                        value={props.value}
                        onChange={(value) => {
                            props.onChange(value);
                            props.onFormikChange &&
                                props.onFormikChange({
                                    target: {
                                        name: props.name,
                                        value: value,
                                    },
                                } as ChangeEvent<HTMLInputElement>);
                        }}
                    />
                );
            case 'file':
                useEffect(() => {
                    props.onChange(
                        acceptedFiles?.map((file) => file.name).join(', '),
                    );
                }, [acceptedFiles]);

                return (
                    <>
                        <div className='file-input border'>
                            <div {...getRootProps({})}>
                                <input {...getInputProps()} />
                                <P
                                    style={{
                                        marginTop: '0.375rem',
                                        padding: '0 0.75rem 0 0.75rem',
                                        height: 'auto',
                                    }}
                                >
                                    {files.length > 0
                                        ? files
                                        : 'Agregue un archivo o suelte un archivo aqui'}
                                </P>
                            </div>
                        </div>
                        <div>
                            <label
                                htmlFor='file-upload'
                                className='file-label'
                                onClick={open}
                                style={{ backgroundColor: background }}
                            >
                                <i className='fa fa-paperclip fa-flip-horizontal'></i>
                                &nbsp;
                                <P>Archivo</P>
                            </label>
                            <input type='file' id='file-upload' multiple />
                        </div>
                    </>
                );
            case 'cuit':
                return (
                    <Input
                        name={props.name}
                        inputMode='number'
                        value={formatCuitChange(props.value) || ''}
                        onChange={(e) => {
                            const value = e.target.value;
                            props.onChange(value.replaceAll('-', '').trim());
                            props.onFormikChange &&
                                props.onFormikChange({
                                    target: {
                                        name: props.name,
                                        value: value.replaceAll('-', '').trim(),
                                    },
                                } as ChangeEvent<HTMLInputElement>);
                        }}
                        disabled={props.disabled}
                    />
                );

            default:
                return (
                    <Input
                        type={props.type || 'text'}
                        placeholder={props.placeholder}
                        {...inputProps}
                    />
                );
        }
    };

    return (
        <FormGroup className='form-input position-relative bisbull-form-input'>
            {props.label && <Label>{props.label}</Label>}
            {renderLeftIcon()}
            {renderInput()}
            {props.type === 'password' && props.value.length >= 1 && (
                <div className='show-hide'>
                    <span
                        className={!passwordShown ? 'show' : 'hide'}
                        onClick={togglePassword}
                    ></span>
                </div>
            )}
            {props.error && (
                <div className='mt-2 ml-1'>
                    <span style={{ color: '#d22d3d' }}>{props.error}</span>
                </div>
            )}
            {props.passwordError && (
                <div className='password-error-container mt-2'>
                    {props.passwordError.split(',').map((error, index) => (
                        <ErrorTag key={index} error={error} />
                    ))}
                </div>
            )}
        </FormGroup>
    );
};

interface PhoneFormInputProps {
    formik: any;
}

export const PhoneFormInput = (props: PhoneFormInputProps) => {
    return (
        <FormGroup className='form-input position-relative bisbull-form-input'>
            <Label>Teléfono</Label>
            <Row>
                <Col sm={2}>
                    <Input
                        type='number'
                        name='phoneCode'
                        value={props.formik.values.phoneCode}
                        onChange={props.formik.handleChange}
                    />
                </Col>
                <Col sm={6}>
                    <Input
                        type='number'
                        name='phoneNumber'
                        value={props.formik.values.phoneNumber}
                        onChange={props.formik.handleChange}
                    />
                </Col>
            </Row>
            {(props.formik.errors.phoneCode ||
                props.formik.errors.phoneNumber) && (
                <div className='mt-2 ml-1'>
                    <span style={{ color: '#d22d3d' }}>
                        El número es incorrecto
                    </span>
                </div>
            )}
        </FormGroup>
    );
};

FormInput.Phone = PhoneFormInput;
