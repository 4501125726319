interface LoaderProps {
    show?: boolean;
}

export const Loader = (props: LoaderProps) => {
    return (
        <>
            <div className={`loader-wrapper ${props.show ? '' : 'loderhide'}`}>
                <div className='theme-loader'>
                    <div className='loader-p'></div>
                </div>
            </div>
        </>
    );
};
