import { H2, P, H5, BuyModal } from 'common';
import { Row, Col } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getBuyAndSellPrices } from 'utils/currency';
import Big from 'big.js';
import { MODAL_OPEN } from 'common/Modal/const';
import { modalActions } from 'common/Modal/modalSlice';
import { useMemo } from 'react';
import { ORDER_SIDE } from '../types';
import { format, parseISO } from 'date-fns';
import { formatNumber, formatNumberWithCommas } from '../../../../utils/string';

const Separator = () => (
    <hr
        style={{
            width: '100%',
            padding: '0px',
            margin: '10px 0',
        }}
    />
);

export const Receipt = () => {
    const dispatch = useAppDispatch();
    const { confirmedExchangeQuote } = useAppSelector(
        (state) => state.quotation,
    );
    const { tokenPairPrices } = useAppSelector((state) => state.common);
    const { modalOpen } = useAppSelector((state) => state.modal);

    const {
        youBoughtSoldText,
        mainValue,
        eqValue,
        transactionIdText,
        createdAtDate,
        quotationText,
        amountText,
        comissionText,
        totalText,
    } = useMemo(() => {
        const result = {
            youBoughtSoldText: '',
            mainValue: '',
            eqValue: '',
            transactionIdText: '',
            createdAtDate: '',
            quotationText: '',
            amountText: '',
            comissionText: '',
            totalText: '',
        };
        const foundTPP = tokenPairPrices?.find(
            (tpp) => tpp.tokenpairId === confirmedExchangeQuote?.tokenpairId,
        );
        if (!confirmedExchangeQuote || !tokenPairPrices || !foundTPP)
            return result;
        const { orderSide, amount } = confirmedExchangeQuote;
        const prices = getBuyAndSellPrices(
            foundTPP,
            amount,
            confirmedExchangeQuote.priceQuote,
        );
        result.transactionIdText = `ID de la transacción: ${confirmedExchangeQuote.id.toString()}`;
        result.createdAtDate = format(
            parseISO(confirmedExchangeQuote.createdAt),
            'dd MMM yyyy - HH:mm:ss',
        );
        result.quotationText = `1 ${
            foundTPP.baseCode
        } = ${formatNumberWithCommas(
            confirmedExchangeQuote.priceQuote,
            foundTPP.quoteCode,
        )}`;
        if (orderSide === ORDER_SIDE.BUY) {
            result.youBoughtSoldText = `Compraste ${foundTPP.baseCode}`;
            result.mainValue = formatNumberWithCommas(
                prices.buy.toFixed(8),
                foundTPP.baseCode,
            );
            result.eqValue = result.totalText = formatNumberWithCommas(
                amount,
                foundTPP.quoteCode,
            );
            result.amountText = formatNumberWithCommas(
                Big(amount).minus(Big(confirmedExchangeQuote.fee)).toString(),
                foundTPP.quoteCode,
            );
            result.comissionText = formatNumberWithCommas(
                confirmedExchangeQuote.fee,
                foundTPP.quoteCode,
            );
        }
        if (orderSide === ORDER_SIDE.SELL) {
            result.youBoughtSoldText = `Vendiste ${foundTPP.baseCode}`;
            result.mainValue = formatNumberWithCommas(
                amount,
                foundTPP.baseCode,
            );
            result.eqValue = formatNumberWithCommas(
                prices.sell.toString(),
                foundTPP.quoteCode,
            );
            result.totalText = formatNumberWithCommas(
                amount,
                foundTPP.baseCode,
            );
            result.amountText = formatNumberWithCommas(
                Big(amount).minus(Big(confirmedExchangeQuote.fee)).toString(),
                foundTPP.baseCode,
            );
            result.comissionText = formatNumberWithCommas(
                confirmedExchangeQuote.fee,
                foundTPP.baseCode,
            );
        }
        return result;
    }, [confirmedExchangeQuote]);

    return (
        <>
            {modalOpen === MODAL_OPEN.BUY_RECEIPT && (
                <BuyModal
                    onClose={() =>
                        dispatch(modalActions.setModalOpen(MODAL_OPEN.NONE))
                    }
                    open={modalOpen === MODAL_OPEN.BUY_RECEIPT}
                    title={youBoughtSoldText}
                    colorTop
                >
                    <div
                        className='confirm-receipt-container receipt'
                        style={{
                            borderRadius: '13px',
                            height: 60,
                            border: 'solid 5px #d7e1de',
                            backgroundColor: 'rgba(215, 225, 222, 0.39)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <H2 style={{ margin: 0 }}>{mainValue}</H2>
                    </div>
                    <H5 className='m-3'>{eqValue}</H5>
                    <Separator />
                    <div style={{ margin: '20px' }}>
                        <P style={{ fontSize: '14px', fontWeight: '500' }}>
                            {transactionIdText}
                        </P>
                    </div>
                    <Row style={{ width: '100%' }}>
                        <Col
                            style={{
                                fontWeight: '500',
                            }}
                        >
                            <Row className='mt-2 mb-2'>
                                <P style={{ fontSize: '12px' }}>
                                    Estado de transacción
                                </P>
                            </Row>
                            <Row className='mt-2 mb-2'>
                                <P style={{ fontSize: '12px' }}>
                                    Fecha de creación
                                </P>
                            </Row>
                            <Row className='mt-2 mb-3'>
                                <P style={{ fontSize: '12px' }}>Cotización</P>
                            </Row>
                        </Col>
                        <Col
                            style={{
                                color: 'grey',
                                fontSize: '12px',
                                fontWeight: '500',
                            }}
                        >
                            <Row
                                className='mt-2 mb-2'
                                style={{ color: 'rgb(63, 111, 100)' }}
                            >
                                Completada
                            </Row>
                            <Row className='mt-2 mb-2'>{createdAtDate}</Row>
                            <Row className='mt-2 mb-3'>{quotationText}</Row>
                        </Col>
                    </Row>
                    <Separator />
                    <Row style={{ width: '100%' }}>
                        <Col
                            style={{
                                fontWeight: '500',
                            }}
                        >
                            <Row className='mt-4 mb-2'>
                                <P style={{ fontSize: '12px' }}>Monto</P>
                            </Row>
                            <Row className='mt-2 mb-0'>
                                <P style={{ fontSize: '12px' }}>Comisión</P>
                            </Row>
                        </Col>
                        <Col
                            style={{
                                color: 'grey',
                                fontSize: '12px',
                                fontWeight: '500',
                            }}
                        >
                            <Row className='mt-4 mb-2'>$ {amountText}</Row>
                            <Row className='mt-2 mb-0'> $ {comissionText}</Row>
                        </Col>
                    </Row>
                    <Separator />
                    <Row style={{ width: '100%' }}>
                        <Col
                            style={{
                                fontWeight: '600',
                            }}
                        >
                            <Row className='mt-0 mb-2'>
                                <P style={{ fontSize: '12px' }}>Total</P>
                            </Row>
                        </Col>
                        <Col
                            style={{
                                color: 'grey',
                                fontSize: '12px',
                                fontWeight: '600',
                            }}
                        >
                            <Row className='mt-0 mb-2'>$ {totalText}</Row>
                        </Col>
                    </Row>
                </BuyModal>
            )}
        </>
    );
};
