import { Card, Modal } from '@common';
import { MODAL_OPEN } from 'common/Modal/const';
import { modalActions } from 'common/Modal/modalSlice';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { PREFERENCE, preferencesTexts } from './const';
import { PreferenceItem } from './PreferenceItem';
import { useState } from 'react';
import { useAppSelector } from 'redux/store';
import { ProfileTabs } from '../ProfileTabs';
import { QR_STEPS } from './QR_steps/const';
import { QR_step_1, QR_step_2 } from './QR_steps';

export const Preferences = () => {
    const dispatch = useDispatch();
    const { modalOpen } = useAppSelector((state) => state.modal);
    const [step, setStep] = useState(QR_STEPS.QR_STEP_1);

    const goToStep = (step: QR_STEPS) => {
        setStep(step);
    };

    const resetSteps = () => {
        setStep(QR_STEPS.QR_STEP_1);
    };

    const renderSteps = (step: QR_STEPS) => {
        switch (step) {
            case 'QR_STEP_1':
                return <QR_step_1 goToStep={goToStep} />;
            case 'QR_STEP_2':
                return <QR_step_2 goToStep={goToStep} />;
            default:
                return null;
        }
    };

    const closeModal = () => {
        dispatch(modalActions.setModalOpen(MODAL_OPEN.NONE));
        resetSteps();
    };

    return (
        <Card>
            <ProfileTabs />
            <Col>
                <Modal
                    open={
                        modalOpen ===
                        MODAL_OPEN.PROFILE_PREFERENCES__SECOND_FACTOR_AUTHENTICATION
                    }
                    onClose={closeModal}
                >
                    <Row style={{ padding: '60px 0px 40px 0px ' }}>
                        {renderSteps(step)}
                    </Row>
                </Modal>
                {/*    <ConfirmModal
                    open={
                        modalOpen ===
                        MODAL_OPEN.PROFILE_PREFERENCES__SECURITY_MAILS
                    }
                    onCancel={closeModal}
                    onConfirm={() => {
                        console.log(
                            'profile preferences, second factor authentication on confirm',
                        );
                        closeModal();
                    }}
                    onClose={closeModal}
                    title='Habilitar'
                    description='Vas a habilitar las alertas de ingreso por mail'
                /> */}
                <PreferenceItem
                    {...preferencesTexts[
                        PREFERENCE.SECOND_FACTOR_AUTHTENTICATION
                    ]}
                    onClick={() =>
                        dispatch(
                            modalActions.setModalOpen(
                                MODAL_OPEN.PROFILE_PREFERENCES__SECOND_FACTOR_AUTHENTICATION,
                            ),
                        )
                    }
                    // style={{ borderBottom: '1px solid #ebf0ee' }}
                />
                {/*    <PreferenceItem
                    {...preferencesTexts[PREFERENCE.SECURITY_MAILS]}
                    onClick={() =>
                        dispatch(
                            modalActions.setModalOpen(
                                MODAL_OPEN.PROFILE_PREFERENCES__SECURITY_MAILS,
                            ),
                        )
                    }
                /> */}
            </Col>
        </Card>
    );
};
