import { Card, H5, Input, P, Table } from 'common';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { REQUEST_STATE } from 'redux/types';
import { useDelayedState } from 'utils/hooks';
import { formatNumber } from 'utils/string';
import { getOperatorUser } from '../operatorService';
import type { OperatorUser } from '../types';
import { formatCUIT } from './utils';
import './Users.scss';
import { LoaderWheel } from 'common/Loader/LoaderWheel';

const filterUsers = (
    users: OperatorUser[] | null,
    searchText: string,
): OperatorUser[] => {
    if (!users) return [];
    if (!searchText) return users;
    const text = searchText.trim().toLowerCase();
    return users.slice().filter((user) => {
        const includesName = user.name.toLowerCase().includes(text);
        const includesLastname = user.lastname.toLowerCase().includes(text);
        const includesEmail = user.email.toLowerCase().includes(text);
        const includesCUIT = user.cuit.toLowerCase().includes(text);
        const includesSpread = user.spread.toLowerCase().includes(text);
        const includesPhone =
            `${user.phoneCountryCode}-${user.phoneNumber}`.includes(text);
        return (
            includesName ||
            includesLastname ||
            includesEmail ||
            includesCUIT ||
            includesSpread ||
            includesPhone
        );
    });
};

export const Users = () => {
    const dispatch = useAppDispatch();
    const { requestStates, users } = useAppSelector((state) => state.operator);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState<OperatorUser[]>([]);
    const { state: search, setState: setSearch } = useDelayedState<string>(
        '',
        (newSearch) => {
            if (requestStates.getOperatorUser === REQUEST_STATE.OK)
                setFilteredUsers(filterUsers(users, newSearch));
            setLoadingSearch(false);
        },
    );

    useEffect(() => {
        setFilteredUsers(filterUsers(users, search));
    }, [users]);

    useEffect(() => {
        dispatch(getOperatorUser());
    }, []);

    return (
        <Card className='bisbull-operator-users'>
            <H5 style={{ fontWeight: 'bold', marginBottom: 20 }}>
                Perfiles de usuario
            </H5>
            <div className='search-container'>
                <P>Filtro: </P>
                <Input
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                        setLoadingSearch(true);
                    }}
                />
                <LoaderWheel className={loadingSearch ? 'show' : ''} />
            </div>
            <Table
                columns={[
                    {
                        id: 'id',
                        header: 'ID',
                    },
                    {
                        id: '__fullname',
                        header: 'Nombre completo',
                        cell: (value, row) => `${row.name} ${row.lastname}`,
                    },
                    {
                        id: 'email',
                        header: 'Email',
                    },
                    {
                        id: 'balanceARS',
                        header: 'Balance equivalente en ARS',
                        cell: (value, row) => `${formatNumber(value)} ARS`,
                    },
                    {
                        id: 'balanceUSDT',
                        header: 'Balance equivalente en USDT',
                        cell: (value, row) => `${formatNumber(value)} USDT`,
                    },
                    {
                        id: 'cuit',
                        header: 'CUIT',
                        cell: (value, row) => formatCUIT(value),
                    },
                    {
                        id: 'spread',
                        header: 'Spread',
                        cell: (value, row) => (
                            <P style={{ fontWeight: 'bold' }}>{value}</P>
                        ),
                    },
                    {
                        id: '__phone',
                        header: 'Teléfono',
                        cell: (value, row) =>
                            `${row.phoneCountryCode}-${row.phoneNumber}`,
                    },
                ]}
                data={filteredUsers}
                /*  loading={
                    requestStates.getOperatorUser === REQUEST_STATE.LOADING
                } */
                noDataText='No hay usuarios'
            />
        </Card>
    );
};
