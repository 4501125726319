import { Dashboard } from 'screens/operator/dashboard';
import { Users } from 'screens/operator/users';
import { ROUTE_PATH } from './const';
import type { RouteItem } from './types';

export const operatorRoutes: RouteItem[] = [
    {
        title: 'Inicio',
        path: `${ROUTE_PATH.DASHBOARD}`,
        Component: <Dashboard />,
    },
    {
        title: 'Billetera',
        path: `${ROUTE_PATH.USERS}`,
        Component: <Users />,
    },
];
