import { BackButton, Button, Card, FormInput, H5 } from 'common';
import { useFormik } from 'formik';
import { Col, Form, Row } from 'reactstrap';
import { validateWithPasswordFields } from 'utils';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import type { UserData } from '../../types';

YupPassword(Yup);

export interface ChangePasswordEditionValues {
    currentPassword: string;
    newPassword: string;
    reNewPassword: string;
}

type ChangePasswordEditionSchema = Yup.ObjectSchema<{
    currentPassword: Yup.BaseSchema;
    newPassword: Yup.BaseSchema;
    reNewPassword: Yup.BaseSchema;
}>;

const validationSchema: ChangePasswordEditionSchema = Yup.object({
    currentPassword: Yup.string().required('La contraseña actual es requerida'),
    newPassword: Yup.string()
        .password()
        .min(8, '8 caracteres')
        .minLowercase(0)
        .minUppercase(1, '1 mayúscula')
        .minNumbers(1, '1 número')
        .minSymbols(1, '1 símbolo'),
    reNewPassword: Yup.string()
        .required('Este campo es requerido')
        .oneOf([Yup.ref('newPassword'), null], 'La contraseña no coincide'),
});

interface ChangePasswordEditionProps {
    data: UserData | null;
    loading: boolean;
    onSubmit: (values: ChangePasswordEditionValues) => void;
    onBack: () => void;
}

export const ChangePasswordEdition = (props: ChangePasswordEditionProps) => {
    const formik = useFormik({
        initialValues: {
            currentPassword: '',
            newPassword: '',
            reNewPassword: '',
        },
        onSubmit: props.onSubmit,
        validationSchema: validationSchema,
        validate: (values) =>
            validateWithPasswordFields(values, validationSchema, [
                'currentPassword',
                'newPassword',
            ]),
    });

    return (
        <Card>
            <Row className='d-flex align-items-center justify-content-between m-2'>
                <H5>Cambiar la contraseña</H5>
                <BackButton onClick={props.onBack} />
            </Row>
            <Form onSubmit={formik.handleSubmit} className='mt-4'>
                <Row>
                    <Col>
                        <FormInput
                            label='Contraseña actual'
                            name='currentPassword'
                            type='password'
                            value={formik.values.currentPassword}
                            onChange={() => null}
                            onFormikChange={formik.handleChange}
                            error={formik.errors.currentPassword}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormInput
                            label='Nueva contraseña'
                            name='newPassword'
                            type='password'
                            value={formik.values.newPassword}
                            onChange={() => null}
                            onFormikChange={formik.handleChange}
                            passwordError={formik.errors.newPassword}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormInput
                            label='Repetir nueva contraseña'
                            name='reNewPassword'
                            type='password'
                            value={formik.values.reNewPassword}
                            onChange={() => null}
                            onFormikChange={formik.handleChange}
                            error={formik.errors.reNewPassword}
                        />
                    </Col>
                </Row>
                <Row className='mt-4 ml-1'>
                    <Button type='submit'>Confirmar</Button>
                </Row>
            </Form>
        </Card>
    );
};
