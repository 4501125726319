import { LandingHeader } from './LandingHeader';
import './Landing.scss';
import { LandingTop } from './LandingTop/LandingTop';
import { LandingMiddle } from './LandingMiddle';
import { LandingBottom } from './LandingBottom';

export const Landing = () => {
    return (
        <div className='bisbull-landing'>
            <LandingHeader />
            <div className='content'>
                <LandingTop />
                <LandingMiddle />
                <LandingBottom />
            </div>
        </div>
    );
};
