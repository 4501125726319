import { Card, H3, H6, P } from 'common';
import { Col, Row } from 'reactstrap';
import { ProfileTabs } from '../ProfileTabs';
import { EditableImage } from './EditableImage';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { REQUEST_STATE } from 'redux/types';
import type { SettingsPersonalDataValuesSubmit } from './UserSettingsPersonalData';
import { SettingsPersonalData } from './UserSettingsPersonalData';
import './Settings.scss';
import { useEffect, useState } from 'react';
import { ChangePasswordEdition } from './ChangePasswordEdition';
import type { ChangePasswordEditionValues } from './ChangePasswordEdition/ChangePasswordEdition';
import { getAvatar, setPassword, setPersonalData } from '../profileService';
import { toast } from 'react-toastify';

export const Settings = () => {
    const dispatch = useAppDispatch();
    // const { avatar } = useAppSelector((state) => state.profile);
    const [renderChangePasswordEdition, setRenderChangePasswordEdition] =
        useState(false);
    const { requestStates, userData } = useAppSelector(
        (state) => state.profile,
    );
    const loading =
        !userData || requestStates.personalData === REQUEST_STATE.LOADING;

    const handlePersonalDataSubmit = (
        values: SettingsPersonalDataValuesSubmit,
    ) => {
        if (!userData) toast.error('Hubo un error');
        else {
            dispatch(
                setPersonalData({
                    password: values.password,
                    phone_country_cod: parseInt(values.phoneCode),
                    phone_number: parseInt(values.phoneNumber),
                }),
            )
                .unwrap()
                .then(() =>
                    toast.success('Se ha cambiado tus datos correctamente'),
                )
                .catch(() => toast.error('Hubo un error'));
        }
    };

    const handleChangePasswordSubmit = (
        values: ChangePasswordEditionValues,
    ) => {
        dispatch(
            setPassword({
                current_password: values.currentPassword,
                new_password: values.newPassword,
                re_new_password: values.reNewPassword,
            }),
        )
            .unwrap()
            .then(() => {
                toast.success('Se ha cambiado la contraseña con éxito');
                setRenderChangePasswordEdition(false);
            })
            .catch(() => toast.error('Hubo un error'));
    };

    useEffect(() => {
        dispatch(getAvatar());
    }, []);

    if (renderChangePasswordEdition)
        return (
            <ChangePasswordEdition
                data={userData}
                loading={loading}
                onSubmit={handleChangePasswordSubmit}
                onBack={() => setRenderChangePasswordEdition(false)}
            />
        );
    return (
        <>
            <Card>
                <ProfileTabs />
                <Row className='mt-5 user-settings'>
                    <Col>
                        <Row className=''>
                            <Col className='d-flex flex-row-reverse'>
                                <EditableImage />
                            </Col>
                            <Col className='d-flex align-items-center'>
                                <div className='texts'>
                                    <H3>
                                        {loading
                                            ? ''
                                            : `${userData?.name} ${userData?.surname}`}
                                    </H3>
                                    {/* <P color='dark'>Puesto de trabajo</P> */}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col className='separator' md='0' />
                    <Col className='d-flex flex-column justify-content-center ml-4'>
                        <H6>Mail:</H6>
                        <P style={{ marginBottom: 10 }}>
                            {loading ? '' : userData?.email}
                        </P>
                        <H6 className='mt-4'>Contraseña:</H6>
                        <Row>
                            <Col className='d-flex'>
                                <P style={{ marginRight: 30 }}>**********</P>
                                <P
                                    color='primary'
                                    style={{
                                        marginBottom: 10,
                                        fontWeight: 'bold',
                                    }}
                                    onClick={() =>
                                        setRenderChangePasswordEdition(true)
                                    }
                                >
                                    Editar
                                </P>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
            <SettingsPersonalData
                data={userData}
                disabled={loading}
                onSubmit={handlePersonalDataSubmit}
                loading={
                    requestStates.setPersonalData === REQUEST_STATE.LOADING
                }
            />
        </>
    );
};
