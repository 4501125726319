import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface LayoutState {
    sidebarOpen: boolean;
}

const initialState: LayoutState = {
    sidebarOpen: true,
};

export const layoutSlice = createSlice({
    name: 'layoutSlice',
    initialState,
    reducers: {
        setSidebarOpen: (
            state: LayoutState,
            action: PayloadAction<boolean>,
        ) => {
            state.sidebarOpen = action.payload;
        },
    },
});

export const layoutActions = layoutSlice.actions;

export const layoutReducer = layoutSlice.reducer;
