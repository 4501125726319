export const getTokensFromUrl = (
    myRoute: string,
    pathname: string,
): { uid: string; token: string } => {
    const result = { uid: '', token: '' };
    const substractedPathname = pathname.replace(`${myRoute}/`, '');
    const splittedArray = substractedPathname.split('/');
    if (splittedArray[0].length > 0) result.uid = splittedArray[0];
    if (splittedArray[1].length > 0) result.token = splittedArray[1];
    return result;
};
