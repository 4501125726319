import React, { Children } from 'react';
import { Input as ReastrapInput } from 'reactstrap';
import type { InputProps as ReactstrapInputProps } from 'reactstrap';
import type { InputType } from './const';

interface InputProps extends Omit<ReactstrapInputProps, 'size'> {
    label?: string;
    name?: string;
    placeholder?: string;
    type?: InputType;
    rows?: number;
    cols?: number;
    value?: string;
    className?: string;
    shape?: 'pill' | 'square';
    shadow?: boolean;
    digits?: boolean;
    size?: 'lg' | 'sm';
    defaultValue?: string;
    maxLength?: number;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

export const Input = (props: InputProps) => {
    const getClassNames = () => {
        let result = '';

        if (props.shape === 'pill') result += ' btn-pill';
        if (props.shape === 'square') result += ' btn-square';
        if (props.type === 'color') result += ' form-control-color';

        if (props.type === 'select') {
            result += ' form-control digits';
            if (props.size === 'lg') result += ' form-control-lg';
            if (props.size === 'sm') result += ' form-control-sm';
        }

        if (props.shadow) result += ' input-air-primary';

        if (props.digits) result += ' digits';

        switch (props.size) {
            case 'lg':
                result += ' form-control-lg';
                break;
            case 'sm':
                result += ' form-control-sm';
                break;
            default:
                result += '';
        }

        return result;
    };

    return (
        <ReastrapInput
            className={getClassNames()}
            type={props.type || 'text'}
            placeholder={props.placeholder || ''}
            name={props.name || ''}
            value={props.value}
            defaultValue={props.defaultValue}
            rows={props.rows || 3}
            cols={props.cols || 30}
            maxLength={props.maxLength || 100}
            onChange={props.onChange}
            disabled={props.disabled}
        >
            {Children.map(props.children, (child) => {
                return child;
            })}
        </ReastrapInput>
    );
};
