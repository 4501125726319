import {
    faArrowDown,
    faArrowUp,
    faMinus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, H4, P } from 'common';
import { LoaderWheel } from 'common/Loader/LoaderWheel';
import { COLOR } from 'common/types';
import './CryptoDataCardItem.scss';
import Big from 'big.js';
import React from 'react';
import { ROUTE_PATH } from 'layout/routes/const';
import { useNavigate } from 'react-router-dom';
import { dotAndComma } from 'utils';
import { formatNumber } from 'utils/string';

interface CryptoDataCardItemProps {
    component?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
    title?: string;
    value?: string;
    variation?: string;
    loading: boolean;
}

export const CryptoDataCardItem = (props: CryptoDataCardItemProps) => {
    const navigate = useNavigate();

    const getVariationColorAndIcon = () => {
        if (props.variation === '0')
            return { icon: faMinus, color: COLOR.dark };
        if (props.variation === undefined)
            return { icon: faMinus, color: COLOR.dark };
        if (Big(props.variation).gt(0))
            return { icon: faArrowUp, color: COLOR.primary };
        else return { icon: faArrowDown, color: COLOR.danger };
    };
    return (
        <Card>
            <div className='crypto-card-container'>
                {props.loading ? (
                    <LoaderWheel />
                ) : (
                    <>
                        {/*<img src={props.src} width='50px' alt='' />*/}
                        {props.component && (
                            <props.component style={{ width: '40px' }} />
                        )}
                        <H4 fontWeight='600'>{props.value}</H4>
                        <P color='info'>{props.title}</P>
                        <div style={{ minHeight: '25px' }}>
                            {props.variation && (
                                <P color={getVariationColorAndIcon().color}>
                                    <strong>
                                        <FontAwesomeIcon
                                            icon={
                                                getVariationColorAndIcon().icon
                                            }
                                            className='mr-1'
                                        />
                                        {`${dotAndComma(
                                            formatNumber(Big(props.variation)),
                                        )}
                                    %`}
                                    </strong>
                                </P>
                            )}
                        </div>

                        <Button
                            onClick={() => navigate(ROUTE_PATH.QUOTATION)}
                            color='primary'
                        >
                            Comprar
                        </Button>
                    </>
                )}
            </div>
        </Card>
    );
};
