import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface MarketState {
    currentCoinId: string | null;
}

const initialState: MarketState = {
    currentCoinId: null,
};

export const marketSlice = createSlice({
    name: 'marketSlice',
    initialState,
    reducers: {
        setCurrentCoinId: (
            state: MarketState,
            actions: PayloadAction<string | null>,
        ) => {
            state.currentCoinId = actions.payload;
        },
    },
});

export const marketActions = marketSlice.actions;

export const marketReducer = marketSlice.reducer;
