import { layoutActions } from 'layout/layoutSlice';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'redux/store';

export const useMobile = () => {
    const dispatch = useAppDispatch();
    const [isMobile, setIsMobile] = useState(false);
    const [flag, setFlag] = useState(true);

    const handleResize = () => {
        if (window.innerWidth < 991) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile && flag) {
            dispatch(layoutActions.setSidebarOpen(false));
            setFlag(false);
        }
        if (!isMobile && !flag) setFlag(true);

        if (!isMobile) {
            dispatch(layoutActions.setSidebarOpen(true));
        }
    }, [isMobile, flag]);

    return { isMobile };
};
