import { Table } from '@common';
import { ExchangeOrderStatusText } from 'common/Table/common-cells';
import { formatISODate } from 'common/Table/utils';
import { dotAndComma } from 'utils';
import { formatNumber } from 'utils/string';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { useEffect } from 'react';
import { getCoins } from '../../../../../common/commonService';
import { getSingleCoinFromId } from '../../../../../utils/currency';
import type { OperatorExchangeDeposit } from 'screens/operator/types';

interface DashboardDepositProps {
    data: OperatorExchangeDeposit[];
    loading: boolean;
    noDataText: string;
}

export const DepositOrders = (props: DashboardDepositProps) => {
    const dispatch = useAppDispatch();
    const { coins } = useAppSelector((state) => state.common);

    const getCoin = (id: string): string => {
        return getSingleCoinFromId(coins, id);
    };

    useEffect(() => {
        dispatch(getCoins());
    }, []);

    return (
        <div style={{ overflowX: 'auto' }}>
            <Table
                loading={props.loading}
                noDataText={props.noDataText}
                data={props.data}
                columns={[
                    {
                        id: 'id',
                        header: 'Número de operación',
                    },
                    {
                        id: '__fullname',
                        header: 'Nombre completo',
                        cell: (value, row) => `${row.name} ${row.lastname}`,
                    },
                    {
                        id: 'email',
                        header: 'Email',
                    },
                    {
                        id: 'currencyId',
                        header: 'Moneda',
                        cell: (value, row) => getCoin(value),
                    },
                    {
                        id: 'amount',
                        header: 'Cantidad',
                        cell: (value, row) =>
                            `${dotAndComma(formatNumber(value))} ${getCoin(
                                row.currencyId,
                            )}`,
                    },
                    {
                        id: 'createdAt',
                        header: 'Fecha de creación',
                        cell: (cell, row) => formatISODate(row.createdAt),
                    },
                    {
                        id: 'updatedAt',
                        header: 'Fecha de actualizacion',
                        cell: (cell, row) => formatISODate(row.updatedAt),
                    },
                    {
                        id: 'changeStateAt',
                        header: 'Fecha de modificacion',
                        cell: (cell, row) => formatISODate(row.changeStateAt),
                    },
                    {
                        id: 'active-executed',
                        header: 'Estado',
                        cell: (cell, row) => {
                            const { active, executed } = row;
                            return (
                                <ExchangeOrderStatusText
                                    active={active}
                                    executed={executed}
                                />
                            );
                        },
                    },
                ]}
            />
        </div>
    );
};
