export enum COIN_TYPES {
    ARS = 'ARS',
    BTC = 'BTC',
    BAT = 'BAT',
    USDC = 'USDC',
    SLP = 'SLP',
    USD = 'USD',
    ETH = 'ETH',
    USDT = 'USDT',
    LTC = 'LTC',
}

export enum ORDER_TYPES {
    EXCHANGE_MARKET = 'EXCHANGE MARKET',
    EXCHANGE_LIMIT = 'EXCHANGE LIMIT',
}
