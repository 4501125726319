import { Card, Tabs } from 'common';
import { useEffect, useState } from 'react';
import { HistoryOrdersTableTab } from './HistoryOrdersTableTab';
import { OpenOrdersTableTab } from './OpenOrdersTableTab';
import { parseISO, subMonths, compareAsc } from 'date-fns';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { getExchangeOrders } from '../../quotation/quotationService';
import { ExchangeTable } from 'common/Table/ExchangeTable';

export const OrdersTablesCard = () => {
    const [renderHistory, setRenderHistory] = useState(false);
    const dispatch = useAppDispatch();
    const exchangeOrders = useAppSelector(
        (state) => state.quotation.exchangeOrders,
    );

    useEffect(() => {
        dispatch(getExchangeOrders());
    }, []);

    const tabs = [
        {
            title: 'Ordenes abiertas',
            active: !renderHistory,
            id: 'openOrders',
        },
        {
            title: 'Historial',
            active: renderHistory,
            id: 'history',
        },
    ];

    const exchangeTableOptions = {
        onlyOrders: true,
        showOnlyActive: !renderHistory,
        showOnlyNonActive: renderHistory,
    };

    return (
        <Card>
            <Tabs
                items={tabs.map((tab) => ({
                    title: tab.title,
                    active: tab.active,
                    id: tab.id,
                }))}
                onClick={(id) => {
                    setRenderHistory(id === 'history');
                }}
            />
            <ExchangeTable options={exchangeTableOptions} />
        </Card>
    );
};
