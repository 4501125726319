import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { REQUEST_STATE } from 'redux/types';
import { capitalize } from 'utils';
import type { GetTokenPairPricesResponse } from './commonService';
import {
    getTokenPairPrice,
    addBalance,
    getCoins,
    setBalance,
    getCountries,
    getTokenPairs,
    getTokenPairPrices,
    getTokenPairPrices24hr,
    getBalanceExtended,
} from './commonService';
import type {
    Coin,
    Country,
    TokenPair,
    TokenPairPrice,
    TokenPairPrice24hr,
    BalanceExtended,
} from './types';

export const mapTPPResponseToTPP = (
    tpp: GetTokenPairPricesResponse,
): TokenPairPrice[] => {
    return tpp.map((tpp) => ({
        baseBalance: tpp.base_balance,
        baseCode: tpp.base_cod,
        feeAmount: tpp.fee_amount,
        feePercentage: tpp.fee_perc,
        fiatSpreadAmount: tpp.fiat_spread_amount,
        fiatSpreadPercentage: tpp.fiat_spread_perc,
        isCrypto: tpp.is_crypto,
        netPrice: tpp.net_price,
        quoteBalance: tpp.quote_balance,
        quoteCode: tpp.quote_cod,
        symbol: tpp.symbol,
        symbolPretty: tpp.symbol_pretty,
        tokenpairId: tpp.tokenpair_id,
        totalPrice: tpp.total_price,
    }));
};
export interface CommonState {
    requestStates: {
        countries: REQUEST_STATE;
        coins: REQUEST_STATE;
        tokenPairs: REQUEST_STATE;
        tokenPairPrices: REQUEST_STATE;
        tokenPairPrice: REQUEST_STATE;
        getBalance: REQUEST_STATE;
        setBalance: REQUEST_STATE;
        addBalance: REQUEST_STATE;
        tokenPairPrices24hr: REQUEST_STATE;
    };
    countries: Country[] | null;
    coins: Coin[] | null;
    tokenPairs: TokenPair[] | null;
    tokenPairPrices: TokenPairPrice[] | null;
    balances: BalanceExtended[] | null;
    tokenPairPrices24hr: TokenPairPrice24hr[] | null;
}

const initialState: CommonState = {
    requestStates: {
        countries: REQUEST_STATE.NONE,
        coins: REQUEST_STATE.NONE,
        tokenPairs: REQUEST_STATE.NONE,
        tokenPairPrices: REQUEST_STATE.NONE,
        tokenPairPrice: REQUEST_STATE.NONE,
        getBalance: REQUEST_STATE.NONE,
        setBalance: REQUEST_STATE.NONE,
        addBalance: REQUEST_STATE.NONE,
        tokenPairPrices24hr: REQUEST_STATE.NONE,
    },
    countries: null,
    coins: null,
    tokenPairs: null,
    tokenPairPrices: null,
    balances: null,
    tokenPairPrices24hr: null,
};

export const commonSlice = createSlice({
    name: 'commonSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        /* Get countries */
        builder.addCase(getCountries.pending, (state) => {
            state.requestStates.countries = REQUEST_STATE.LOADING;
        });
        builder.addCase(getCountries.fulfilled, (state, { payload }) => {
            state.requestStates.countries = REQUEST_STATE.OK;
            state.countries = payload.map((c) => ({
                id: c.id,
                name: capitalize(c.name),
                code: c.code,
                active: c.active,
            }));
        });
        builder.addCase(getCountries.rejected, (state) => {
            toast.error(
                'Hubo un error al obtener los paises, contacte el administrador',
            );
            state.requestStates.countries = REQUEST_STATE.ERROR;
        });

        /* Get coins */
        builder.addCase(getCoins.pending, (state) => {
            state.requestStates.coins = REQUEST_STATE.LOADING;
        });
        builder.addCase(getCoins.fulfilled, (state, { payload }) => {
            state.requestStates.coins = REQUEST_STATE.OK;
            state.coins = payload.map((c) => ({
                id: c.id,
                name: c.name,
                tokenCode: c.token_cod,
                isCrypto: c.is_crypto,
                isActive: c.active,
            }));
        });

        builder.addCase(getCoins.rejected, (state) => {
            state.requestStates.coins = REQUEST_STATE.ERROR;
        });

        /* Get token pairs */
        builder.addCase(getTokenPairs.pending, (state) => {
            state.requestStates.tokenPairs = REQUEST_STATE.LOADING;
        });
        builder.addCase(getTokenPairs.fulfilled, (state, { payload }) => {
            state.requestStates.tokenPairs = REQUEST_STATE.OK;
            state.tokenPairs = payload.map((t) => ({
                id: t.id,
                baseCode: t.base_cod,
                quoteCode: t.quote_cod,
                isCrypto: t.is_crypto,
                symbol: t.symbol,
                symbolPretty: t.symbol_pretty,
            }));
        });
        builder.addCase(getTokenPairs.rejected, (state) => {
            state.requestStates.tokenPairs = REQUEST_STATE.ERROR;
        });

        /* Get all token pair prices */
        builder.addCase(getTokenPairPrices.pending, (state) => {
            state.requestStates.tokenPairPrices = REQUEST_STATE.LOADING;
        });
        builder.addCase(getTokenPairPrices.fulfilled, (state, { payload }) => {
            state.requestStates.tokenPairPrices = REQUEST_STATE.OK;
            state.tokenPairPrices = mapTPPResponseToTPP(payload);
        });
        builder.addCase(getTokenPairPrices.rejected, (state) => {
            state.requestStates.tokenPairPrices = REQUEST_STATE.ERROR;
        });

        /* Get 1 token pair price */
        builder.addCase(getTokenPairPrice.pending, (state) => {
            state.requestStates.tokenPairPrice = REQUEST_STATE.LOADING;
        });
        builder.addCase(getTokenPairPrice.fulfilled, (state, { payload }) => {
            state.requestStates.tokenPairPrice = REQUEST_STATE.OK;
            const mappedPayload = mapTPPResponseToTPP([payload])[0];
            if (!state.tokenPairPrices || state.tokenPairPrices.length === 0) {
                state.tokenPairPrices = [mappedPayload];
            } else {
                state.tokenPairPrices = state.tokenPairPrices.map((tpp) => {
                    if (tpp.tokenpairId === payload.tokenpair_id)
                        return mappedPayload;
                    return tpp;
                });
            }
        });
        builder.addCase(getTokenPairPrice.rejected, (state) => {
            state.requestStates.tokenPairPrice = REQUEST_STATE.ERROR;
        });

        /* Get user balance */
        builder.addCase(getBalanceExtended.pending, (state) => {
            state.requestStates.getBalance = REQUEST_STATE.LOADING;
        });
        builder.addCase(getBalanceExtended.fulfilled, (state, { payload }) => {
            state.requestStates.getBalance = REQUEST_STATE.OK;
            state.balances = payload.map((b) => ({
                currencyId: b.currency_id,
                tokenCode: b.token_cod,
                amount: b.amount,
                amountARS: b.amount_ARS,
                amountUSD: b.amount_USD,
                balanceBTC: b.balance_BTC,
                balanceETH: b.balance_ETH,
            }));
        });
        builder.addCase(getBalanceExtended.rejected, (state) => {
            state.requestStates.getBalance = REQUEST_STATE.ERROR;
        });

        /* Set user balance */
        builder.addCase(setBalance.pending, (state) => {
            state.requestStates.setBalance = REQUEST_STATE.LOADING;
        });
        builder.addCase(setBalance.fulfilled, (state) => {
            state.requestStates.setBalance = REQUEST_STATE.OK;
        });
        builder.addCase(setBalance.rejected, (state) => {
            state.requestStates.setBalance = REQUEST_STATE.ERROR;
        });

        /* Add user balance */
        builder.addCase(addBalance.pending, (state) => {
            state.requestStates.addBalance = REQUEST_STATE.LOADING;
        });
        builder.addCase(addBalance.fulfilled, (state) => {
            state.requestStates.addBalance = REQUEST_STATE.OK;
        });
        builder.addCase(addBalance.rejected, (state) => {
            state.requestStates.addBalance = REQUEST_STATE.ERROR;
        });

        /* Token pair prices 24hr */
        builder.addCase(getTokenPairPrices24hr.pending, (state) => {
            state.requestStates.tokenPairPrices24hr = REQUEST_STATE.LOADING;
        });
        builder.addCase(
            getTokenPairPrices24hr.fulfilled,
            (state, { payload }) => {
                state.requestStates.tokenPairPrices24hr = REQUEST_STATE.OK;
                state.tokenPairPrices24hr = payload.map((tpp) => ({
                    tokenpairId: tpp.tokenpair_id,
                    baseId: tpp.base_id,
                    quoteId: tpp.quote_id,
                    symbol: tpp.symbol,
                    priceChange: tpp.priceChange,
                    priceChangePercent: tpp.priceChangePercent,
                    weightedAvgPrice: tpp.weightedAvgPrice,
                    prevClosePrice: tpp.prevClosePrice,
                    lastPrice: tpp.lastPrice,
                }));
            },
        );
        builder.addCase(getTokenPairPrices24hr.rejected, (state) => {
            state.requestStates.tokenPairPrices24hr = REQUEST_STATE.ERROR;
        });
    },
});

export const commonActions = commonSlice.actions;

export const commonReducer = commonSlice.reducer;
