import { Tabs } from 'common';
import { ROUTE_PATH } from 'layout/routes/const';
import { useLocation } from 'react-router-dom';
import { profileSubRouteTitles } from './const';
import { profileSubRoutes } from './Profile';

/* since is rendered on subroutes, needs to have full path to
correctly change the url */
const _getFullPath = (path: string) => `${ROUTE_PATH.PROFILE}/${path}`;

export const ProfileTabs = () => {
    const { pathname } = useLocation();
    return (
        <Tabs
            items={profileSubRoutes.map((route) => {
                const fullPath = _getFullPath(route.path);
                return {
                    title: profileSubRouteTitles[route.id],
                    active: pathname === fullPath,
                    link: fullPath,
                };
            })}
            style={{ marginBottom: 15 }}
        />
    );
};
