import { Button, P, Table } from '@common';
import { ExchangeOrderStatusText } from 'common/Table/common-cells';
import { formatISODate } from 'common/Table/utils';
import { dotAndComma } from 'utils';
import { formatNumber } from 'utils/string';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { useEffect } from 'react';
import type { ReactNode } from 'react';
import { getCoins } from '../../../../../common/commonService';
import { getSingleCoinFromId } from '../../../../../utils/currency';
import { Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import {
    cancelExchangeCryptoWithdrawal,
    cancelExchangeWithdrawal,
    doneExchangeCryptoWithdrawal,
    doneExchangeWithdrawal,
    getOperatorExchangeCryptoWithdrawal,
    getOperatorExchangeWithdrawal,
} from '../../../operatorService';
import type { OperatorWithdrawalTableItem } from 'screens/operator/types';
import {
    isExchangeCryptoWithdrawals,
    isExchangeWithdrawals,
} from 'screens/operator/types';
import Big from 'big.js';

interface DashboardWithdrawalProps {
    data: OperatorWithdrawalTableItem[];
    loading: boolean;
    noDataText: string;
}

export const WithdrawalOrders = (props: DashboardWithdrawalProps) => {
    const dispatch = useAppDispatch();
    const { coins } = useAppSelector((state) => state.common);

    const getCoin = (id: string): string => {
        return getSingleCoinFromId(coins, id);
    };

    useEffect(() => {
        dispatch(getCoins());
    }, []);

    const handleExchangeWithdrawal = (id: string, isExecute: boolean) => {
        dispatch(
            isExecute
                ? doneExchangeWithdrawal({
                      id: id,
                  })
                : cancelExchangeWithdrawal({
                      id: id,
                  }),
        )
            .unwrap()
            .then(() => {
                dispatch(getCoins());
                dispatch(getOperatorExchangeWithdrawal());
            })
            .catch(() => {
                toast.error(
                    `Hubo un error al ${
                        isExecute ? 'ejecutar' : 'cancelar'
                    } el retiro, intente lo más tarde.`,
                );
            });
    };

    const handleCryptoExchangeWithdrawal = (id: string, isExecute: boolean) => {
        dispatch(
            isExecute
                ? doneExchangeCryptoWithdrawal({
                      id: id,
                  })
                : cancelExchangeCryptoWithdrawal({
                      id: id,
                  }),
        )
            .unwrap()
            .then(() => {
                dispatch(getCoins());
                dispatch(getOperatorExchangeCryptoWithdrawal());
            })
            .catch(() => {
                toast.error(
                    `Hubo un error al ${
                        isExecute ? 'ejecutar' : 'cancelar'
                    } el retiro, intente lo más tarde.`,
                );
            });
    };

    const getWithdrawalOrderColumns = (
        key: string,
        withdrawalTableItem: OperatorWithdrawalTableItem,
    ): ReactNode => {
        switch (key) {
            case 'id':
                return withdrawalTableItem.getExchangeWithdrawals?.id || '';
            case 'userId':
                return withdrawalTableItem.getExchangeWithdrawals?.userId || '';
            case 'fullname':
                if (!withdrawalTableItem.getExchangeWithdrawals) return null;
                const { name, lastname } =
                    withdrawalTableItem.getExchangeWithdrawals || {};
                return name + ' ' + lastname || '';
            case 'email':
                if (!withdrawalTableItem.getExchangeWithdrawals) return null;
                const { email } =
                    withdrawalTableItem.getExchangeWithdrawals || {};
                return email || '';
            case 'currencyId':
                return getCoin(
                    withdrawalTableItem.getExchangeWithdrawals?.currencyId ||
                        '',
                );
            case 'amount':
                if (!withdrawalTableItem.getExchangeWithdrawals) return null;
                const { amount, currencyId } =
                    withdrawalTableItem.getExchangeWithdrawals;
                return `${dotAndComma(formatNumber(Big(amount)))} ${getCoin(
                    currencyId,
                )}`;
            case 'createdAt':
                if (!withdrawalTableItem.getExchangeWithdrawals) return null;
                return formatISODate(
                    withdrawalTableItem.getExchangeWithdrawals.createdAt,
                );
            case 'updatedAt':
                if (!withdrawalTableItem.getExchangeWithdrawals) return null;
                return formatISODate(
                    withdrawalTableItem.getExchangeWithdrawals.updatedAt,
                );
            case 'changeStateAt':
                if (!withdrawalTableItem.getExchangeWithdrawals) return null;
                return formatISODate(
                    withdrawalTableItem.getExchangeWithdrawals.changeStateAt,
                );
            case 'active-executed':
                if (!withdrawalTableItem.getExchangeWithdrawals) return null;
                const { active, executed, changeStateBy } =
                    withdrawalTableItem.getExchangeWithdrawals;
                return (
                    <ExchangeOrderStatusText
                        active={active}
                        executed={executed}
                        changeStateBy={changeStateBy}
                        isExtraction
                    />
                );
            case 'actions':
                if (!withdrawalTableItem.getExchangeWithdrawals) return null;
                const { id } = withdrawalTableItem.getExchangeWithdrawals;
                if (
                    withdrawalTableItem.getExchangeWithdrawals.active &&
                    !withdrawalTableItem.getExchangeWithdrawals.executed
                ) {
                    return (
                        <Col>
                            <Row className='flex-nowrap'>
                                <Button
                                    className='mr-2'
                                    disabled={props.loading}
                                    onClick={() =>
                                        handleExchangeWithdrawal(id, true)
                                    }
                                    style={{ minWidth: '135px' }}
                                >
                                    Realizado
                                </Button>
                                <Button
                                    color='danger'
                                    disabled={props.loading}
                                    onClick={() =>
                                        handleExchangeWithdrawal(id, false)
                                    }
                                    style={{ minWidth: '135px' }}
                                >
                                    Cancelar
                                </Button>
                            </Row>
                        </Col>
                    );
                } else {
                    return null;
                }
            default:
                return null;
        }
    };

    const getCryptoWithdrawalColumns = (
        key: string,
        withdrawalTableItem: OperatorWithdrawalTableItem,
    ): ReactNode => {
        switch (key) {
            case 'id':
                return withdrawalTableItem.exchangeCryptoWithdrawals?.id || '';
            case 'userId':
                return (
                    withdrawalTableItem.exchangeCryptoWithdrawals?.userId || ''
                );
            case 'fullname':
                if (!withdrawalTableItem.exchangeCryptoWithdrawals) return null;
                const { name, lastname } =
                    withdrawalTableItem.exchangeCryptoWithdrawals || {};
                return name + ' ' + lastname || '';
            case 'email':
                if (!withdrawalTableItem.exchangeCryptoWithdrawals) return null;
                const { email } =
                    withdrawalTableItem.exchangeCryptoWithdrawals || {};
                return email || '';
            case 'currencyId':
                return getCoin(
                    withdrawalTableItem.exchangeCryptoWithdrawals?.currencyId ||
                        '',
                );
            case 'amount':
                if (!withdrawalTableItem.exchangeCryptoWithdrawals) return null;
                const { amount, currencyId } =
                    withdrawalTableItem.exchangeCryptoWithdrawals;
                return `${dotAndComma(formatNumber(Big(amount)))} ${getCoin(
                    currencyId,
                )}`;
            case 'createdAt':
                if (!withdrawalTableItem.exchangeCryptoWithdrawals) return null;
                return formatISODate(
                    withdrawalTableItem.exchangeCryptoWithdrawals.createdAt,
                );
            case 'updatedAt':
                if (!withdrawalTableItem.exchangeCryptoWithdrawals) return null;
                return formatISODate(
                    withdrawalTableItem.exchangeCryptoWithdrawals.updatedAt,
                );
            case 'changeStateAt':
                if (!withdrawalTableItem.exchangeCryptoWithdrawals) return null;
                return formatISODate(
                    withdrawalTableItem.exchangeCryptoWithdrawals.changeStateAt,
                );
            case 'active-executed':
                if (!withdrawalTableItem.exchangeCryptoWithdrawals) return null;
                const { active, executed, changeStateBy } =
                    withdrawalTableItem.exchangeCryptoWithdrawals;
                return (
                    <ExchangeOrderStatusText
                        active={active}
                        executed={executed}
                        changeStateBy={changeStateBy}
                        isExtraction
                    />
                );
            case 'actions':
                if (!withdrawalTableItem.exchangeCryptoWithdrawals) return null;
                const { id } = withdrawalTableItem.exchangeCryptoWithdrawals;
                if (
                    withdrawalTableItem.exchangeCryptoWithdrawals.active &&
                    !withdrawalTableItem.exchangeCryptoWithdrawals.executed
                ) {
                    return (
                        <Col>
                            <Row className='flex-nowrap'>
                                <Button
                                    className='mr-2'
                                    disabled={props.loading}
                                    onClick={() =>
                                        handleCryptoExchangeWithdrawal(id, true)
                                    }
                                    style={{ minWidth: '135px' }}
                                >
                                    Realizado
                                </Button>
                                <Button
                                    color='danger'
                                    disabled={props.loading}
                                    onClick={() =>
                                        handleCryptoExchangeWithdrawal(
                                            id,
                                            false,
                                        )
                                    }
                                    style={{ minWidth: '135px' }}
                                >
                                    Cancelar
                                </Button>
                            </Row>
                        </Col>
                    );
                } else {
                    return null;
                }
            default:
                return null;
        }
    };

    return (
        <div style={{ overflowX: 'auto' }}>
            <Table
                loading={props.loading}
                noDataText={props.noDataText}
                data={props.data}
                columns={[
                    {
                        id: '__id',
                        header: 'Número de operación',
                        cell: (value, row) => {
                            const withdrawalTableItem =
                                row as OperatorWithdrawalTableItem;
                            if (isExchangeWithdrawals(withdrawalTableItem)) {
                                return getWithdrawalOrderColumns(
                                    'id',
                                    withdrawalTableItem,
                                );
                            } else if (
                                isExchangeCryptoWithdrawals(withdrawalTableItem)
                            ) {
                                return getCryptoWithdrawalColumns(
                                    'id',
                                    withdrawalTableItem,
                                );
                            }
                        },
                    },
                    {
                        id: 'fullname',
                        header: 'Nombre completo',
                        cell: (value, row) => {
                            const withdrawalTableItem =
                                row as OperatorWithdrawalTableItem;
                            if (isExchangeWithdrawals(withdrawalTableItem)) {
                                return getWithdrawalOrderColumns(
                                    'fullname',
                                    withdrawalTableItem,
                                );
                            } else if (
                                isExchangeCryptoWithdrawals(withdrawalTableItem)
                            ) {
                                return getCryptoWithdrawalColumns(
                                    'fullname',
                                    withdrawalTableItem,
                                );
                            }
                        },
                    },
                    {
                        id: 'email',
                        header: 'Email',
                        cell: (value, row) => {
                            const withdrawalTableItem =
                                row as OperatorWithdrawalTableItem;
                            if (isExchangeWithdrawals(withdrawalTableItem)) {
                                return getWithdrawalOrderColumns(
                                    'email',
                                    withdrawalTableItem,
                                );
                            } else if (
                                isExchangeCryptoWithdrawals(withdrawalTableItem)
                            ) {
                                return getCryptoWithdrawalColumns(
                                    'email',
                                    withdrawalTableItem,
                                );
                            }
                        },
                    },
                    {
                        id: 'userId',
                        header: 'Usuario',
                        cell: (value, row) => {
                            const withdrawalTableItem =
                                row as OperatorWithdrawalTableItem;
                            if (isExchangeWithdrawals(withdrawalTableItem)) {
                                return getWithdrawalOrderColumns(
                                    'userId',
                                    withdrawalTableItem,
                                );
                            } else if (
                                isExchangeCryptoWithdrawals(withdrawalTableItem)
                            ) {
                                return getCryptoWithdrawalColumns(
                                    'userId',
                                    withdrawalTableItem,
                                );
                            }
                        },
                    },
                    {
                        id: 'currencyId',
                        header: 'Moneda',
                        cell: (value, row) => {
                            const withdrawalTableItem =
                                row as OperatorWithdrawalTableItem;
                            if (isExchangeWithdrawals(withdrawalTableItem)) {
                                return getWithdrawalOrderColumns(
                                    'currencyId',
                                    withdrawalTableItem,
                                );
                            } else if (
                                isExchangeCryptoWithdrawals(withdrawalTableItem)
                            ) {
                                return getCryptoWithdrawalColumns(
                                    'currencyId',
                                    withdrawalTableItem,
                                );
                            }
                        },
                    },
                    {
                        id: 'amount',
                        header: 'Cantidad',
                        cell: (value, row) => {
                            const withdrawalTableItem =
                                row as OperatorWithdrawalTableItem;
                            if (isExchangeWithdrawals(withdrawalTableItem)) {
                                return getWithdrawalOrderColumns(
                                    'amount',
                                    withdrawalTableItem,
                                );
                            } else if (
                                isExchangeCryptoWithdrawals(withdrawalTableItem)
                            ) {
                                return getCryptoWithdrawalColumns(
                                    'amount',
                                    withdrawalTableItem,
                                );
                            }
                        },
                    },
                    {
                        id: 'createdAt',
                        header: 'Fecha de creación',
                        cell: (value, row) => {
                            const withdrawalTableItem =
                                row as OperatorWithdrawalTableItem;
                            if (isExchangeWithdrawals(withdrawalTableItem)) {
                                return getWithdrawalOrderColumns(
                                    'createdAt',
                                    withdrawalTableItem,
                                );
                            } else if (
                                isExchangeCryptoWithdrawals(withdrawalTableItem)
                            ) {
                                return getCryptoWithdrawalColumns(
                                    'createdAt',
                                    withdrawalTableItem,
                                );
                            }
                        },
                    },
                    {
                        id: 'updatedAt',
                        header: 'Fecha de actualizacion',
                        cell: (value, row) => {
                            const withdrawalTableItem =
                                row as OperatorWithdrawalTableItem;
                            if (isExchangeWithdrawals(withdrawalTableItem)) {
                                return getWithdrawalOrderColumns(
                                    'updatedAt',
                                    withdrawalTableItem,
                                );
                            } else if (
                                isExchangeCryptoWithdrawals(withdrawalTableItem)
                            ) {
                                return getCryptoWithdrawalColumns(
                                    'updatedAt',
                                    withdrawalTableItem,
                                );
                            }
                        },
                    },
                    {
                        id: 'changeStateAt',
                        header: 'Fecha de modificacion',
                        cell: (value, row) => {
                            const withdrawalTableItem =
                                row as OperatorWithdrawalTableItem;
                            if (isExchangeWithdrawals(withdrawalTableItem)) {
                                return getWithdrawalOrderColumns(
                                    'changeStateAt',
                                    withdrawalTableItem,
                                );
                            } else if (
                                isExchangeCryptoWithdrawals(withdrawalTableItem)
                            ) {
                                return getCryptoWithdrawalColumns(
                                    'changeStateAt',
                                    withdrawalTableItem,
                                );
                            }
                        },
                    },
                    {
                        id: 'active-executed',
                        header: 'Estado',
                        cell: (value, row) => {
                            const withdrawalTableItem =
                                row as OperatorWithdrawalTableItem;
                            if (isExchangeWithdrawals(withdrawalTableItem)) {
                                return getWithdrawalOrderColumns(
                                    'active-executed',
                                    withdrawalTableItem,
                                );
                            } else if (
                                isExchangeCryptoWithdrawals(withdrawalTableItem)
                            ) {
                                return getCryptoWithdrawalColumns(
                                    'active-executed',
                                    withdrawalTableItem,
                                );
                            }
                        },
                    },
                    {
                        id: 'actions',
                        header: 'Acciones',
                        cell: (value, row) => {
                            const withdrawalTableItem =
                                row as OperatorWithdrawalTableItem;
                            if (isExchangeWithdrawals(withdrawalTableItem)) {
                                return getWithdrawalOrderColumns(
                                    'actions',
                                    withdrawalTableItem,
                                );
                            } else if (
                                isExchangeCryptoWithdrawals(withdrawalTableItem)
                            ) {
                                return getCryptoWithdrawalColumns(
                                    'actions',
                                    withdrawalTableItem,
                                );
                            }
                        },
                    },
                ]}
            />
        </div>
    );
};
