import { useAppDispatch, useAppSelector } from 'redux/store';
import { UL } from '@common';
import { Logout } from './Logout';
import { MoonLight } from './MoonLight';
import { sessionActions } from 'screens/session/sessionSlice';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { REQUEST_STATE } from 'redux/types';
import { LoaderWheel } from 'common/Loader/LoaderWheel';

export const RightBar = ({ toggle }: any) => {
    const dispatch = useAppDispatch();

    const open = toggle ? 'open' : '';

    const handleLogOut = () => {
        dispatch(sessionActions.logout());
        window.location.href = '/';
    };

    const { requestStates } = useAppSelector((state) => state.quotation);
    const { requestStates: commonRequestStates } = useAppSelector(
        (state) => state.common,
    );
    const { requestStates: operatorRequestStates } = useAppSelector(
        (state) => state.operator,
    );

    const isLoading =
        Object.values(requestStates).some(
            (rs) => rs === REQUEST_STATE.LOADING,
        ) ||
        Object.values(commonRequestStates).some(
            (rs) => rs === REQUEST_STATE.LOADING,
        ) ||
        operatorRequestStates.getOperatorUser === REQUEST_STATE.LOADING ||
        operatorRequestStates.getExchangeOrder === REQUEST_STATE.LOADING ||
        operatorRequestStates.suspendUserExchangeLimit ===
            REQUEST_STATE.LOADING ||
        operatorRequestStates.reactivateUserExchangeLimit ===
            REQUEST_STATE.LOADING;

    return (
        <div className='nav-right col pull-right right-menu p-0'>
            <UL simple className={`d-flex flex-row nav-menus ${open}`}>
                <MoonLight />
                {isLoading ? (
                    <LoaderWheel
                        style={{ position: 'absolute', left: '35px' }}
                    />
                ) : null}
                <Logout onClick={handleLogOut} />
            </UL>
        </div>
    );
};
