interface ErrorTagProps {
    error: string;
}

export const ErrorTag = (props: ErrorTagProps) => {
    return (
        <div
            style={{
                width: '137px',
                height: '29px',
                margin: '5px 10px 0px 0px',
                padding: '5px',
                borderRadius: '10px',
                border: 'solid 1px rgba(212, 53, 84, 0.4)',
                backgroundColor: 'rgba(212, 53, 84, 0.12)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div>
                <p
                    style={{
                        fontSize: '11px',
                        fontWeight: '600',
                        color: '#d43554',
                    }}
                >
                    <i
                        className='icofont icofont-close-circled'
                        style={{
                            fontSize: '13px',
                            marginRight: '5px',
                            fontWeight: '600',
                        }}
                    ></i>
                    {props.error}
                </p>
            </div>
        </div>
    );
};
