import bisbull_text_logo_green from 'assets/images/bisbull_text_logo_green.png';
import bisbull_icon_logo_green from 'assets/images/logo.png';
import { Button } from 'common';
import { NavLink } from 'react-router-dom';
import { useLoadedImage } from 'utils';

export const LandingHeader = () => {
    const text_greenLoaded = useLoadedImage(bisbull_text_logo_green);
    const icon_greenLoaded = useLoadedImage(bisbull_icon_logo_green);

    return (
        <div className='header'>
            <div className='brand-container'>
                <img
                    src={bisbull_icon_logo_green}
                    style={{
                        display: !!icon_greenLoaded ? 'block' : 'none',
                    }}
                    alt='Texto del logo'
                />
                <img
                    src={bisbull_text_logo_green}
                    style={{
                        display: !!text_greenLoaded ? 'block' : 'none',
                    }}
                    alt='Ícono del logo'
                />
            </div>
            <div className='links'>
                {/* <NavLink className='btn' to='/criptomonedas'>
                    Criptomonedas
                </NavLink>
                <NavLink className='btn' to='/cotizacion'>
                    Cotización
                </NavLink> */}
                <NavLink to='/login'>
                    <Button className='btn' color='transparent'>
                        Iniciar Sesión
                    </Button>
                </NavLink>
                <NavLink to='/registro'>
                    <Button className='btn btn-primary' color='primary'>
                        Registrate
                    </Button>
                </NavLink>
            </div>
        </div>
    );
};
