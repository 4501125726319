import { useAppDispatch, useAppSelector } from 'redux/store';
import { RefreshCw } from 'react-feather';
import { useFormik } from 'formik';
import {
    getBalance,
    getCoins,
    getTokenPairPrices,
    getTokenPairs,
} from 'common/commonService';
import { ORDER_SIDE } from '../../quotation/types';
import { Button, Card, H5, Input, P, TextDropdown } from '@common';
import type { TextDropdownOption } from '@common';
import { CoinComponentObject } from 'common/helper';
import { convertCryptoUtils, dotAndComma } from 'utils/currency';
import Big from 'big.js';
import { Col, Form, Row } from 'reactstrap';
import { addExchangeQuote } from '../../quotation/quotationService';
import { toast } from 'react-toastify';
import { modalActions } from 'common/Modal/modalSlice';
import { MODAL_OPEN } from 'common/Modal/const';
import { useEffect, useState } from 'react';
import './ConvertCryptos.scss';
import { REQUEST_STATE } from '../../../../redux/types';

export interface ConvertCryptosValues {
    amountFrom: string;
    coinFrom: string;
    coinTo: string;
}

export const ConvertCryptos = () => {
    const dispatch = useAppDispatch();
    const [switchCoin, setSwitchCoin] = useState(false);
    const { coins, balances, tokenPairPrices, tokenPairs } = useAppSelector(
        (state) => state.common,
    );
    const { coins: coinsReq, tokenPairPrices: tppReq } = useAppSelector(
        (state) => state.common.requestStates,
    );
    const [options, setOptions] = useState<TextDropdownOption[]>([]);

    const formik = useFormik<ConvertCryptosValues>({
        initialValues: {
            amountFrom: '',
            coinFrom: '',
            coinTo: '',
        },
        onSubmit: (values) => {
            const equivalentTo = convertCryptoUtils.getToAmountEquivalent(
                coins,
                tokenPairPrices,
                {
                    fromCoinId: values.coinFrom,
                    toCoinId: values.coinTo,
                    fromAmount: values.amountFrom,
                },
            );
            const toCoin = coins?.find(
                (c) => c.id.toString() === values.coinTo,
            );
            const fromCoin = coins?.find(
                (c) => c.id.toString() === values.coinFrom,
            );
            const foundTP = tokenPairs?.find(
                (tp) =>
                    (tp.quoteCode === fromCoin?.tokenCode &&
                        tp.baseCode === toCoin?.tokenCode) ||
                    (tp.baseCode === fromCoin?.tokenCode &&
                        tp.quoteCode === toCoin?.tokenCode),
            );
            if (!fromCoin || !toCoin || !foundTP) return;
            dispatch(
                addExchangeQuote({
                    amount: values.amountFrom,
                    tokenpair_id: foundTP.id,
                    order_side: equivalentTo.type,
                    amount_in:
                        equivalentTo.type === ORDER_SIDE.BUY
                            ? foundTP.quoteCode
                            : foundTP.baseCode,
                    symbol: foundTP.symbol,
                    executeAfterConfirm: null,
                }),
            )
                .unwrap()
                .then(() => {
                    dispatch(modalActions.setModalOpen(MODAL_OPEN.BUY_CONFIRM));
                })
                .catch(() => {
                    toast.error(
                        'No se pudo realizar la operación, contacte el administrador e intente denuevo.',
                    );
                });
        },
    });

    useEffect(() => {
        dispatch(getCoins());
        dispatch(getBalance());
        dispatch(getTokenPairPrices());
        dispatch(getTokenPairs());
    }, []);

    useEffect(() => {
        setOptions(
            convertCryptoUtils.getFromOptions(
                coins,
                tokenPairPrices,
                true,
                formik.values.coinTo,
            ),
        );
    }, [formik.values.coinTo, coins, tokenPairPrices]);

    const _getMaxBalance = (fromCoinId: string) =>
        convertCryptoUtils.getMaxAmount(balances, fromCoinId);
    const _limitAmount = (fromCoinId: string, value: string): string => {
        if (!balances) return '';
        const maxBalance = _getMaxBalance(fromCoinId);
        if (!value || maxBalance.eq(Big(0))) return '';
        if (Big(value).gt(maxBalance)) return maxBalance.toString();
        if (Big(value).lt(Big(0))) return '0';
        return value;
    };
    const _getSrcFromCoinId = (
        id: string,
    ): React.FunctionComponent<React.SVGAttributes<SVGElement>> | undefined => {
        if (!coins) return undefined;
        const foundCoin = coins.find((c) => c.id.toString() === id);
        if (!foundCoin) return undefined;
        const res = CoinComponentObject[foundCoin.tokenCode].component;
        return res;
    };
    const handleFromAmountChange = (value: string) => {
        const foundCoin = coins?.find(
            (c) => c.id.toString() === formik.values.coinFrom,
        );
        if (!coins || !foundCoin) return;
        formik.setFieldValue(
            'amountFrom',
            _limitAmount(foundCoin.id.toString(), value),
        );
    };
    const renderUseAllBalance = () => {
        const foundCoin = coins?.find(
            (c) => c.id.toString() === formik.values.coinFrom,
        );
        if (!formik.values.coinFrom || !coins || !foundCoin) return null;
        const max = _getMaxBalance(foundCoin.id.toString());
        return (
            <P
                color='primary'
                style={{
                    fontSize: '11px',
                    marginBottom: '2px',
                    textDecoration: 'underline',
                }}
                onClick={() =>
                    formik.setFieldValue('amountFrom', max.toString())
                }
            >
                {`Usar todo mi saldo (${dotAndComma(max.toString())} ${
                    foundCoin.tokenCode
                })`}
            </P>
        );
    };

    const equivalentAmountData = convertCryptoUtils.getToAmountEquivalent(
        coins,
        tokenPairPrices,
        {
            fromCoinId: formik.values.coinFrom,
            toCoinId: formik.values.coinTo,
            fromAmount: formik.values.amountFrom,
        },
    );
    const handleClickSwitchButton = () => {
        if (formik.values.coinTo && formik.values.coinFrom) {
            if (
                Big(equivalentAmountData.value).gt(
                    _getMaxBalance(formik.values.coinTo),
                )
            ) {
                formik.setFieldValue(
                    'amountFrom',
                    _getMaxBalance(formik.values.coinTo).toString(),
                );
            } else {
                formik.setFieldValue('amountFrom', equivalentAmountData.value);
            }
            setSwitchCoin(!switchCoin);
            formik.setFieldValue('coinFrom', formik.values.coinTo);
            formik.setFieldValue('coinTo', formik.values.coinFrom);
        }
    };
    // flex-column-reverse flex-sm-row flex-xl-column-reverse
    return (
        <Card noPadding noOverflow className='wallet-convert-cryptos'>
            <Form onSubmit={formik.handleSubmit}>
                <div style={{ padding: '2rem' }}>
                    <H5 style={{ fontWeight: 'bold' }}>
                        Convertir criptomonedas
                    </H5>
                </div>
                <H5 style={{ paddingLeft: '2rem' }}>De</H5>
                <Row
                    className='mt-4 column-reverse-rows'
                    style={{ padding: '0 2rem', minHeight: '130px' }}
                >
                    <Col>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'left',
                            }}
                        >
                            <Input
                                type='number'
                                value={formik.values.amountFrom}
                                className='input-amount'
                                style={{
                                    textAlign: 'left',
                                    fontSize: '20px',
                                    paddingLeft: '10px',
                                    minWidth: '200px',
                                }}
                                onChange={(e) =>
                                    handleFromAmountChange(e.target.value)
                                }
                                placeholder='0,00000000'
                            />
                            {renderUseAllBalance()}
                        </div>
                    </Col>
                    <Col>
                        <TextDropdown
                            loading={
                                coinsReq === REQUEST_STATE.LOADING ||
                                tppReq === REQUEST_STATE.LOADING
                            }
                            noDataText='No hay monedas disponibles'
                            options={options}
                            value={formik.values.coinFrom}
                            component={_getSrcFromCoinId(
                                formik.values.coinFrom,
                            )}
                            onChange={(option) => {
                                if (
                                    option.id !== formik.values.coinFrom &&
                                    option.id !== formik.values.coinTo
                                ) {
                                    formik.setFieldValue('coinFrom', option.id);
                                    formik.setFieldValue('amountFrom', '');
                                }
                            }}
                            showBorder
                            narrowBorder
                        />
                    </Col>
                </Row>
                <div
                    style={{
                        height: '50px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '20px auto',
                    }}
                >
                    <div
                        className={`switch-coin-button ${
                            formik.values.coinFrom && formik.values.coinTo
                                ? ''
                                : ' btn-disabled'
                        }`}
                        onClick={handleClickSwitchButton}
                    >
                        <RefreshCw size='18' />
                    </div>
                    <hr
                        style={{
                            position: 'absolute',
                            width: '100%',
                            zIndex: '1',
                        }}
                    />
                </div>
                <H5 style={{ paddingLeft: '2rem' }}>A</H5>
                <Row
                    className='mb-2 column-reverse-rows'
                    style={{ padding: '0 2rem 2rem 2rem' }}
                >
                    <Col
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '10px',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'left',
                                marginTop: '20px',
                            }}
                        >
                            <P
                                color='info'
                                style={{
                                    minWidth: '200px',
                                    fontSize: '20px',
                                    fontFamily: 'Roboto',
                                }}
                            >
                                {dotAndComma(
                                    equivalentAmountData.value.toString(),
                                )}
                            </P>
                        </div>
                    </Col>
                    <Col className='mb-sm-0 mb-3 mt-4 mt-sm-0'>
                        <TextDropdown
                            loading={
                                coinsReq === REQUEST_STATE.LOADING ||
                                tppReq === REQUEST_STATE.LOADING
                            }
                            noDataText='No hay monedas disponibles'
                            options={convertCryptoUtils.getToOptions(
                                coins,
                                tokenPairPrices,
                                formik.values.coinFrom,
                                true,
                            )}
                            value={formik.values.coinTo}
                            component={_getSrcFromCoinId(formik.values.coinTo)}
                            onChange={(option) => {
                                if (option.id !== formik.values.coinTo)
                                    formik.setFieldValue('coinTo', option.id);
                            }}
                            showBorder
                            narrowBorder
                        />
                    </Col>
                </Row>
                <Row style={{ padding: '0 2rem 2rem 2rem' }}>
                    <Button type='submit' color='primary' className='btn-block'>
                        Convertir
                    </Button>
                </Row>
            </Form>
        </Card>
    );
};
