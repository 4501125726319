import { Input, Label } from 'reactstrap';
import './InputAmount.scss';

interface InputAmountProps {
    text?: string;
    placeholder?: string;
    value: string;
    onChange: (value: string) => void;
    suffix?: string;
}

export const InputAmount = (props: InputAmountProps) => {
    return (
        <div className='input-amount'>
            <Input
                type='number'
                placeholder={props?.placeholder || '0,00'}
                value={props.value}
                onChange={(value) => {
                    props.onChange(value.target.value);
                }}
                style={{
                    marginTop: '10px',
                }}
            />
            <Label className='suffix-label'>{props.suffix}</Label>
            <Label
                style={{
                    textAlign: 'center',
                    marginTop: '5px',
                    fontFamily: 'Roboto',
                    fontWeight: '400',
                }}
            >
                {props?.text || 'Ingresa un monto'}
            </Label>
        </div>
    );
};
