import { Button as ReactstrapButton } from 'reactstrap';
import type { Color } from 'common/types';
import type { CSSProperties, MouseEventHandler, ReactNode } from 'react';
import { LoaderWheel } from 'common/Loader/LoaderWheel';

interface ButtonProps {
    color?: Color;
    size?: 'sm' | 'lg';
    children?: ReactNode;
    style?: CSSProperties;
    type?: 'submit';
    disabled?: boolean;
    loading?: boolean;
    className?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    outline?: boolean;
    border?: string;
    caret?: boolean;
}

export const Button = (props: ButtonProps) => {
    const { children, style, loading, disabled, ...rest } = props;

    return (
        <ReactstrapButton
            disabled={loading || disabled}
            style={{ borderRadius: 10, ...style }}
            {...rest}
            color={props.color || 'primary'}
        >
            {loading ? <LoaderWheel white /> : children}
        </ReactstrapButton>
    );
};
