import loginImage from 'assets/images/login_side_image.png';
import { Button } from 'common';
import { ROUTE_PATH } from 'layout/routes/const';
import { useNavigate } from 'react-router-dom';
import { Row } from 'reactstrap';
import { SessionCard } from '../components/SessionCard';

export const RegisterThanks = () => {
    const navigate = useNavigate();

    return (
        <SessionCard
            title='Gracias por registrarte!'
            subtitle='Vas a estar recibiendo un email para activar la cuenta.'
            src={loginImage}
            onSubmit={() => navigate(ROUTE_PATH.LOGIN)}
            noBackButton
        >
            <Row className='justify-content-center'>
                <Button type='submit' style={{ marginLeft: '0px' }}>
                    Redirigir al login
                </Button>
            </Row>
        </SessionCard>
    );
};
