import { Button, Card, H4 } from '@common';
import { Col, Form, Row } from 'reactstrap';
import type { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';

interface HelpPageProps {
    title: string;
    children?: ReactNode;
    className?: string;
}

export const HelpPage = (props: HelpPageProps) => {
    const navigate = useNavigate();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        navigate('/');
    };

    return (
        <Card className={props.className}>
            <Form
                onSubmit={handleSubmit}
                style={{ borderRadius: '20px', width: '100%' }}
            >
                <Row className='m-3'>
                    <Col className='ml-3'>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                height: '95%',
                            }}
                        >
                            <div
                                className='box-header mt-2 mb-4'
                                style={{ textAlign: 'center' }}
                            >
                                <H4>{props.title}</H4>
                            </div>
                            {props.children}
                        </div>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};
