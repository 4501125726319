import { Button } from 'common';
import './MarketSidebar.scss';
import type { Section } from '../Sidebar';
import { ROUTE_PATH } from 'layout/routes/const';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../redux/store';

interface MarketSidebarProps {
    sections: Section[];
}

export const MarketSidebar = (props: MarketSidebarProps) => {
    const navigate = useNavigate();

    const sidebarOpen = useAppSelector((state) => state.layout.sidebarOpen);

    return (
        <header
            className={`sidebar main-nav bisbull-market-sidebar main-nav${
                !sidebarOpen ? ' close_icon' : ''
            } 
            `}
        >
            {props.sections.map((section) => (
                <Button
                    color={
                        section.href === ROUTE_PATH.MARKET
                            ? 'primary'
                            : 'transparent'
                    }
                    onClick={() => navigate(section.href)}
                >
                    {section.icon}
                </Button>
            ))}
        </header>
    );
};
