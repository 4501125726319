import type { CoinComponentValue } from 'common/helper';
import { P } from 'common/Paragraph';

export const CoinCell = (props: CoinComponentValue) => {
    return (
        <div className='coin-type-container'>
            <props.component />
            <P>{props.text}</P>
        </div>
    );
};
