import type { COIN_TYPES } from 'utils/const';

export enum ORDER_SIDE {
    BUY = 'BUY',
    SELL = 'SELL',
}
export interface Quotation {
    id: number;
    tokenpairId: number;
    symbol: string;
    amount: string;
    priceQuote: string;
    createdAt: string;
    confirmed: boolean;
}

export interface BuyCoinValues {
    from: string;
    fromValue: string;
    to: string;
    toValue: string;
}

export interface BuySellCoinValues {
    isSelling: boolean;
    cryptoCoin: COIN_TYPES;
}

export interface ExchangeQuote {
    id: number;
    tokenpairId: number;
    orderSide: ORDER_SIDE;
    symbol: string;
    amount: string;
    amount_in: string;
    priceQuote: string;
    fee_perc: string;
    fee: string;
    fiat_spread_perc: string;
    fiat_spread: string;
    createdAt: string;
    expirationAt: string;
    confirmed: boolean;
}

export interface ExchangeOrder {
    id: number;
    tokenpairId: number;
    orderType: string;
    orderSide: ORDER_SIDE;
    symbol: string;
    amount: string;
    amountIn: string;
    netPrice: string;
    priceTarget: string;
    feePerc: string;
    fee: string;
    fiatSpreadPerc: string;
    fiatSpread: string;
    exchange: number;
    createdAt: string;
    updatedAt: string;
    active: boolean;
    executed: boolean;
    changeStateAt: string;
    changeStateBy: number;
    convertedToQuote: number;
}
