export enum ROUTE_PATH {
    // landing in unauthenticated, dashboard in authenticated
    ROOT = '/',
    // session
    LOGIN = '/login',
    LOGIN2FA = '/login-2fa',
    RECOVER_PASSWORD = '/recuperar-password',
    RECOVER_CONFIRM_PASSWORD = '/password/reset/confirm',
    REGISTER = '/registro',
    REGISTER_ACTIVATION = '/activate',
    // user and operator
    DASHBOARD = '/inicio',
    // user
    WALLET = '/billetera',
    PROFILE = '/perfil',
    HISTORY = '/historial',
    MARKET = '/trading',
    QUOTATION = '/compra-venta',
    DEPOSIT = '/inicio/depositar',
    HELP = '/ayuda',
    P2P = '/p2p',
    CONVERSOR = '/conversor',
    // operator
    USERS = '/users',
}
