import { Card, Tabs } from 'common';
import { useEffect, useState } from 'react';
import { ExternalOrders } from './tabs/ExternalOrders';
import { OpenOrders } from './tabs/OpenOrders';
import { HistoryOrders } from './tabs/HistoryOrders';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { REQUEST_STATE } from 'redux/types';
import {
    getExchangeAdvice,
    getExchangeOrder,
    getOperatorExchangeCryptoWithdrawal,
    getOperatorExchangeDeposit,
    getOperatorExchangeWithdrawal,
} from 'screens/operator/operatorService';
import type {
    ExchangeAdvice,
    OperatorExchangeCryptoWithdrawal,
    OperatorExchangeOrder,
    OperatorWithdrawalTableItem,
    OperatorExchangeWithdrawal,
} from 'screens/operator/types';
import { WithdrawalOrders } from './tabs/WithdrawalsOrders';
import { compareDesc, parseISO } from 'date-fns';
import {
    isExchangeCryptoWithdrawals,
    isExchangeWithdrawals,
    WITHDRAWAL_TABLE_ITEM_TYPE,
} from 'screens/operator/types';
import { DepositOrders } from './tabs/DepositOrders';
import './OrdersCardTables.scss';

export enum DASHBOARD_TAB {
    OPEN_ORDERS = 'OPEN_ORDERS',
    EXTERNAL_ORDERS = 'EXTERNAL_ORDERS',
    HISTORY_ORDERS = 'HISTORY_ORDERS',
    WITHDRAWALS_ORDERS = 'WITHDRAWALS_ORDERS',
    DEPOSIT_ORDERS = 'DEPOSIT_ORDERS',
    USER_WITHDRAWALS = 'USER_WITHDRAWALS',
}

const getOpenAndHistoryOrders = (
    exchangeOrders: OperatorExchangeOrder[] | null,
    operatorId?: number,
): {
    openOrders: OperatorExchangeOrder[];
    historyOrders: OperatorExchangeOrder[];
} => {
    const openOrders = [] as OperatorExchangeOrder[];
    const historyOrders = [] as OperatorExchangeOrder[];
    if (!exchangeOrders || !operatorId)
        return { openOrders: [], historyOrders: [] };
    exchangeOrders.forEach((eo) => {
        const { active, executed } = eo;
        const isActive = active && !executed;
        const isSuspendedByOperator =
            !active && !executed && eo.changeStateBy === operatorId;
        if (isActive || isSuspendedByOperator) openOrders.push(eo);
        else historyOrders.push(eo);
    });
    return { openOrders, historyOrders };
};

const sortExchangeAdvice = (
    exchangeAdvices: ExchangeAdvice[] | null,
): ExchangeAdvice[] => {
    if (!exchangeAdvices) return [];
    return exchangeAdvices.slice().sort((a, b) => {
        const aIsActive = a.active && !a.done;
        const bIsActive = b.active && !b.done;
        if (aIsActive && !bIsActive) return -1;
        else return 1;
    });
};

export const OrdersCardTables = () => {
    const [currentActiveTab, setCurrentActivTab] = useState(
        DASHBOARD_TAB.OPEN_ORDERS,
    );
    const { getExchangeOrder: getExchangeOrderReq } = useAppSelector(
        (state) => state.quotation.requestStates,
    );
    const tabsTableProps = {
        loading: getExchangeOrderReq === REQUEST_STATE.LOADING,
        noDataText: 'No hay operaciones',
    };
    const dispatch = useAppDispatch();
    const { exchangeOrders, exchangeAdvices } = useAppSelector(
        (state) => state.operator,
    );
    const operatorId = useAppSelector((state) => state.profile.userData?.id);

    const { exchangeWithdrawals: getExchangeWithrawals, exchangeDeposit } =
        useAppSelector((state) => state.operator);
    const {
        exchangeWithdrawals: getExchangeWithdrawals,
        exchangeCryptoWithdrawals,
    } = useAppSelector((state) => state.operator);

    const getExchangeTableData = (
        getExchangeWithdrawals: OperatorExchangeWithdrawal[] | null,
        exchangeCryptoWithdrawals: OperatorExchangeCryptoWithdrawal[] | null,
    ): OperatorWithdrawalTableItem[] => {
        if (!getExchangeWithdrawals || !exchangeCryptoWithdrawals) return [];
        const result = [
            ...getExchangeWithdrawals.map((eo) => ({
                type: WITHDRAWAL_TABLE_ITEM_TYPE.ARS,
                getExchangeWithdrawals: eo,
                exchangeCryptoWithdrawals: null,
            })),
            ...exchangeCryptoWithdrawals.map((ew) => ({
                type: WITHDRAWAL_TABLE_ITEM_TYPE.CRYPTO,
                getExchangeWithdrawals: null,
                exchangeCryptoWithdrawals: ew,
            })),
        ].sort((a, b) => {
            // mix exchange orders with exchange withdrawals in order
            // of creation
            let aDate: string | null = null;
            let bDate: string | null = null;
            if (isExchangeWithdrawals(a) && a.getExchangeWithdrawals)
                aDate = a.getExchangeWithdrawals.createdAt;
            else if (
                isExchangeCryptoWithdrawals(a) &&
                a.exchangeCryptoWithdrawals
            )
                aDate = a.exchangeCryptoWithdrawals.createdAt;
            if (isExchangeWithdrawals(b) && b.getExchangeWithdrawals)
                bDate = b.getExchangeWithdrawals.createdAt;
            else if (isExchangeCryptoWithdrawals(b) && b.getExchangeWithdrawals)
                bDate = b.getExchangeWithdrawals.createdAt;
            if (!aDate || !bDate) return 0;
            return compareDesc(parseISO(aDate), parseISO(bDate));
        });
        return result;
    };

    useEffect(() => {
        dispatch(getExchangeOrder());
        dispatch(getExchangeAdvice());
        dispatch(getOperatorExchangeWithdrawal());
        dispatch(getOperatorExchangeDeposit());
        dispatch(getOperatorExchangeCryptoWithdrawal());
    }, []);

    const { openOrders, historyOrders } = getOpenAndHistoryOrders(
        exchangeOrders,
        operatorId,
    );
    const externalOrders = sortExchangeAdvice(exchangeAdvices);

    const tabs = [
        {
            title: 'Ordenes abiertas',
            active: currentActiveTab === DASHBOARD_TAB.OPEN_ORDERS,
            id: DASHBOARD_TAB.OPEN_ORDERS,
            component: () => (
                <OpenOrders
                    data={openOrders}
                    key={DASHBOARD_TAB.OPEN_ORDERS}
                    {...tabsTableProps}
                />
            ),
        },
        {
            title: 'Sugerencias',
            active: currentActiveTab === DASHBOARD_TAB.EXTERNAL_ORDERS,
            id: DASHBOARD_TAB.EXTERNAL_ORDERS,
            component: () => (
                <ExternalOrders
                    data={externalOrders}
                    key={DASHBOARD_TAB.EXTERNAL_ORDERS}
                    {...tabsTableProps}
                />
            ),
        },
        {
            title: 'Historial de ordenes',
            active: currentActiveTab === DASHBOARD_TAB.HISTORY_ORDERS,
            id: DASHBOARD_TAB.HISTORY_ORDERS,
            component: () => (
                <HistoryOrders
                    data={historyOrders}
                    key={DASHBOARD_TAB.HISTORY_ORDERS}
                    {...tabsTableProps}
                />
            ),
        },
        {
            title: 'Extracciones',
            active: currentActiveTab === DASHBOARD_TAB.WITHDRAWALS_ORDERS,
            id: DASHBOARD_TAB.WITHDRAWALS_ORDERS,
            component: () => (
                <WithdrawalOrders
                    data={
                        getExchangeTableData(
                            getExchangeWithdrawals,
                            exchangeCryptoWithdrawals,
                        ) || []
                    }
                    key={DASHBOARD_TAB.WITHDRAWALS_ORDERS}
                    {...tabsTableProps}
                />
            ),
        },
        {
            title: 'Depositos',
            active: currentActiveTab === DASHBOARD_TAB.DEPOSIT_ORDERS,
            id: DASHBOARD_TAB.DEPOSIT_ORDERS,
            component: () => (
                <DepositOrders
                    data={exchangeDeposit || []}
                    key={DASHBOARD_TAB.DEPOSIT_ORDERS}
                    {...tabsTableProps}
                />
            ),
        },
    ];

    const renderCurrentTab = () => {
        const foundTab = tabs.find((tab) => tab.active);
        if (!foundTab) return null;
        return foundTab.component();
    };

    return (
        <Card className='operator-orders-tabs'>
            <Tabs
                items={tabs}
                onClick={(id) => setCurrentActivTab(id as DASHBOARD_TAB)}
            />
            {renderCurrentTab()}
        </Card>
    );
};
