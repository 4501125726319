import { Input, Label } from 'reactstrap';
import './MarketInput.scss';

interface MarketInputProps {
    name: string;
    placeholder?: string;
    value: string;
    type?: 'text' | 'number';
    disabled?: boolean;
    onChange?: (value: string) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
    className?: string;
    prefix: string;
    suffix: string;
}

export const MarketInput = (props: MarketInputProps) => {
    const mixBackgroundLayout = localStorage.getItem('mix_background_layout');

    const getClassName = () => {
        let result = 'market-input-container';
        if (props.className) result += ' ' + props.className;
        if (props.disabled) result += ' disabled';
        if (mixBackgroundLayout === 'dark-only') result += ' dark';
        return result;
    };

    return (
        <div className={getClassName()}>
            <Label className='prefix-label'>{props.prefix}</Label>
            <Input
                id={props.name}
                name={props.name}
                disabled={props.disabled}
                type={props.type || 'number'}
                placeholder={props?.placeholder || '0,00'}
                value={props.value}
                onChange={(value) => {
                    props.onChange && props.onChange(value.target.value);
                }}
                onBlur={(e) => {
                    props.onBlur && props.onBlur(e);
                }}
            />
            <Label className='suffix-label'>{props.suffix}</Label>
        </div>
    );
};
