import './History.scss';
import { ExchangeTable } from 'common/Table/ExchangeTable';
import { Card } from 'common';

export const History = () => {
    return (
        <div className='history-tables'>
            <Card style={{ marginTop: '35px' }} title='Operaciones realizadas'>
                <ExchangeTable />
            </Card>
        </div>
    );
};
