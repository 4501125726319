import { Row, Col } from 'reactstrap';
import { GraphicCard } from './GraphicCard';
import { InputCard } from './InputCard';
import { MarketWalletCard } from './MarketWalletCard';
import { OrdersTablesCard } from './OrdersTablesCard';
import { useEffect, useState } from 'react';
import { Card, TextDropdown } from '@common';
import './Market.scss';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getBalanceExtended, getTokenPairPrices } from 'common/commonService';
import { marketActions } from './marketSlice';
import { COIN_TYPES } from '../../../utils/const';
import { REQUEST_STATE } from '../../../redux/types';

export const Market = () => {
    const dispatch = useAppDispatch();
    const tokenPairPrices = useAppSelector(
        (state) => state.common.tokenPairPrices,
    );
    const { currentCoinId } = useAppSelector((state) => state.market);
    const sidebarOpen = useAppSelector((state) => state.layout.sidebarOpen);
    // const { tokenPairPrices: tppReq } = useAppSelector(
    //     (state) => state.common.requestStates,
    // );
    const [loading, setLoading] = useState(true);

    const dropdownOptions = tokenPairPrices
        ? tokenPairPrices
              .filter((tpp) => tpp.isCrypto)
              .map((tpp) => ({
                  id: tpp.tokenpairId.toString(),
                  text: tpp.symbolPretty,
                  price: tpp.totalPrice,
              }))
        : [];

    useEffect(() => {
        dispatch(getTokenPairPrices())
            .unwrap()
            .then(() => {
                const foundBTCUSDT = tokenPairPrices?.find(
                    (tpp) =>
                        tpp.baseCode === COIN_TYPES.BTC &&
                        tpp.quoteCode === COIN_TYPES.USDT,
                );
                if (!tokenPairPrices || !foundBTCUSDT) return setLoading(true);
                if (currentCoinId === null)
                    dispatch(
                        marketActions.setCurrentCoinId(
                            foundBTCUSDT.tokenpairId.toString(),
                        ),
                    );
            });
        const intervalId = setInterval(() => {
            dispatch(getTokenPairPrices());
            dispatch(getBalanceExtended());
        }, 5000);
        setLoading(false);
        return () => clearInterval(intervalId);
    }, [tokenPairPrices]);

    const responsiveClass = sidebarOpen ? '' : 'responsive-sidebar';
    //   console.log(loading);
    return (
        <div className={`bisbull-market-container ${responsiveClass}`}>
            <Row className='main-container'>
                <Col className='graphic-card-container'>
                    <Card style={{ paddingRight: '0px' }}>
                        <Row className='mb-1'>
                            <Col className='col-dropdown'>
                                <TextDropdown
                                    loading={loading}
                                    noDataText='No data'
                                    options={dropdownOptions}
                                    value={currentCoinId}
                                    onChange={(option) =>
                                        dispatch(
                                            marketActions.setCurrentCoinId(
                                                option.id,
                                            ),
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                        <GraphicCard currentCoinId={currentCoinId} />
                    </Card>
                </Col>
                <Col>
                    <Row>
                        <InputCard currentCoinId={currentCoinId} />
                    </Row>
                    <MarketWalletCard />
                </Col>
            </Row>
            <Row style={{ maxHeight: 1000 }}>
                <OrdersTablesCard />
            </Row>
        </div>
    );
};
