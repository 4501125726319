import { useEffect, useState } from 'react';

/**
 * useLoadedImage
 * ==============
 * @description stateful source when fully loaded
 * @param src image
 * @returns sourceLoaded state
 */
export const useLoadedImage = (src: any) => {
    const [sourceLoaded, setSourceLoaded] = useState(null);

    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => setSourceLoaded(src);
    }, [src]);

    return sourceLoaded;
};

/**
 * useDelayedState
 * ===============
 * @description triggers callback after delay from most recent setState triggered
 * @param initialState
 * @param callBack function to trigger with new value as parameter
 * @param timeoutDelay replace default delay of 1 second
 * @returns state and setState
 */
export const useDelayedState = <T>(
    initialState: T,
    callBack: (state: T) => void,
    timeoutDelay = 1000,
) => {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        const debounce = setTimeout(() => {
            callBack(state);
        }, timeoutDelay);
        return () => clearTimeout(debounce);
    }, [state]);

    return { state, setState };
};
