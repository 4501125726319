import type {
    DepositCryptoResponse,
    ExchangeWithdrawalResponseItem,
    ExchangeDepositResponseItem,
    ExchangeWithdrawalCryptoResponseItem,
    CryptoNetworkResponseItem,
    CurrencyCryptoNetworkResponseItem,
    DepositARSResponse,
} from './walletService';
import type {
    ExchangeDeposit,
    CryptoNetworks,
    ExchangeWithdrawal,
    CurrencyCryptoNetwork,
    ExchangeWithdrawalCrypto,
    ARSAdress,
    CryptoAdress,
} from './types';

export const mapExchangeWithDrawals = (
    exchangeWithdrawals: ExchangeWithdrawalResponseItem[],
): ExchangeWithdrawal[] => {
    if (!exchangeWithdrawals) return [];
    return exchangeWithdrawals.map((ew) => ({
        id: ew.id,
        currencyId: ew.currency_id,
        amount: ew.amount,
        createdAt: ew.created_at,
        updatedAt: ew.updated_at,
        changeStateBy: ew.change_state_by,
        changeStateAt: ew.change_state_at,
        active: ew.active,
        executed: ew.executed,
    }));
};

export const mapExchangeDeposit = (
    exchangeDeposit: ExchangeDepositResponseItem[],
): ExchangeDeposit[] => {
    if (!exchangeDeposit) return [];
    return exchangeDeposit.map((ed) => ({
        id: ed.id,
        currencyId: ed.currency_id,
        amount: ed.amount,
        referenceId: ed.reference_id,
        createdAt: ed.created_at,
        updatedAt: ed.updated_at,
        changeStateBy: ed.change_state_by,
        changeStateAt: ed.change_state_at,
        active: ed.active,
        executed: ed.executed,
    }));
};

export const mapExchangeWithdrawalsCrypto = (
    exchangeWithdrawalsCrypto: ExchangeWithdrawalCryptoResponseItem[],
): ExchangeWithdrawalCrypto[] => {
    if (!exchangeWithdrawalsCrypto) return [];
    return exchangeWithdrawalsCrypto.map((ewc) => ({
        id: ewc.id,
        currencyId: ewc.currency_id,
        address: ewc.address,
        cryptonetorkId: ewc.cryptonetwork_id,
        amount: ewc.amount,
        createdAt: ewc.created_at,
        updatedAt: ewc.updated_at,
        changeStateBy: ewc.change_state_by,
        changeStateAt: ewc.change_state_at,
        active: ewc.active,
        executed: ewc.executed,
    }));
};

export const mapDepositARS = (
    depositARS: DepositARSResponse,
): ARSAdress | null => {
    if (!depositARS) return null;
    return {
        id: depositARS.id,
        currencyId: depositARS.currency_id,
    };
};

export const mapDepositCrypto = (
    depositCrypto: DepositCryptoResponse,
): CryptoAdress | null => {
    if (!depositCrypto) return null;
    return {
        address: depositCrypto.address,
        cryptoNetworkId: depositCrypto.cryptonetwork_id,
    };
};

export const mapGetCryptoNetworks = (
    cryptoNetworks: CryptoNetworkResponseItem[],
): CryptoNetworks[] => {
    if (!cryptoNetworks) return [];
    return cryptoNetworks.map((cn) => ({
        id: cn.id,
        name: cn.name,
        createdAt: cn.created_at,
        active: cn.active,
    }));
};

export const mapGetCurrencyCryptoNetwork = (
    currencyCryptoNetwork: CurrencyCryptoNetworkResponseItem[],
): CurrencyCryptoNetwork[] => {
    if (!currencyCryptoNetwork) return [];
    return currencyCryptoNetwork.map((ccn) => ({
        currencyId: ccn.currency_id,
        cryptonetworkId: ccn.cryptonetwork_id,
        createdAt: ccn.created_at,
        active: ccn.active,
    }));
};
