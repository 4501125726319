import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.scss';
import { store } from 'redux/store';
import { Routes } from 'layout';
import { ThemeProvider } from 'providers/ThemeProvider';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

root.render(
    <Provider store={store}>
        <ThemeProvider>
            <Routes />
        </ThemeProvider>
    </Provider>,
);
