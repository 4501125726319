import type { CSSProperties, DetailedHTMLProps, HTMLAttributes } from 'react';

interface H2ElementProps
    extends DetailedHTMLProps<
        HTMLAttributes<HTMLHeadingElement>,
        HTMLHeadingElement
    > {
    fontWeight?:
        | '100'
        | '200'
        | '300'
        | '400'
        | '500'
        | '600'
        | '700'
        | '800'
        | '900';
    display?: '1' | '2' | '3' | '4';
    style?: CSSProperties;
}

export const H2 = (props: H2ElementProps) => {
    const getClassName = () => {
        let result = '';
        if (props.fontWeight) result += 'f-w-' + props.fontWeight;
        if (props.display) result += 'display-' + props.display;
        return result;
    };

    return (
        <h2 className={getClassName()} style={props.style}>
            {props.children}
        </h2>
    );
};
