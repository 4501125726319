import type { PreferenceItemTexts } from './PreferenceItem';

export enum PREFERENCE {
    SECOND_FACTOR_AUTHTENTICATION = 'SECOND_FACTOR_AUTHTENTICATION',
    SECURITY_MAILS = 'SECURITY_MAILS',
}

export const preferencesTexts: { [key in PREFERENCE]: PreferenceItemTexts } = {
    [PREFERENCE.SECOND_FACTOR_AUTHTENTICATION]: {
        buttonText: 'Configurar',
        title: 'Gestionar autentificación de dos factores',
        subTitle: 'Configurá el ingreso de tu cuenta',
    },
    [PREFERENCE.SECURITY_MAILS]: {
        buttonText: 'Habilitar',
        title: 'Habilitar mails de seguridad',
        subTitle: 'Configuración de registro de ingreso',
    },
};
