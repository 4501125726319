import { Col, Modal as ReactstrapModal, Row } from 'reactstrap';
import { Card, H4, H6 } from '@common';
import type { ReactNode } from 'react';
import { X } from 'react-feather';
import './BuyModal.scss';

interface BuyModalProps {
    open?: boolean;
    title?: string;
    onClose: () => void;
    children?: ReactNode;
    hideClose?: boolean;
    className?: string;
    closeBtn?: boolean;
    subtitle?: string | ReactNode;
    colorTop?: boolean;
    style?: React.CSSProperties;
}

export const BuyModal = (props: BuyModalProps) => {
    return (
        <Col style={props.style} className={props.className}>
            <ReactstrapModal isOpen={props.open} className='buy-modal' backdrop>
                <div
                    style={{
                        borderRadius: '15px 15px 0 0',
                        borderTop: props.colorTop ? '20px solid #3f6f64' : '',
                    }}
                ></div>
                {!props.hideClose && (
                    <div className='close-button' onClick={props.onClose}>
                        <X size={20} />
                    </div>
                )}
                <Row className='m-3'>
                    <Col>
                        <div className='title-container pt-4'>
                            <H4>{props.title}</H4>
                            <H6 fontWeight='400'>{props.subtitle}</H6>
                            {props.children}
                        </div>
                    </Col>
                </Row>
            </ReactstrapModal>
        </Col>
    );
};
