import { Input as ReactstrapCheckbox, Label } from 'reactstrap';
import type { InputProps as ReactstrapCheckboxProps } from 'reactstrap';
import type { ChangeEvent } from 'react';

interface CheckboxProps extends Omit<ReactstrapCheckboxProps, 'onChange'> {
    label?: string;
    isChecked: boolean;
    onChange: (value: boolean) => void;
    error?: string | null;
    name?: string;
    onFormikChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox = (props: CheckboxProps) => {
    const { label, onChange, isChecked, ...rest } = props;

    return (
        <div className='form-check'>
            <div className='checkbox p-0 checkbox-primary'>
                <ReactstrapCheckbox
                    name={props.name}
                    className='form-check-input'
                    id={label}
                    type='checkbox'
                    onChange={(value) => {
                        props.onChange(value.target.checked);
                        props.onFormikChange && props.onFormikChange(value);
                    }}
                    {...rest}
                />
                <Label className='form-check-label' htmlFor={label}>
                    {label}
                </Label>
                {props.error && (
                    <div className='ml-2 mb-2'>
                        <span style={{ color: '#d22d3d' }}>{props.error}</span>
                    </div>
                )}
            </div>
        </div>
    );
};
