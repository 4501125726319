import { Card, P, H1 } from 'common';
import { Row } from 'reactstrap';
import './DashboardBanner.scss';
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { personalData } from 'screens/user/profile/profileService';
import { dotAndComma, getTotalBalanceEquivalent } from 'utils/currency';
import { LoaderWheel } from 'common/Loader/LoaderWheel';
import { REQUEST_STATE } from 'redux/types';
import { getBalanceExtended, getCoins } from 'common/commonService';
import { useLoadedImage } from 'utils';
import imgBanner from 'assets/images/banner-02.png';
import { USER_TYPE } from '../../screens/user/profile/types';

export const DashboardBanner = () => {
    const dispatch = useAppDispatch();
    const { balances } = useAppSelector((state) => state.common);
    const balanceState = useAppSelector(
        (state) => state.common.requestStates.getBalance,
    );
    const userType = useAppSelector((state) => state.profile.userType);

    const loaded = useLoadedImage(imgBanner);

    useEffect(() => {
        dispatch(personalData());
        dispatch(getCoins());
        dispatch(getBalanceExtended());
    }, []);

    const totalBalanceEquivalent = getTotalBalanceEquivalent(balances);

    const isUser = userType === USER_TYPE.USER;

    return (
        <Card
            noOverflow
            className={`bisbull-dashboard-banner ${!!loaded ? 'loaded' : ''}`}
        >
            <Row className='conversion-texts'>
                <P className='f-w-500'>
                    Equivalente total en <strong>ARS</strong>
                </P>
            </Row>
            <Row>
                <H1 className='balance-big-number'>
                    {balanceState === REQUEST_STATE.LOADING ? (
                        <LoaderWheel white />
                    ) : (
                        dotAndComma(totalBalanceEquivalent.amountARS.toFixed(2))
                    )}
                </H1>
            </Row>
            {isUser && (
                <Row>
                    <NavLink className='see-account-link-text' to='/billetera'>
                        <P>Ver cuenta</P>
                    </NavLink>
                </Row>
            )}
        </Card>
    );
};
