import { H4, H6 } from '@common';
import { Col, Form, Row } from 'reactstrap';
import type { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackButton } from './BackButton';
import { useLoadedImage } from 'utils';

interface SessionCardProps {
    title: string;
    subtitle: string;
    src: string;
    children?: ReactNode;
    onSubmit: () => void;
    onClose?: () => void;
    noImage?: boolean;
    noBackButton?: boolean;
}

export const SessionCard = (props: SessionCardProps) => {
    const loaded = useLoadedImage(props.src);

    const navigate = useNavigate();
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onSubmit();
    };

    const widthSize = props.noImage ? '708px' : '1017px';

    return (
        <>
            <Form
                onSubmit={handleSubmit}
                className='theme-form login-form needs-validation'
                style={{
                    borderRadius: '20px',
                    width: widthSize,
                    /* 
                        90vh = never touch screen
                        35px = back button height
                        20px = back button bottom distance
                        20px = extra distance from back button
                     */
                    maxHeight: 'calc(90vh - 75px)',
                    overflow: 'auto',
                }}
            >
                <Row className='m-3'>
                    {props.noImage ? null : (
                        <Col>
                            <div
                                style={{
                                    display: 'flex',
                                    height: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRight: '1px solid #f4f4f4',
                                }}
                            >
                                <div
                                    style={{
                                        minHeight: '360px',
                                        minWidth: '360px',
                                    }}
                                >
                                    <img
                                        src={props.src}
                                        alt='login-img'
                                        style={{
                                            width: '360px',
                                            display: !!loaded
                                                ? 'block'
                                                : 'none',
                                        }}
                                    />
                                </div>
                            </div>
                        </Col>
                    )}
                    <Col className='ml-3'>
                        {props.onClose && (
                            <div
                                className='d-flex'
                                style={{
                                    justifyContent: 'right',
                                    cursor: 'pointer',
                                }}
                                onClick={props.onClose}
                            >
                                <i
                                    className='icofont icofont-close-circled'
                                    style={{
                                        fontWeight: '500',
                                        fontSize: '20px',
                                    }}
                                />
                            </div>
                        )}
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                height: '95%',
                                marginTop: props.onClose ? 0 : 20,
                            }}
                        >
                            <div className='box-header'>
                                <H4> {props.title}</H4>
                                <H6 fontWeight='400'>{props.subtitle}</H6>
                            </div>
                            {props.children}
                        </div>
                    </Col>
                </Row>
            </Form>
            {props.noBackButton ? null : <BackButton navigate={navigate} />}
        </>
    );
};
