import { Card, P } from '@common';
import './LandingMiddle.scss';
import image1 from 'assets/images/landing_small_1.png';
import image2 from 'assets/images/landing_small_2.png';
import image3 from 'assets/images/landing_small_3.png';
import { useLoadedImage } from 'utils';

interface ImageCardProps {
    src: string;
    alt: string;
    title: string;
    subtitle: string;
}

const ImageCard = (props: ImageCardProps) => {
    const loaded = useLoadedImage(props.src);

    return (
        <Card>
            <div style={{ minHeight: '120px' }}>
                <img
                    src={props.src}
                    alt={props.alt}
                    style={{
                        display: !!loaded ? 'block' : 'none',
                    }}
                />
            </div>
            <P className='title'>{props.title}</P>
            <P className='subtitle'>{props.subtitle}</P>
        </Card>
    );
};

export const LandingMiddle = () => {
    return (
        <div className='bisbull-landing-middle'>
            <div className='titles'>
                <P className='title'>
                    ¿Qué podés hacer en <strong> BISBULL?</strong>
                </P>
                <P className='subtitle'>
                    Tenemos un producto para cada una de tus necesidades
                    financieras
                </P>
            </div>
            <div className='cards'>
                <ImageCard
                    title='Depositá pesos'
                    subtitle='Fondeá tu cuenta por transferencia bancaria, Mercado Pago o en efectivo'
                    src={image1}
                    alt='Depositá pesos'
                />
                <ImageCard
                    title='Comprá crypto'
                    subtitle='Accedé a bitcoins y otros activos digitales en pocos pasos'
                    src={image2}
                    alt='Comprá crypto'
                />
                <ImageCard
                    title='Gestioná tus fondos'
                    subtitle='Guardá y enviá tus fondos cripto o retirá tus fondos cuando quieras'
                    src={image3}
                    alt='Gestioná tus fondos'
                />
            </div>
        </div>
    );
};
