import './MarketHeader.scss';
import bisbull_text_logo_green from 'assets/images/bisbull_text_logo_green.png';
import bisbull_text_logo_white from 'assets/images/bisbull_text_logo_white.png';
import bisbull_icon_logo_green from 'assets/images/logo.png';
import bisbull_icon_logo_orange from 'assets/images/logo_orange.png';
import { useAppSelector } from 'redux/store';
import { getTotalBalanceEquivalent } from 'utils';
import { formatNumber } from 'utils/string';
import { P } from 'common';
import { ROUTE_PATH } from 'layout/routes/const';
import { AlignCenter } from 'react-feather';
import { layoutActions } from '../../layoutSlice';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from 'redux/store';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { REQUEST_STATE } from 'redux/types';
import { LoaderWheel } from 'common/Loader/LoaderWheel';

export const MarketHeader = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { balances } = useAppSelector((state) => state.common);
    const { sidebarOpen } = useSelector((state: RootState) => state.layout);
    const mix_background_layout = localStorage.getItem('mix_background_layout');

    const [isLoading, setIsLoading] = useState(true);

    const logoIcon =
        mix_background_layout === 'dark-only'
            ? bisbull_icon_logo_orange
            : bisbull_icon_logo_green;
    const logoText =
        mix_background_layout === 'dark-only'
            ? bisbull_text_logo_white
            : bisbull_text_logo_green;

    const totalBalanceEquivalent = getTotalBalanceEquivalent(balances);

    const toggleSidebar = () =>
        dispatch(layoutActions.setSidebarOpen(!sidebarOpen));

    const { requestStates } = useAppSelector((state) => state.quotation);

    useEffect(() => {
        Object.values(requestStates).some((rs) => rs === REQUEST_STATE.LOADING)
            ? setIsLoading(true)
            : setIsLoading(false);
    }, [requestStates]);

    return (
        <div className='bisbull-market-header'>
            <div
                className='brand-container'
                onClick={() => navigate(ROUTE_PATH.DASHBOARD)}
            >
                <img
                    src={logoIcon}
                    alt='logo'
                    style={{ height: 30, paddingLeft: 10 }}
                />
                <img
                    src={logoText}
                    alt='logo'
                    style={{ height: 40, paddingRight: '10px' }}
                />
            </div>
            <div className='close-sidebar'>
                <div
                    className='toggle-sidebar'
                    style={{ marginLeft: '10px' }}
                    onClick={toggleSidebar}
                >
                    <AlignCenter
                        className='status_toggle middle'
                        id='sidebar-toggle'
                    />
                </div>
            </div>
            <div className='balance-container'>
                <P style={{ marginRight: 10 }}>Equivalente total: </P>
                <P style={{ fontWeight: 'bold' }}>
                    {`${formatNumber(totalBalanceEquivalent.amountARS)} ARS`}
                </P>
            </div>
            {isLoading && (
                <div className='loader-wheel-container'>
                    <LoaderWheel />
                </div>
            )}
        </div>
    );
};
