import { Fragment } from 'react';
import { ListGroupItem } from 'reactstrap';

interface LIProps extends React.HTMLAttributes<HTMLOListElement> {
    val?: string;
    className?: string;
    onclick?: (e: React.MouseEvent<HTMLOListElement, MouseEvent>) => void;
    style?: React.CSSProperties;
}

export const LI = (props: LIProps) => {
    return (
        <Fragment>
            <ListGroupItem
                className={props.className}
                style={props.style}
                {...props}
            >
                {props.val ? (
                    <div dangerouslySetInnerHTML={{ __html: props.val }} />
                ) : (
                    ''
                )}{' '}
                {props.children}
            </ListGroupItem>
        </Fragment>
    );
};
