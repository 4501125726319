import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { REQUEST_STATE } from 'redux/types';
import type { SymbolVariationObj } from './quotationService';
import {
    reactivateExchangeLimit,
    getQuotation,
    addExchangeQuote,
    confirmExchangeQuote,
    addExchangeLimit,
    cancelExchangeLimit,
    executeExchangeLimit,
    getExchangeOrders,
    getCoinVaration,
} from './quotationService';
import type {
    BuyCoinValues,
    BuySellCoinValues,
    ExchangeOrder,
    ExchangeQuote,
    Quotation,
} from './types';
import {
    mapExchangeOrder,
    mapExchangeQuote,
    mapExchangeWithDrawals,
} from './utils';

export interface QuotationState {
    requestStates: {
        quotation: REQUEST_STATE;
        addExchangeQuote: REQUEST_STATE;
        confirmExchangeQuote: REQUEST_STATE;
        // getExchangeLimit: REQUEST_STATE;
        addExchangeLimit: REQUEST_STATE;
        cancelExchangeLimit: REQUEST_STATE;
        executeExchangeLimit: REQUEST_STATE;
        reactivateExchangeLimit: REQUEST_STATE;
        getExchangeOrder: REQUEST_STATE;
        getCoinVariation: REQUEST_STATE;
    };
    quotation: Quotation[] | null;
    buyCoinValues: BuyCoinValues | null;
    buySellCoinValues: BuySellCoinValues | null;
    addedExchangeQuote:
        | (ExchangeQuote & { executeAfterConfirm: number | null })
        | null;
    confirmedExchangeQuote: ExchangeQuote | null;
    exchangeOrders: ExchangeOrder[] | null;
    exchangeLimitToExecute: number | null;
    coinVariation: SymbolVariationObj | null;
}

const initialState: QuotationState = {
    requestStates: {
        quotation: REQUEST_STATE.NONE,
        addExchangeQuote: REQUEST_STATE.NONE,
        confirmExchangeQuote: REQUEST_STATE.NONE,
        // getExchangeLimit: REQUEST_STATE.NONE,
        addExchangeLimit: REQUEST_STATE.NONE,
        cancelExchangeLimit: REQUEST_STATE.NONE,
        executeExchangeLimit: REQUEST_STATE.NONE,
        reactivateExchangeLimit: REQUEST_STATE.NONE,
        getExchangeOrder: REQUEST_STATE.NONE,
        getCoinVariation: REQUEST_STATE.NONE,
    },
    quotation: null,
    buyCoinValues: null,
    buySellCoinValues: null,
    addedExchangeQuote: null,
    confirmedExchangeQuote: null,
    // addedExchangeLimit: null,
    exchangeOrders: null,
    exchangeLimitToExecute: null,
    coinVariation: null,
};

export const quotationSlice = createSlice({
    name: 'quotationSlice',
    initialState,
    reducers: {
        setBuyCoinValues: (
            state: QuotationState,
            actions: PayloadAction<BuyCoinValues>,
        ) => {
            state.buyCoinValues = actions.payload;
        },
        setBuySellCoinValues: (
            state: QuotationState,
            actions: PayloadAction<BuySellCoinValues>,
        ) => {
            state.buySellCoinValues = actions.payload;
        },
    },
    extraReducers: (builder) => {
        /* Get quotation */
        builder.addCase(getQuotation.pending, (state) => {
            state.requestStates.quotation = REQUEST_STATE.LOADING;
        });
        builder.addCase(getQuotation.fulfilled, (state, { payload }) => {
            state.requestStates.quotation = REQUEST_STATE.OK;
            state.quotation = payload.map(mapExchangeQuote);
        });
        builder.addCase(getQuotation.rejected, (state) => {
            toast.error(
                'Hubo un error al obtener los datos, contacte el administrador',
            );
            state.requestStates.quotation = REQUEST_STATE.ERROR;
        });
        /* Add exchange quote */
        builder.addCase(addExchangeQuote.pending, (state) => {
            state.requestStates.addExchangeQuote = REQUEST_STATE.LOADING;
        });
        builder.addCase(addExchangeQuote.fulfilled, (state, { payload }) => {
            state.requestStates.addExchangeQuote = REQUEST_STATE.OK;
            const { executeAfterConfirm, ...exchangeQuote } = payload;
            const mappedExchangeQuote = mapExchangeQuote(exchangeQuote);
            state.addedExchangeQuote = {
                ...mappedExchangeQuote,
                executeAfterConfirm,
            };
        });
        builder.addCase(addExchangeQuote.rejected, (state) => {
            state.requestStates.addExchangeQuote = REQUEST_STATE.ERROR;
        });
        /* Confirm quote */
        builder.addCase(confirmExchangeQuote.pending, (state) => {
            state.requestStates.confirmExchangeQuote = REQUEST_STATE.LOADING;
        });
        builder.addCase(
            confirmExchangeQuote.fulfilled,
            (state, { payload }) => {
                state.requestStates.confirmExchangeQuote = REQUEST_STATE.OK;
                state.confirmedExchangeQuote = mapExchangeQuote(payload);
            },
        );
        builder.addCase(confirmExchangeQuote.rejected, (state) => {
            state.requestStates.confirmExchangeQuote = REQUEST_STATE.ERROR;
        });

        /* getExchangeLimit */
        // builder.addCase(getExchangeLimit.pending, (state) => {
        //     state.requestStates.getExchangeLimit = REQUEST_STATE.LOADING;
        // });
        // builder.addCase(getExchangeLimit.fulfilled, (state, { payload }) => {
        //     state.requestStates.getExchangeLimit = REQUEST_STATE.LOADING;
        // });
        // builder.addCase(getExchangeLimit.rejected, (state) => {
        //     state.requestStates.getExchangeLimit = REQUEST_STATE.LOADING;
        // });

        /* addExchangeLimit */
        builder.addCase(addExchangeLimit.pending, (state) => {
            state.requestStates.addExchangeLimit = REQUEST_STATE.LOADING;
        });
        builder.addCase(addExchangeLimit.fulfilled, (state, { payload }) => {
            state.requestStates.addExchangeLimit = REQUEST_STATE.OK;
            // state.addedExchangeLimit
        });
        builder.addCase(addExchangeLimit.rejected, (state) => {
            state.requestStates.addExchangeLimit = REQUEST_STATE.ERROR;
        });

        /* cancelExchangeLimit */
        builder.addCase(cancelExchangeLimit.pending, (state) => {
            state.requestStates.cancelExchangeLimit = REQUEST_STATE.LOADING;
        });
        builder.addCase(cancelExchangeLimit.fulfilled, (state, { payload }) => {
            state.requestStates.cancelExchangeLimit = REQUEST_STATE.OK;
        });
        builder.addCase(cancelExchangeLimit.rejected, (state) => {
            state.requestStates.cancelExchangeLimit = REQUEST_STATE.ERROR;
        });

        /* executeExchangeLimit */
        builder.addCase(executeExchangeLimit.pending, (state) => {
            state.requestStates.executeExchangeLimit = REQUEST_STATE.LOADING;
        });
        builder.addCase(
            executeExchangeLimit.fulfilled,
            (state, { payload }) => {
                state.requestStates.executeExchangeLimit =
                    REQUEST_STATE.LOADING;
            },
        );
        builder.addCase(executeExchangeLimit.rejected, (state) => {
            state.requestStates.executeExchangeLimit = REQUEST_STATE.ERROR;
        });

        /* reactivateExchangeLimit */
        builder.addCase(reactivateExchangeLimit.pending, (state) => {
            state.requestStates.reactivateExchangeLimit = REQUEST_STATE.LOADING;
        });
        builder.addCase(reactivateExchangeLimit.fulfilled, (state) => {
            state.requestStates.reactivateExchangeLimit = REQUEST_STATE.LOADING;
        });
        builder.addCase(reactivateExchangeLimit.rejected, (state) => {
            state.requestStates.reactivateExchangeLimit = REQUEST_STATE.ERROR;
        });

        /* get exchange orders */
        builder.addCase(getExchangeOrders.pending, (state) => {
            state.requestStates.getExchangeOrder = REQUEST_STATE.LOADING;
        });
        builder.addCase(getExchangeOrders.fulfilled, (state, { payload }) => {
            state.requestStates.getExchangeOrder = REQUEST_STATE.OK;
            state.exchangeOrders = payload.map(mapExchangeOrder);
        });
        builder.addCase(getExchangeOrders.rejected, (state) => {
            state.requestStates.getExchangeOrder = REQUEST_STATE.ERROR;
        });

        /* get coins variation */
        builder.addCase(getCoinVaration.pending, (state) => {
            state.requestStates.getCoinVariation = REQUEST_STATE.LOADING;
        });
        builder.addCase(getCoinVaration.fulfilled, (state, { payload }) => {
            state.requestStates.getCoinVariation = REQUEST_STATE.OK;
            state.coinVariation = payload;
        });
        builder.addCase(getCoinVaration.rejected, (state) => {
            state.requestStates.getExchangeOrder = REQUEST_STATE.ERROR;
        });
    },
});

export const quotationActions = quotationSlice.actions;

export const quotationReducer = quotationSlice.reducer;
