import {
    Modal as ReactstrapModal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import type { ReactNode } from 'react';
import type { Color } from 'common/types';
import { X } from 'react-feather';
import './Modal.scss';

interface ModalProps {
    color?: Color;
    open?: boolean;
    size?: 'sm' | 'lg';
    title?: string;
    footer?: ReactNode;
    onClose: () => void;
    bodyClassName?: string;
    children?: ReactNode;
    hideClose?: boolean;
    className?: string;
    noPadding?: boolean;
}

export const Modal = (props: ModalProps) => {
    return (
        <ReactstrapModal
            isOpen={props.open}
            toggle={props.onClose}
            size={props.size}
            className={props.className}
        >
            {!props.hideClose && (
                <div className='close-button' onClick={props.onClose}>
                    <X size={24} />
                </div>
            )}
            {props.title && (
                <ModalHeader toggle={props.onClose}>{props.title}</ModalHeader>
            )}
            <ModalBody
                className={props.bodyClassName}
                style={{
                    padding: props.noPadding ? '0' : '',
                }}
            >
                {props.children}
            </ModalBody>
            {props.footer && <ModalFooter>{props.footer}</ModalFooter>}
        </ReactstrapModal>
    );
};
