export enum HELP_SUB_ROUTES_KEY {
    SEND_CONSULT = 'SEND_CONSULT',
    TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
    HELP_CENTER = 'HELP_CENTER',
}

export const HELP_SUB_ROUTES = {
    [HELP_SUB_ROUTES_KEY.SEND_CONSULT]: 'enviar-consulta',
    [HELP_SUB_ROUTES_KEY.TERMS_AND_CONDITIONS]: 'terminos-y-condiciones',
    [HELP_SUB_ROUTES_KEY.HELP_CENTER]: 'centro-de-ayuda',
};

export const helpSubRouteTitles: { [key in HELP_SUB_ROUTES_KEY]: string } = {
    [HELP_SUB_ROUTES_KEY.SEND_CONSULT]: 'Enviar consulta',
    [HELP_SUB_ROUTES_KEY.TERMS_AND_CONDITIONS]: 'Términos y Condiciones',
    [HELP_SUB_ROUTES_KEY.HELP_CENTER]: 'Centro de Ayuda',
};
