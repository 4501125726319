import { Modal, P, TextDropdown, ToastBanner } from 'common';
import { CoinComponentObject } from 'common/helper';
import { MODAL_OPEN } from 'common/Modal/const';
import { modalActions } from 'common/Modal/modalSlice';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { COIN_TYPES } from 'utils/const';
import { getDepositARSInfo, getDepositCryptoInfo } from '../walletService';
import { getCoins } from '../../../../common/commonService';
import { getSingleCoinIDFromName } from '../../../../utils/currency';

export const mockCyptoNetworks = [
    {
        id: 'BEP20',
        text: 'BNB Smart Chain (BEP20)',
    },
    {
        id: 'ERC20',
        text: 'Ethereum (ERC20) ',
    },
    {
        id: 'TRC20',
        text: 'Tron (TRC20)',
    },
    {
        id: 'BEP2',
        text: 'BNB Beacon Chain (BEP2)',
    },
];

interface DepositModalProps {
    modalData?: string;
}

export const DepositModal = (props: DepositModalProps) => {
    const dispatch = useAppDispatch();
    const { modalOpen } = useAppSelector((state) => state.modal);
    const { modalData } = props;
    const { depositARS, depositCrypto } = useAppSelector(
        (state) => state.wallet,
    );
    const { coins } = useAppSelector((state) => state.common);
    const [cryptoNetwork, setCryptoNetwork] = useState('');
    const [currentNetwork, setCurrentNetwork] = useState(
        mockCyptoNetworks[0].id,
    );

    useEffect(() => {
        dispatch(getDepositARSInfo());
        dispatch(getCoins());
    }, []);

    const closeModal = () => {
        dispatch(modalActions.setModalOpen(MODAL_OPEN.NONE));
    };

    useEffect(() => {
        if (modalData) {
            if (modalData !== COIN_TYPES.ARS) {
                dispatch(
                    getDepositCryptoInfo({
                        currency_id: getSingleCoinIDFromName(coins, modalData),
                    }),
                );
            }
        }
        if (depositCrypto) setCryptoNetwork(depositCrypto?.address);
    }, [modalData, coins, !cryptoNetwork, !depositCrypto]);

    return (
        <Modal
            className='wallet-modal-open'
            title={
                modalData &&
                `Depositar ` +
                    CoinComponentObject[modalData.toString()].text +
                    ' (' +
                    modalData +
                    ')'
            }
            open={modalOpen === MODAL_OPEN.DEPOSIT_MODAL}
            onClose={() => {
                closeModal();
            }}
            noPadding
        >
            {modalData === COIN_TYPES.ARS ? (
                <div className='deposit-modal-container'>
                    <ToastBanner
                        show
                        message='Podes ingresar hasta ? ARS mensuales'
                    />
                    <div
                        className='modal-ars-data'
                        style={{ marginTop: '20px' }}
                    >
                        <strong>CUIT</strong>
                        <P>20-1-735131-1-5</P>
                        <strong>CBU</strong>
                        <P>3220001805007351310010</P>
                        <strong>ALIAS</strong>
                        <P>BISBULL</P>
                        <strong>Banco</strong>
                        <P>No disponible</P>
                        <strong>Cuenta</strong>
                        <P>735131</P>
                    </div>
                </div>
            ) : (
                <div className='deposit-modal-container'>
                    <ToastBanner show message='Elige la red a depositar' />
                    <div style={{ marginTop: '20px' }}>
                        <strong>Red: </strong>
                        <div className='modal-crypto-dropdown'>
                            <TextDropdown
                                loading={false}
                                noDataText='No hay datos'
                                value={currentNetwork}
                                onChange={(e) => {
                                    setCurrentNetwork(e.id);
                                }}
                                options={mockCyptoNetworks}
                                backgroundColor
                            />
                        </div>
                        <strong>Direccion: </strong>
                        <P>{cryptoNetwork}</P>
                    </div>
                </div>
            )}
        </Modal>
    );
};
