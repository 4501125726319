import { Edit2 } from 'react-feather';
import './EditableImage.scss';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import React, { useEffect, useState } from 'react';
import { deleteAvatar, getAvatar, setAvatar } from '../../profileService';
import { toast } from 'react-toastify';
import { REQUEST_STATE } from '../../../../../redux/types';
import { LoaderWheel } from '../../../../../common/Loader/LoaderWheel';
import { useLoadedImage } from 'utils';

export const EditableImage = () => {
    const dispatch = useAppDispatch();
    const [deleteImg, setDeleteImg] = useState(true);
    const { avatar } = useAppSelector((state) => state.profile);
    const { requestStates } = useAppSelector((state) => state.profile);
    const loading = requestStates.getAvatar === REQUEST_STATE.LOADING;
    const loaded = useLoadedImage(avatar);

    useEffect(() => {
        dispatch(getAvatar())
            .unwrap()
            .catch(() => {
                setDeleteImg(false);
            });
    }, []);

    const setAvatarHandler = (imageToSet: any) => {
        dispatch(setAvatar({ image: imageToSet }))
            .unwrap()
            .catch(() => {
                toast.error(
                    'Hubo un error al subir su avatar, si el error persiste contacte a el administrador',
                );
            })
            .then(() => {
                dispatch(getAvatar());
            })
            .catch(() => {
                // toast.error(
                //     'Hubo un error al cargar su avatar, si el error persiste contacte el administrador.',
                // );
            });
    };

    const handleSetImage = async (event: any) => {
        if (event.target.files) {
            const imageToSet = await event.target.files[0];
            //5 mega bytes max
            if (imageToSet.size < 5000000) {
                deleteImg
                    ? dispatch(deleteAvatar())
                          .unwrap()
                          .catch(() => {
                              toast.error(
                                  'Hubo un error al cambiar su avatar, si el error persiste contacte a el administrador.',
                              );
                          })
                          .then(() => {
                              setAvatarHandler(imageToSet);
                          })
                    : setAvatarHandler(imageToSet);
            } else {
                toast.error(
                    'El tamano del archivo es muy grande (5MB máximo).',
                );
            }
        }
    };

    return (
        <div className='editable-image'>
            {loading ? (
                <LoaderWheel />
            ) : (
                <>
                    <img
                        src={avatar}
                        alt=''
                        style={{ display: loaded ? 'block' : 'none' }}
                    />
                    <div className='edit-button'>
                        <Edit2 id='myDiv' />
                        <input
                            type='file'
                            name='image'
                            placeholder='Image'
                            accept='.jpg'
                            onChange={(e) => handleSetImage(e)}
                        />
                    </div>
                </>
            )}
        </div>
    );
};
