import { Button, FormInput } from '@common';
import { useLocation, useNavigate } from 'react-router-dom';
import { getTokensFromUrl, validateWithPasswordFields } from '@utils';
import { Col, Row } from 'reactstrap';
import { ROUTE_PATH } from 'layout/routes/const';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { SessionCard } from '../components/SessionCard';
import passwordImage from 'assets/images/recover_password_side_image.png';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { REQUEST_STATE } from 'redux/types';
import { recoverPasswordConfirm } from '../sessionService';
import YupPassword from 'yup-password';

YupPassword(Yup);

export interface RecoverPasswordConfirmValues {
    newPassword: string;
    reNewPassword: string;
}

type RecoverPasswordConfirmSchema = Yup.ObjectSchema<{
    newPassword: Yup.BaseSchema;
    reNewPassword: Yup.BaseSchema;
}>;

const validationSchema: RecoverPasswordConfirmSchema = Yup.object({
    newPassword: Yup.string()
        .password()
        .min(8, '8 caracteres')
        .minLowercase(0)
        .minUppercase(1, '1 mayúscula')
        .minNumbers(1, '1 número')
        .minSymbols(1, '1 símbolo'),
    reNewPassword: Yup.string()
        .required('Este campo es requerido')
        .oneOf([Yup.ref('newPassword'), null], 'La contraseña no coincide'),
});

export const RecoverPasswordConfirm = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const recoverPasswordConfirmState = useAppSelector(
        (state) => state.session.requestStates.recoverPasswordConfirm,
    );

    const formik = useFormik({
        initialValues: {
            newPassword: '',
            reNewPassword: '',
        },
        onSubmit: () => {
            const { uid, token } = getTokensFromUrl(
                ROUTE_PATH.RECOVER_CONFIRM_PASSWORD,
                pathname,
            );
            dispatch(
                recoverPasswordConfirm({
                    uid,
                    token,
                    new_password: formik.values.newPassword,
                    re_new_password: formik.values.reNewPassword,
                    onSuccess: () => navigate('/login'),
                }),
            );
        },
        validationSchema: validationSchema,
        validate: (values) =>
            validateWithPasswordFields(values, validationSchema, [
                'newPassword',
            ]),
    });

    return (
        <div>
            <div className='login-card'>
                <SessionCard
                    title='Confirmar contraseña'
                    subtitle='Escribí tu nueva contraseña y confirmala'
                    src={passwordImage}
                    onSubmit={formik.handleSubmit}
                    onClose={() => navigate(-1)}
                >
                    <Row>
                        <Col>
                            <FormInput
                                label='Nueva Contraseña'
                                name='newPassword'
                                type='password'
                                onFormikChange={formik.handleChange}
                                value={formik.values.newPassword}
                                onChange={() => null}
                                placeholder='********'
                                passwordError={formik.errors.newPassword}
                            />
                        </Col>
                    </Row>
                    <Row className='mb-3 mt-3'>
                        <Col>
                            <FormInput
                                label='Confirmar Contraseña'
                                name='reNewPassword'
                                type='password'
                                onFormikChange={formik.handleChange}
                                onChange={() => null}
                                value={formik.values.reNewPassword}
                                error={formik.errors.reNewPassword}
                            />
                        </Col>
                    </Row>

                    <Row className='justify-content-left'>
                        <Button
                            type='submit'
                            loading={
                                recoverPasswordConfirmState ===
                                REQUEST_STATE.LOADING
                            }
                            style={{ marginLeft: '15px', marginTop: '15px' }}
                        >
                            Enviar
                        </Button>
                    </Row>
                </SessionCard>
            </div>
        </div>
    );
};
