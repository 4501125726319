import { useState, createContext } from 'react';
import type { ReactNode } from 'react';
import { ConfigDB } from 'config/theme';

type ThemeColor = 'light-only' | 'dark-only';

interface ThemeContext {
    mix_background_layout: ThemeColor;
    addMixBackgroundLayout: (newValue: ThemeColor) => void;
}

export const ThemeContext = createContext<ThemeContext>({
    mix_background_layout: 'light-only',
    addMixBackgroundLayout: () => null,
});

interface ThemeProviderProps {
    children?: ReactNode;
}

export const ThemeProvider = (props: ThemeProviderProps) => {
    const [mix_background_layout, setMixBackgroundLayout] = useState(
        localStorage.getItem('mix_background_layout') as ThemeColor,
    );

    const addMixBackgroundLayout = (mix_background_layout: ThemeColor) => {
        ConfigDB.data.color.mix_background_layout = mix_background_layout;
        localStorage.setItem('mix_background_layout', mix_background_layout);
        setMixBackgroundLayout(mix_background_layout);
    };

    return (
        <ThemeContext.Provider
            value={{
                addMixBackgroundLayout,
                mix_background_layout,
            }}
        >
            {props.children}
        </ThemeContext.Provider>
    );
};
